import { Button, ButtonGroup, Flex, Image, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";

export const PreviewPrice = (props) => {
    const { updatedItems, selectedItemIdsToObject, newPrice } = props;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    return <Table variant="simple">
        <Thead>
            <Tr>
                <Th>Name</Th>
                <Th>Before</Th>
                <Th>After</Th>
                <Th>Currency</Th>
            </Tr>
        </Thead>
        <Tbody>
            {updatedItems.map((item, index) => (
                <Tr key={item.id}>
                    <Td>
                        <Flex align="center">
                            <Image
                                src={item.name[2]}
                                w="36px"
                                h="36px"
                                me="8px"
                                borderRadius="10px"
                            />
                            <Flex direction="column">
                                <Text
                                    color={textColor}
                                    fontSize="sm"
                                    fontWeight="700"
                                >
                                    {item.name[0]}
                                </Text>
                                <Text
                                    color="secondaryGray.500"
                                    fontSize="sm"
                                    fontWeight="600"
                                >
                                    {item.name[1]}
                                </Text>
                            </Flex>
                        </Flex></Td>
                    <Td fontWeight="700" >{selectedItemIdsToObject[index].price}</Td>

                    <Td>{newPrice}</Td>

                    <Td>CAD</Td>
                </Tr>
            ))}
        </Tbody>
    </Table>
}

export const PreviewPriceFooter = (props) => {
    const { onPreviewPriceSubmit, onPreviewPriceDiscard } = props;
    return <ButtonGroup>
        <Button variant='ghost' onClick={onPreviewPriceDiscard}>Discard</Button>
        <Button colorScheme='brand' mr={3} onClick={onPreviewPriceSubmit} disabled>
            Save Changes
        </Button>
    </ButtonGroup>
}