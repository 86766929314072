import React from "react";
import { Box, Heading, SimpleGrid, Text, VStack, HStack, Badge, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import LineChart from "components/charts/LineChart";
import BubbleChart from "components/charts/BubbleChart";
import AIInsight from "components/AIInsight";

// Mock data
const repeatPurchaseData = [
    { month: "Jan", running: 20, basketball: 15, lifestyle: 25 },
    { month: "Feb", running: 22, basketball: 16, lifestyle: 27 },
    { month: "Mar", running: 25, basketball: 18, lifestyle: 30 },
    { month: "Apr", running: 28, basketball: 20, lifestyle: 32 },
    { month: "May", running: 30, basketball: 22, lifestyle: 35 },
    { month: "Jun", running: 32, basketball: 24, lifestyle: 38 },
];

const productAffinityData = [
    { x: 100, y: 200, z: 700, name: "Running Shoes" },
    { x: 80, y: 250, z: 500, name: "Basketball Shoes" },
    { x: 120, y: 180, z: 600, name: "Lifestyle Shoes" },
    { x: 70, y: 220, z: 400, name: "Running Apparel" },
    { x: 90, y: 190, z: 550, name: "Basketball Apparel" },
];

const crossSellOpportunities = [
    { product: "Running Shoes", recommendation: "Running Socks", conversionRate: 35 },
    { product: "Basketball Shoes", recommendation: "Basketball", conversionRate: 28 },
    { product: "Lifestyle Shoes", recommendation: "Casual Wear", conversionRate: 40 },
];

const ProductAffinity = () => {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <AIInsight message="Cross-sell opportunities identified between running shoes and performance socks. Consider bundling these products for a 10% potential sales increase." />

            <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
                <Card>
                    <Heading size="md" mb="4">
                        Repeat Purchase Analysis
                    </Heading>
                    <LineChart
                        chartData={[
                            {
                                name: "Running",
                                data: repeatPurchaseData.map((item) => item.running),
                            },
                            {
                                name: "Basketball",
                                data: repeatPurchaseData.map((item) => item.basketball),
                            },
                            {
                                name: "Lifestyle",
                                data: repeatPurchaseData.map((item) => item.lifestyle),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: repeatPurchaseData.map((item) => item.month),
                            },
                            yaxis: {
                                title: {
                                    text: "Repeat Purchase Rate (%)",
                                },
                            },
                            markers: {
                                size: 4,
                            },
                            legend: {
                                position: "top",
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Product Affinity Map
                    </Heading>
                    <BubbleChart
                        chartData={[
                            {
                                name: "Products",
                                data: productAffinityData.map((item) => [item.x, item.y, item.z]),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                title: {
                                    text: "Purchase Frequency",
                                },
                            },
                            yaxis: {
                                title: {
                                    text: "Average Order Value",
                                },
                            },
                            tooltip: {
                                y: {
                                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                                        return `${productAffinityData[dataPointIndex].name}: ${value}`;
                                    },
                                },
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Cross-Sell and Upsell Opportunities
                    </Heading>
                    <VStack align="stretch" spacing={4}>
                        {crossSellOpportunities.map((opportunity, index) => (
                            <Box key={index} p={4} borderWidth={1} borderRadius="md">
                                <HStack justify="space-between" mb={2}>
                                    <Text fontWeight="bold">{opportunity.product}</Text>
                                    <Badge colorScheme="green">
                                        {opportunity.conversionRate}% Conversion
                                    </Badge>
                                </HStack>
                                <Text mb={2}>Recommend: {opportunity.recommendation}</Text>
                                <Button size="sm" colorScheme="blue">
                                    Create Campaign
                                </Button>
                            </Box>
                        ))}
                    </VStack>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default ProductAffinity;
