// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";

export default function Information(props) {
  const { title, value, icon, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Card bg={bg} {...rest} >
      <Flex align='center' direction='row' pr='15px'>
        {icon ? <Icon as={icon} color='secondaryGray.500' h='25px' w='25px' mr='25px' /> : null}
        <Box>
          <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
            {title}
          </Text>
          <Text color={textColorPrimary} fontWeight='500' fontSize='md'>
            {value}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}
