/*eslint-disable*/

/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
																																																																																	   
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Alert, AlertDescription, AlertTitle, Button, Divider, Flex, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
// import Card from 'components/card/Card';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SearchTableOverview from '../../components/table/SearchTableOverview';
import Connected from 'components/widgets/list/Connected';
import { AuthContext } from 'contexts/AuthContext';
import { logData } from 'util/Logger';
import DrawerFactory from 'components/drawers/DrawerFactory';
import ProductSettings from 'views/settingsProduct';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import { Footer, PreviewCategories } from './modals/previews/PreviewCategories';
import { PreviewPrice, PreviewPriceFooter } from './modals/previews/PreviewPrice';
import { BulkEditCategories, BulkEditCategoriesFooter } from './modals/bulkEdit/BulkEditCategories';
import { BulkEditPrice, BulkEditPriceFooter } from './modals/bulkEdit/BulkEditPrice';
import { errorData } from 'util/Logger';
import UnsavedChangesModal from './modals/UnsavedChangesModal';
import AlertContainer from './components/alerts/AlertContainer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ExportProductsDrawer from './drawers/ExportProductsDrawer';
import FilterDrawer from './drawers/FilterDrawer';
import ConnectDataSource from 'components/modals/ConnectDataSource';
import { firestore } from 'firebaseApp';
import { collection, doc, getDocs, onSnapshot } from 'firebase/firestore';
import { convertToRowData } from 'dataAdapters/ProductAdapter';
import { mergeFields } from 'util/TableAdapter';
import { getProducts } from 'api/products/getProducts';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'firebaseApp';
import NoProductsFound from './components/EmptyContainer';
import OrderUpsell from 'views/orderList/components/OrderUpsell';
import Card from 'components/card/Card';
import MiniStatistics from 'views/customers/components/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { IoMdAlert, IoMdBookmark, IoMdCart, IoMdPricetag, IoMdTime, IoMdTrendingUp } from 'react-icons/io';
import { getProductsAnalytics } from 'api/analytics/getProductsAnalytics';
import { IoRibbon } from 'react-icons/io5';
import ProductFilterModal from './modals/ProductFilterModal';
import ProductUpsell from './components/ProductUpsell';

function ProductOverview() {
	const { currentUser } = useContext(AuthContext);
	const [connectedApps, setConnectedApps] = useState([]);

	const history = useHistory();

	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState([]);
	const [columns, setColumns] = useState([]);
	const [drawerRendering, setDrawerRendering] = useState({});

	// Bulk edit states
	const [bulkEditModalRendering, setBulkEditModalRendering] = useState({});
	const [previewChangesRendering, setPreviewChangesRendering] = useState({});
	const [tags, setTags] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [previewItems, setPreviewItems] = useState([]);
	const [bulkEditPrice, setBulkEditPrice] = useState('');

	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [filterActive, setFilterActive] = useState(false);
	const [activeFilters, setActiveFilters] = useState({});
	const [filterCount, setfilterCount] = useState(0);
	const [filters, setFilters] = useState({
		name: '',
		minPrice: '',
		maxPrice: '',
		category: '',
		tags: [],
		vendor: '',
	});

	const openFilterModal = () => setIsFilterModalOpen(true);
	const closeFilterModal = () => setIsFilterModalOpen(false);


	// analytics
	const [activeProducts, setActiveProducts] = useState(0);
	const [collections, setCollections] = useState(0);

	const {
		isOpen: isExportOpen,
		onOpen: onExportOpen,
		onClose: onExportClose,
	} = useDisclosure();

	const {
		isOpen,
		onOpen,
		onClose,
	} = useDisclosure();

	// confirmation modal
	const {
		isOpen: isConfirmOpen,
		onClose: onConfirmClose,
		onOpen: onConfirmOpen,
	} = useDisclosure();

	// bulk edit modal
	const {
		isOpen: isBulkEditOpen,
		onClose: onBulkEditClose,
		onOpen: onBulkEditOpen,
	} = useDisclosure();

	// preview changes modal
	const {
		isOpen: isPreviewOpen,
		onClose: onPreviewClose,
		onOpen: onPreviewOpen,
	} = useDisclosure();

	// import csv modal
	const {
		isOpen: isImportOpen,
		onClose: onImportClose,
		onOpen: onImportOpen,
	} = useDisclosure();

	// filter drawer
	const {
		isOpen: isFilterOpen,
		onClose: onFilterClose,
		onOpen: onFilterOpen,
	} = useDisclosure();

	const onOverlayClick = useRef(() => { });
	const hasUnsavedChangesRef = useRef(hasUnsavedChanges);

	useEffect(() => {
		hasUnsavedChangesRef.current = hasUnsavedChanges;
	}, [hasUnsavedChanges]);

	useEffect(() => {
		onOverlayClick.current = () => {
			logData('hasUnsavedChanges:', hasUnsavedChangesRef.current);
			if (hasUnsavedChangesRef.current) {
				logData('Unsaved changes detected');
				onConfirmOpen();
			} else {
				onClose();
			}
		};
	}, [onConfirmOpen, onClose]);

	const countActiveFilters = (filters) => {
		return Object.values(filters).filter(value => value !== '' && value.length !== 0).length;
	};

	const applyFilters = (filters) => {
		setActiveFilters(filters);
		const filterCount = countActiveFilters(filters);
		setFilterActive(filterCount > 0);
		setfilterCount(filterCount);

		const filteredProducts = products.filter(product => {
			const matchesName = !filters.name || product.name[0].toLowerCase().includes(filters.name.toLowerCase());
			const matchesPrice = (!filters.minPrice || parseFloat(product.price) >= parseFloat(filters.minPrice)) &&
				(!filters.maxPrice || parseFloat(product.price) <= parseFloat(filters.maxPrice));
			const matchesVendor = !filters.vendor || product.vendor.toLowerCase().includes(filters.vendor.toLowerCase());
			const matchesTag = filters.tags.length === 0 || (product.tags && product.tags.some(productTag =>
				filters.tags.some(filterTag => productTag.toLowerCase().includes(filterTag.toLowerCase()))
			));

			return matchesName && matchesPrice && matchesTag && matchesVendor;
		});

		setFilteredProducts(filteredProducts);
	};


	const onBulkEditMenuItemClicked = (action, data) => {
		logData('Bulk edit menu item clicked:', action);
		logData('data: ', data);

		switch (action) {
			case 'categories':
				setTags(data);
				setBulkEditModalRendering({
					title: 'Editting Categories',
					bodyComponents: <BulkEditCategories data={data} setTags={setTags} />,
					footerComponents: <BulkEditCategoriesFooter onBulkEditClose={onBulkEditClose} onPreviewBulkEditCategories={onPreviewBulkEditCategories} data={data} />,
				});
				break;
			case 'price':
				setBulkEditModalRendering({
					title: 'Update Pricing',
					bodyComponents: <BulkEditPrice setBulkEditPrice={setBulkEditPrice} />,
					footerComponents: <BulkEditPriceFooter
						onBulkEditClose={onBulkEditClose}
						onPreviewBulkEditPrice={onPreviewBulkEditPrice}
						data={data}
					/>,
				});
				break;
			case 'attributes':
				setBulkEditModalRendering({
					title: 'Not Yet Supported',
					bodyComponents: <Text>
						This feature is not yet supported.
					</Text>,
				});
				break;
			default:
				setBulkEditModalRendering({
					title: 'Not Yet Supported',
					bodyComponents: <Text>
						This feature is not yet supported.
					</Text>,
				});
				break;
		}
		onBulkEditOpen();
	};

	// Helper function to get selected items
	const getSelectedItems = (items, selectedIds) => {
		return items.filter((item) => selectedIds.includes(item.id));
	};

	// Helper function to update categories
	const updateCategories = (items, tags) => {
		return items.map((item) => ({
			...item,
			tags: Array.from(new Set([...(item.tags || []), ...tags]))
		}));
	};

	const onPreviewBulkEditCategories = (tags) => {
		onBulkEditClose();

		const selectedItemIdsToObject = getSelectedItems(products, selectedItems);
		const updatedItems = updateCategories(selectedItemIdsToObject, tags);

		setPreviewItems(updatedItems);

		setPreviewChangesRendering({
			bodyComponents: <PreviewCategories updatedItems={updatedItems} selectedItemIdsToObject={selectedItemIdsToObject} />,
			footerComponents: <Footer onPreviewCategoriesSubmit={onPreviewClose} onPreviewCategoriesDiscard={onPreviewClose} />,
		});

		onPreviewOpen();
	};

	const onImportCsvResult = async (importResult) => {
		logData('Import result:', importResult);

		let result;
		try {
			result = await parseProducts({ csvData: importResult, token: currentUser.token });
			history.push('/admin/products/importdetails');
		} catch (error) {
			errorData('Error enriching products:', error);
		} finally {
			onImportClose();
		}
	};

	// Helper function to update price
	const updatePrice = (items, newPrice) => {
		return items.map((item) => ({
			...item,
			price: newPrice,
		}));
	};

	const onPreviewBulkEditPrice = () => {
		onBulkEditClose();
		logData('onPreviewBulkEditPrice: bulkEditPrice:', bulkEditPrice);

		const selectedItemIdsToObject = getSelectedItems(products, selectedItems);
		const updatedItems = updatePrice(selectedItemIdsToObject, bulkEditPrice);
		logData('onPreviewBulkEditPrice: bulkEditPrice:', bulkEditPrice);
		logData('onPreviewBulkEditPrice: updatedItems:', updatedItems);
		setPreviewItems(updatedItems);

		setPreviewChangesRendering({
			bodyComponents: <PreviewPrice updatedItems={updatedItems} selectedItemIdsToObject={selectedItemIdsToObject} newPrice={bulkEditPrice} />,
			footerComponents: <PreviewPriceFooter onPreviewPriceSubmit={onPreviewClose} onPreviewPriceDiscard={onPreviewClose} />,
		});

		onPreviewOpen();
	};


	const onItemClicked = (type, item) => {
		logData('Item clicked:', type, item);
		setDrawerRendering({
			bodyComponents: <ProductSettings
				item={item}
				onDismissDrawer={onClose}
				id={item.id}
			/>,
			footerComponents: null,
			dismissible: false,
			onOverlayClick: onOverlayClick.current,
			size: 'xl',
		});
		onOpen();
	}

	const discardChanges = () => {
		setHasUnsavedChanges(false);
		onClose();
		onConfirmClose();
	}
	let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const onAddNewProductClicked = () => {
		const newProductId = "new_product_draft"
		const drawer = <ProductSettings
			id={newProductId}
			onDismissDrawer={onClose}
		/>;
		setDrawerRendering({
			bodyComponents: drawer,
			footerComponents: (
				<>
					<Card
						flexDirection="column"
						alignItems="flex-end"
						w="100%"

						bg="transparent"
						px="20px"
						pb="20px"
						pt="0px"
						m={0}
					>
						<Button
							variant="brand"
							minW="183px"
							fontSize="sm"
							fontWeight="500"
							borderRadius="10px"
						>
							Create product
						</Button>
					</Card></>
			),
			size: 'xl',
		});
		onOpen();
	};

	const onExportToCsv = () => {
		logData('Exporting to CSV');
		onExportOpen();
	};

	const onFilterColumns = () => {
		logData('Filtering columns');
		onFilterOpen();
	};

	const onMenuActionClicked = (actionType) => {
		logData('Menu action clicked:', actionType);
		switch (actionType) {
			case 'New':
				onAddNewProductClicked();
				break;
			case 'import':
				onImportOpen();
				break;
			case 'export':
				onExportToCsv();
				break;
			case 'filterColumns':
				onFilterColumns();
				break;
			case 'add':
				onAddNewProductClicked();
				break;
			case 'filter':
				openFilterModal();
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		if (currentUser.token === null) return;
		const abortController = new AbortController();

		const fetchProducts = async () => {
			setLoading(true);
			try {
				const result = await getProducts({
					token: currentUser.token,
					uid: currentUser.uid,
					abortController,
				});
				const rowProducts = result.products.map((product) => convertToRowData(product));
				setProducts(rowProducts);
				const columns = mergeFields(rowProducts);
				const tableFormat = columns.map((column) => ({
					Header: column,
					accessor: column,
				})).concat({
					Header: 'actions',
					accessor: 'editable',
				});
				setColumns(tableFormat);
			} catch (error) {
				console.error('Error fetching products:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchProducts();

		// Cleanup function
		return () => {
			abortController.abort(); // Abort the fetch request
		};
	}, [currentUser.token, currentUser.uid]);

	useEffect(() => {
		const fetchConnectedApps = async () => {
			try {
				const getConnectedApps = httpsCallable(functions, 'getConnectedApps');
				const result = await getConnectedApps();
				setConnectedApps(result.data);
				console.log('Connected Apps:', result.data);
			} catch (error) {
				console.error('Error getting connected apps:', error);
			}
		};

		if (currentUser && currentUser.token) {
			fetchConnectedApps();
		}
	}, [currentUser]);

	useEffect(() => {
		const unsubscribe = onSnapshot(
			doc(firestore, `Users/${currentUser.uid}/Apps`, 'shopify'),
			(docSnapshot) => {
				if (docSnapshot.exists()) {
					const shopifyApp = docSnapshot.data();
					setConnectedApps((prevApps) => {
						const updatedApps = prevApps.map((app) =>
							app.name === 'shopify' ? { ...app, data: shopifyApp } : app
						);
						return updatedApps;
					});
				}
			},
			(error) => {
				console.error('Error listening for Shopify app changes:', error);
			}
		);

		return () => {
			unsubscribe(); // Unsubscribe from the listener when the component unmounts
		};
	}, [currentUser.uid]);

	useEffect(() => {
		const unsubscribe = onSnapshot(
			doc(firestore, `Users/${currentUser.uid}/Apps`, 'google'),
			(docSnapshot) => {
				if (docSnapshot.exists()) {
					const googleApp = docSnapshot.data();
					setConnectedApps((prevApps) => {
						const updatedApps = prevApps.map((app) =>
							app.name === 'google' ? { ...app, data: googleApp } : app
						);
						return updatedApps;
					});
				}
			},
			(error) => {
				console.error('Error listening for Google app changes:', error);
			}
		);

		return () => {
			unsubscribe(); // Unsubscribe from the listener when the component unmounts
		};
	}, [currentUser.uid]);

	useEffect(() => {
		const unsubscribeProducts = onSnapshot(
			collection(firestore, `Users/${currentUser.uid}/Products`),
			(querySnapshot) => {
				const updatedProducts = querySnapshot.docs
					.map((doc) => {
						const product = doc.data();
						return convertToRowData(product);
					})
					.sort((a, b) => {
						const aUpdatedAt = new Date(a.updatedAt);
						const bUpdatedAt = new Date(b.updatedAt);
						return bUpdatedAt - aUpdatedAt; // Sort in descending order
					});

				setProducts(updatedProducts);
				const columns = mergeFields(updatedProducts);
				const tableFormat = columns
					.map((column) => ({
						Header: column,
						accessor: column,
					}))
					.concat({
						Header: 'actions',
						accessor: 'editable',
					});
				setColumns(tableFormat);
			},
			(error) => {
				console.error('Error listening for product changes:', error);
			}
		);
		return () => {
			unsubscribeProducts(); // Unsubscribe from the listener when the component unmounts
		};
	}, [currentUser.uid]);

	useEffect(() => {
		if (currentUser.token === null) return;
		const abortController = new AbortController();

		const fetchInsights = async () => {
			try {
				const insights = await getProductsAnalytics({
					token: currentUser.token,
					uid: currentUser.uid,
					abortController,
				});
				setActiveProducts(insights.products?.totalActiveProducts);
				setCollections(insights.products?.collections.length);
			} catch (error) {
				console.error("Failed to fetch insights:", error);
			}
		};

		fetchInsights();

		// Cleanup function
		return () => {
			abortController.abort(); // Abort the fetch request
		};
	}, [currentUser.token, currentUser.uid]);


	// logData('tableDataOverview:', tableDataOverview);
	// logData('columnsDataOverview:', columnsDataOverview);
	const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
	const brandColor = useColorModeValue("brand.500", "white");
	const alertBg = useColorModeValue("red.500", "red.200");

	return (
		<ProductInEditContext.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges }}>
			<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
				<AlertContainer />
				{/* <Banner /> */}
				<SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4} mb={4} >
					<Flex height="120px">
						<MiniStatistics
							name="Active Products"
							value={activeProducts}
							startContent={<IconBox
								w='56px'
								h='56px'
								bg={boxBg}
								icon={
									<Icon w='32px' h='32px' as={IoMdPricetag} color={brandColor} />
								}
							/>}
						/>
					</Flex>
					<Flex height="120px">
						<MiniStatistics
							name="Collections"
							value={collections}
							startContent={<IconBox
								w='56px'
								h='56px'
								bg={boxBg}
								icon={
									<Icon w='32px' h='32px' as={IoMdBookmark} color={brandColor} />
								}
							/>}
						/>
					</Flex>
					<Flex height="120px">
						<MiniStatistics
							name="Data Quality Score"
							value={"84%"}
							// endContent={<Flex><Text color="green.500" fontWeight="bold">Avg. Spend: ${251.78.toFixed(2)}</Text></Flex>}
							startContent={<IconBox
								w='56px'
								h='56px'
								bg={boxBg}
								icon={
									<Icon w='32px' h='32px' as={IoRibbon} color={brandColor} />
								}
							/>}
						/>
					</Flex>
					<Flex height="120px">
						<MiniStatistics
							name="Out of Stock"
							value={"3"}
							startContent={<IconBox
								w='56px'
								h='56px'
								bg={boxBg}
								icon={
									<Icon w='32px' h='32px' as={IoMdAlert} color={alertBg} />
								}
							/>}
						/>
					</Flex>
				</SimpleGrid>
				<SearchTableOverview
					tableData={filterActive ? filteredProducts : products}
					columnsData={columns}
					onItemClicked={onItemClicked}
					type='Import'
					searchPlaceholder='Search for products...'
					onActionClicked={onMenuActionClicked}
					onBulkEditMenuItemClicked={onBulkEditMenuItemClicked}
					onSelectedItemsChange={(items) => setSelectedItems(items)}
					loading={loading}
					filterCount={filterCount}
				/>

				<Divider mt='20px' mb="20px" />

				<SimpleGrid columns={{ base: 1, md: 2 }} spacing='20px'>

					{connectedApps.length > 0 && (
						<>

							<Connected
								connectedApps={connectedApps}
								h="100%"
							/>
						</>
					)}
					{!loading && products.length > 0 && (
						<ProductUpsell />
					)}
				</SimpleGrid>


				{DrawerFactory.createDrawer({
					isOpen: isOpen,
					onClose: onClose,
					bodyComponents: drawerRendering?.bodyComponents,
					footerComponents: drawerRendering?.footerComponents,
					dismissible: drawerRendering?.dismissible,
					onOverlayClick: drawerRendering?.onOverlayClick,
					size: drawerRendering?.size,
				})}
				<UnsavedChangesModal isConfirmOpen={isConfirmOpen} onConfirmClose={onConfirmClose} discardChanges={discardChanges} />
				<Modal
					isOpen={isBulkEditOpen}
					onClose={onBulkEditClose}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onDismissDrawer={() => {
						setBulkEditModalRendering({});
					}}
					isCentered
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>{bulkEditModalRendering.title}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>{bulkEditModalRendering.bodyComponents}</ModalBody>

						<ModalFooter>
							{bulkEditModalRendering.footerComponents}
						</ModalFooter>
					</ModalContent>
				</Modal>
				<Modal
					isOpen={isPreviewOpen}
					onClose={onPreviewClose}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					size='full'
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Preview Changes</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							{previewChangesRendering.bodyComponents}
						</ModalBody>
						<ModalFooter>
							{previewChangesRendering.footerComponents}
						</ModalFooter>
					</ModalContent>
				</Modal>
				<ConnectDataSource
					onImportCsvResult={onImportCsvResult}
					onCloseConnectDataSourceModal={onImportClose}
					onImportClose={onImportClose}
					isImportOpen={isImportOpen}
					showCsvImport={false}
				/>
				<ExportProductsDrawer
					isOpen={isExportOpen}
					onClose={onExportClose}
					columns={columns.map(column => column.accessor)}
				/>
				<FilterDrawer isOpen={isFilterOpen}
					columns={columns.map(column => column.accessor)}
					onClose={onFilterClose} />
			</Flex>
			<ProductFilterModal
				isOpen={isFilterModalOpen}
				onClose={closeFilterModal}
				onApplyFilters={applyFilters}
				filters={filters}
				setFilters={setFilters}
				setFilterActive={setFilterActive}
			/>
		</ProductInEditContext.Provider>
	);
}

export default withRouter(ProductOverview);