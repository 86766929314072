import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";

const checkShopifyProductsAccess = async ({
    accessToken,
    shopName
}) => {
    try {
        // Call the Cloud Function
        const checkAccessFunction = httpsCallable(functions, 'checkShopifyProductsAccess');
        const result = await checkAccessFunction({ accessToken, shopName });

        return result.data;
    } catch (error) {
        console.error('Error checking Shopify product access:', error);
        throw error;
    }
};

export default checkShopifyProductsAccess;
