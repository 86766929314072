import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";

const storeShopifyApiKeys = async ({
    accessToken,
    shopName,
    grantedScopes,
}) => {
    try {
        // Call the Cloud Function
        const checkAccessFunction = httpsCallable(functions, 'storeShopifyApiKeys');
        const result = await checkAccessFunction({ accessToken, shopName, grantedScopes });

        return result.data;
    } catch (error) {
        console.error('Error checking Shopify product access:', error);
        throw error;
    }
};

export default storeShopifyApiKeys;
