import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
import logo from "../../../assets/img/logo.png";

export function SidebarBrand() {
  return (
    <Flex align='start' direction='column'>
      <Image src={logo} alt='Horizon' mt='0' mb='20px' w='60%' />
      {/* <CompanyLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      {/* <HSeparator mb='20px' /> */}
    </Flex>
  );
}

export default SidebarBrand;
