/*eslint-disable*/

import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, CloseButton } from "@chakra-ui/react";
import { logData } from "util/Logger";

export const ImportStatus = {
    Importing: 'in-progress',
    Success: 'success',
    Error: 'error',
    Cancelling: 'cancelling',
    Cancelled: 'cancelled',
};

export default function ImportStatusAlert(props) {
    const { importStatus, onClose, onClick } = props;
    logData('importStatus', importStatus);

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    }

    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    }

    return (
        importStatus ? (
            <Alert
                mb="20px"
                status={
                    importStatus == ImportStatus.Success ? 'success' :
                        importStatus == ImportStatus.Error ? 'error' :
                            importStatus == ImportStatus.Cancelling ? 'warning' :
                                importStatus == ImportStatus.Cancelled ? 'info' : 'info'
                }
                onClose={handleOnClose} 
            >
                <AlertIcon />
                {importStatus == ImportStatus.Success ? (
                    <AlertTitle>Import Completed</AlertTitle>
                ) : importStatus == ImportStatus.Error ? (
                    <AlertTitle>Error importing products</AlertTitle>
                ) : importStatus == ImportStatus.Cancelling ? (
                    <AlertTitle>Import Cancelling</AlertTitle>
                ) : importStatus == ImportStatus.Cancelled ? (
                    <AlertTitle>Import Cancelled</AlertTitle>
                ) : importStatus == ImportStatus.Importing ? (
                    <AlertTitle>Import in progress</AlertTitle>
                ) : (
                    <AlertTitle>Unrecognized status</AlertTitle>
                )}
                <AlertDescription>
                    {importStatus == ImportStatus.Success
                        ? 'Import has been successfully completed.'
                        : importStatus == ImportStatus.Error
                            ? 'Please check the file and try again'
                            : importStatus == ImportStatus.Cancelling
                                ? 'Import is being cancelled.'
                                : importStatus == ImportStatus.Cancelled
                                    ? 'Import has been cancelled.'
                                    : importStatus == ImportStatus.Importing
                                        ? ''
                                        : 'Unrecognized status'}
                </AlertDescription>
                {importStatus !== ImportStatus.Importing && (
                    <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
                )}
                {
                    importStatus == ImportStatus.Importing && (
                        <Button colorScheme='outline' size='sm' ml='auto' color='brand.500' onClick={handleOnClick} >
                            Show Details
                        </Button>
                    )
                }
            </Alert>
        ) : null
    );
}