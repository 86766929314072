import {
    Alert,
    AlertIcon,
    AlertTitle,
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    UnorderedList,
    useDisclosure,
} from '@chakra-ui/react';
import { FaAppStore, FaBoxOpen, FaBuilding, FaCheckCircle, FaEllipsisH, FaEnvelope, FaImages, FaPencilAlt, FaPuzzlePiece, FaRegChartBar, FaRocket, FaStore } from 'react-icons/fa';
import { useState } from 'react';
import { joinWaitlist } from 'api/sinteli/joinWaitlist';
import Card from 'components/card/Card';
import ListItem from 'components/widgets/list/ListItem';
import Airtable from 'airtable';
import { logData } from 'util/Logger';
import { IoApps } from 'react-icons/io5';

const UpgradeToPro = (props) => {
    const { source } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [email, setEmail] = useState('');

    const handleJoinWaitlist = async () => {
        setIsLoading(true);
        try {
            const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appbw9FhuHO7M735N');

            await base('tbl7WoukH2uYDkXin').create([
                {
                    "fields": {
                        "Email": email,
                        "Source": source,
                    }
                }
            ]);
        } catch (error) {
            console.error('Error joining the Pro Waitlist:', error);
        } finally {
            try {
                await joinWaitlist({ email, source });
                setShowSuccessAlert(true);
            } catch (error) {
                console.error('Error joining the Pro Waitlist:', error);
            }
            setIsLoading(false);

            setShowSuccessAlert(true);
        }

    };

    return (
        <>
            <Button colorScheme="brand" variant="outline" onClick={onOpen}
                borderRadius={'10px'}
            >
                Join Waitlist
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="md" blockScrollOnMount={isLoading}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>

                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Card p="20px" boxShadow="base" borderRadius="10px" bg="gray.100">
                            {/* <Text fontSize="xl" fontWeight="bold" textAlign="center" mb="20px">
                                $79/month
                            </Text> */}
                            <Text fontSize="4xl" fontWeight="bold" textAlign="center" mb="20px">
                                What's Included?
                            </Text>
                            <UnorderedList spacing={4} mb="20px">
                                <ListItem icon={FaStore} title="Seamless Multi-Channel Integration" showBadge={false} />

                                <ListItem icon={FaPuzzlePiece} title="Customizable Dashboards" showBadge={false} />
                                <ListItem icon={FaRegChartBar} title="Real-Time Analytics" showBadge={false} />
                                <ListItem icon={FaRocket} title="Scalable for Growth" showBadge={false} />
                                <ListItem icon={FaBoxOpen} title="Third-Party App Integrations" showBadge={false} />
                                <ListItem icon={FaEnvelope} title="Priority email support" showBadge={false} />
                                <ListItem icon={FaEllipsisH} title="+ more" showBadge={false} />
                            </UnorderedList>
                        </Card>

                        <Flex direction="column" align="center" mt="20px">
                            {/* <Text fontSize="md" textAlign="center">
                                Ideal for: Ecommerce Managers, Product Owners, and Marketers
                            </Text> */}
                            <Input
                                type="email"
                                placeholder="Enter your email address"
                                borderRadius="10px"
                                w="100%"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {showSuccessAlert ? (
                                <Alert status="success" borderRadius="10px" my="10px">
                                    <AlertIcon />
                                    <AlertTitle mr={2}>Successfully joined the waitlist!</AlertTitle>
                                </Alert>
                            ) : (
                                <Button
                                    colorScheme="brand"
                                    variant="solid"
                                    my="15px"
                                    w="100%"
                                    onClick={handleJoinWaitlist}
                                    isLoading={isLoading}
                                >
                                    Join waitlist
                                </Button>
                            )}
                            <Button
                                colorScheme="brand"
                                variant="transparent"
                                mb="15px"
                                w="100%"
                                as="a"
                                href="https://www.sinteli.com?utm_source=webapp&utm_medium=waitlist_button&utm_campaign=join_waitlist"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Learn more
                            </Button>

                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default UpgradeToPro;
