const edges = [
    { id: 'facebook-ads-b', source: 'facebook-ads', target: 'b', animated: true },
    { id: 'google-ads-b', source: 'google-ads', target: 'b', animated: true },
    { id: 'shopify-b', source: 'shopify', target: 'b', animated: true },
    { id: 'shopify-d', source: 'shopify', target: 'd', animated: true },
    { id: 'shopify-c', source: 'shopify', target: 'c', animated: true },
    { id: 'google-analytics-a', source: 'google-analytics', target: 'a', animated: true },
];

export default edges;
