/*eslint-disable*/
import { Flex, Checkbox, Menu,MenuButton, IconButton, MenuList, MenuItem, Text, useColorModeValue, Badge, Image, Box, ButtonGroup, Button, HStack, Icon } from "@chakra-ui/react";
import Shopify from '../../../assets/img/icons/shopify.webp';
import Amazon from '../../../assets/img/icons/amazon.webp';
import Google from '../../../assets/img/icons/google.svg';
import { MdDelete, MdEdit, MdOpenInNew, MdMoreHoriz, MdFileDownload, MdExitToApp } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { logData } from "util/Logger";
import { FaDownload } from "react-icons/fa";

export function RowAdapter({ cell, column, onClick, onCheckboxChange, isSelected }) {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("secondaryGray.500", "white");

    const handleItemClick = (type, item) => {
        onClick(type, item);
    };

    const handleCheckboxChange = (e, itemId) => {
        logData('Checkbox clicked', itemId);
        onCheckboxChange(e, itemId);
    };

    if (column.Header.toLowerCase() === 'name' || column.Header.toLowerCase() === 'platform') {
        return (
            <Text color={textColor} fontSize='sm' isTruncated fontWeight={'bold'} >
                {cell.value}
            </Text>
        );
    }

    if (column.Header === 'ID' || column.Header === 'id') {
        return (
            <Flex align='center'>
                <Checkbox
                    value={cell.row.original.id}
                    defaultChecked={isSelected}
                    isChecked={isSelected}
                    colorScheme='brand'
                    me='10px'
                    onChange={(e) => handleCheckboxChange(e, cell.row.original.id)}
                />
                <Text color={textColor} fontSize='sm'>
                    {cell.value}
                </Text>
            </Flex>
        );
    }

    if (cell.column.Header.toLowerCase() === 'categories' && Array.isArray(cell.value)) {
        return cell.value.filter(c => c.length > 0).map((category, index) => (
            <Badge colorScheme='brand' key={index} me='1' mt='1' borderRadius={"5px"}>
                {category}
            </Badge>
        ));
    }

    // if (cell.Header.toLowerCase() === 'tags' && cell.value.length > 0) {
    //     return cell.value.split(',').map((tag, index) => (
    //         <Badge colorScheme='blue' key={index} me='1' mt='1'>
    //             {tag}
    //         </Badge>
    //     ));
    // }


    if (column.Header.toLowerCase() === 'images') {
        const images = cell.value;
        if (images == undefined || images.length === 0) {
            return <Text color={textColor} fontSize='sm'>None</Text>;
        }
        return (
            <Flex>
                {images.map((image, index) => (
                    <Image
                        key={index}
                        src={image}
                        w="56px"
                        h="56px"
                        me={index === images.length - 1 ? '0' : '8px'}
                        borderRadius="10px"
                        objectFit="contain"
                    />
                ))}
                {images.length > 3 && <Text color={textColor} fontSize="sm" ml="8px">+{images.length - 3}</Text>}
            </Flex>
        );
    }

    if (column.Header.toLowerCase() === 'status') {
        switch (cell.value?.toLowerCase()) {
            case 'pending':
                return <Badge colorScheme='yellow' borderRadius={"5px"}>Pending</Badge>;
            case 'processing':
                return <Badge colorScheme='blue' borderRadius={"5px"}>Processing</Badge>;
            case 'delivered':
            case 'active':
            case 'approved':
                return <Badge colorScheme='green' borderRadius={"5px"}>{cell.value}</Badge>;
            case 'rejected':
            case 'cancelled':
                return <Badge colorScheme='red' borderRadius={"5px"}>{cell.value}</Badge>;
            case 'shipped':
                return <Badge colorScheme='purple' borderRadius={"5px"}>Shipped</Badge>;
            default:
                return <Badge colorScheme='gray'
                    bg='secondaryGray.300'

                    borderRadius={"5px"}>{cell.value}</Badge>;
        }
    }

    if (column.Header.toLowerCase() === 'priority') {
        switch (cell.value.toLowerCase()) {
            case 'high':
                return <Badge colorScheme='red' borderRadius={"5px"}>{cell.value}</Badge>;
            case 'medium':
                return <Badge colorScheme='yellow' borderRadius={"5px"}>{cell.value}</Badge>;
            default:
                return <Badge colorScheme='gray'
                    bg='secondaryGray.300'
                    borderRadius={"5px"}>{cell.value}</Badge>;
        }
    }

    if (column.Header.toLowerCase() === 'date' || column.Header.toLowerCase() === 'last updated') {
        return (
            <Badge colorScheme='gray' fontWeight='normal' borderRadius={"5px"}>{cell.value}</Badge>
        );
    }

    if (column.Header.toLowerCase() === 'channel') {
        let logo;
        switch (cell.value.toLowerCase()) {
            case 'shopify':
                logo = <Image src={Shopify} />
                break;
            case 'amazon':
                logo = <Image src={Amazon} />;
                break;
            case 'google':
                logo = <Image src={Google} />;
                break;
            default:
                logo = null;
        }
        return logo ? (
            <Badge colorScheme='gray' bg='secondaryGray.300'

                borderRadius={"5px"}>
                <Flex align='center'>
                    <Box w='20px' me='5px'>
                        {logo}
                    </Box>
                    <Text color={textColor} fontSize='xs'>{cell.value}</Text>
                </Flex>
            </Badge>

        ) : <Badge colorScheme='gray' bg='secondaryGray.300'
            borderRadius={"5px"}>{cell.value}</Badge>;

    }

    if (column.Header.toLowerCase() === 'product') {
        return (
            <Text color={textColor} fontSize='sm' isTruncated>
                {cell.value}
            </Text>
        );
    }

    if (column.Header.toLowerCase() === 'revenue') {
        return (
            <Text color={textColor} fontSize='md'>
                {cell.value}
            </Text>
        );
    }

    if (column.Header.toLowerCase() === 'customer') {
        return (
            <Text color={textColor} fontSize='md'>
                {cell.value}
            </Text>
        );
    }

    if (column.Header === 'Actions') {
        return (
            <HStack spacing={2}>
                <Button
                    borderRadius="25px"
                    size="sm"
                    variant="outline"
                    colorScheme="brand"
                >
                    Edit
                </Button>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<MdMoreHoriz />}
                        variant="outline"
                        size="sm"
                        borderColor="brand"
                    />
                    <MenuList>
                        {/* <MenuItem icon={<MdEdit />}>Edit</MenuItem> */}
                        <MenuItem icon={<MdExitToApp />}>Export</MenuItem>
                        <MenuItem color="red.500" icon={<MdDelete color="red.500"/>}>Remove</MenuItem>
                    </MenuList>
                </Menu>

            </HStack>
        );
    }


    if (cell.column.Header === 'rating') {
        return (
            <Flex>
                <Text
                    me="6px"
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                >
                    {cell.value[0]}
                </Text>
                <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="400"
                >
                    ({cell.value[1]} votes)
                </Text>
            </Flex>
        );
    }

    if (cell.column.Header === 'price' || cell.column.Header === 'amount') {
        return (
            <Text
                color={'green.500'}
                fontSize="sm"
                fontWeight="600"
            >
                {cell.value}
            </Text>
        );
    }

    // Format currency
    if (cell.column.Header === 'currency') {
        return (
            <Text color={textColor} fontSize="sm" fontWeight="500">
                {cell.value}
            </Text>
        );
    }

    // Format vendor
    if (cell.column.Header === 'vendor') {
        return (
            <Text color={textColor} fontSize="sm" fontWeight="700">
                {cell.value}
            </Text>
        );
    }

    // if (cell.column.Header == 'customer') {
    //     return (
    //         <Text color="secondaryGray.600" fontSize='sm' fontWeight="700">
    //             {cell.value}
    //         </Text>
    //     );
    // }

    // Format line items
    if (cell.column.Header === 'items') {
        return (
            <Text color={textColor} fontSize="sm" fontWeight="500">
                {cell.value}
            </Text>
        );
    }

    if (cell.column.Header === 'order') {
        return (
            <Badge
                colorScheme='gray'
                fontSize="md"
                borderRadius={"5px"}
                bg='secondaryGray.300'
            >{cell.value}</Badge>
        );
    }

    if (cell.column.Header === 'sales') {
        return (
            <Text color={textColor} fontSize="md" fontWeight="500">
                {cell.value}
            </Text>
        );
    }

    if (cell.column.Header.toLowerCase() === 'segment name') {
        return (
            <Text color={textColor} fontSize="sm" fontWeight="bold">
                {cell.value}
            </Text>
        );
    }

    if ((cell.column.Header.toLowerCase() === 'name' ||
        cell.column.Header.toLowerCase() === 'order'
    ) && Array.isArray(cell.value)) {
        return (
            <Flex align="center">
                <Checkbox
                    value={cell.row.original.id}
                    defaultChecked={isSelected}
                    isChecked={isSelected}
                    colorScheme='brand'
                    me='10px'
                    onChange={(e) => handleCheckboxChange(e, cell.row.original.id)}
                />
                <Image
                    src={cell.value[2]}
                    w="36px"
                    h="36px"
                    me="8px"
                    borderRadius="10px"
                />
                <Flex direction="column">
                    <Text
                        color={textColor}
                        fontSize="sm"
                        fontWeight="700"
                    >
                        {cell.value[0]}
                    </Text>
                    <Text
                        color="secondaryGray.500"
                        fontSize="sm"
                        fontWeight="600"
                    >
                        {cell.value[1]}
                    </Text>
                </Flex>
            </Flex>
        );
    }

    if (cell.column.Header === 'metadata') {
        return (
            <Flex align="center">
                <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    me="8px"
                >
                    None
                </Text>
            </Flex>
        );

    }

    if (cell.column.Header === 'actions') {
        return (
            <ButtonGroup align='center'  >
                {cell.value.map((item, key) => {
                    if (item === "edit") {
                        return (
                            <Button
                                onClick={() => handleItemClick('edit', cell.row.original)}
                            >
                                <MdEdit
                                    key={key}
                                    color={iconColor}
                                    me='16px'
                                    size='20px'
                                    cursor='pointer'
                                />
                            </Button>
                        );
                    } else if (item === "delete") {
                        return (
                            <Button
                                onClick={() => handleItemClick('delete', cell.row.original)}
                            >
                                <IoMdTrash
                                    key={key}
                                    color={iconColor}
                                    me='16px'
                                    size='20px'
                                />
                            </Button>
                        );
                    } else if (item === "view") {
                        return (
                            <Button
                                onClick={() => handleItemClick('view', cell.row.original)}
                            >
                                <MdOpenInNew
                                    key={key}
                                    color={iconColor}
                                    size='20px'
                                />
                            </Button>
                        );
                    } else {
                        return null;
                    }
                })}
            </ButtonGroup>
        )
    }

    // For all other columns, render the cell value as text
    return (
        <Text color={textColor} fontSize="sm" fontWeight="500">
            {cell.value}
        </Text>
    );
}