import { ReactComponent as PromotionsIcon } from 'assets/img/icons/promotions.svg';
import { FaCheckCircle, FaEllipsisH } from 'react-icons/fa';
import Card from 'components/card/Card';
import { Flex, FormControl, Icon, SimpleGrid, Text, UnorderedList } from '@chakra-ui/react';
import ListItem from 'components/widgets/list/ListItem';
import UpgradeToPro from 'components/modals/UpgradeToPro';

export default function PromotionDetails() {
    return (
        <FormControl>
            <Card mb="20px" mt="20px" boxShadow="base" borderRadius="10px">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="40px" py="20px">
                    <Flex direction="column" ms="10px" align="center">
                        <Icon as={PromotionsIcon} boxSize="50px" mb="20px" />
                        <Text fontSize="xl" fontWeight="bold" mb="20px">
                            Promote Your Products
                        </Text>
                        <UpgradeToPro source="Promote Your Products" />
                    </Flex>
                    <UnorderedList spacing={4} px="10px">
                        <ListItem
                            icon={FaCheckCircle}
                            title="Create discounts and promotions"
                            showBadge={false}
                        />
                        <ListItem
                            icon={FaCheckCircle}
                            title="Offer free shipping"
                            showBadge={false}
                        />
                        <ListItem
                            icon={FaCheckCircle}
                            title="Connect Loyalty Programs"
                            showBadge={false}
                        />
                        <ListItem
                            icon={FaEllipsisH}
                            title="+ more"
                            showBadge={false}
                        />
                    </UnorderedList>
                </SimpleGrid>
            </Card>
        </FormControl>
    );
}
