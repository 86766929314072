import React from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Button,
    Box,
    Heading,
    Flex,
    IconButton,
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

export default function DataSourceSection({ config, setConfig, borderColor }) {
    const addDataSource = () => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: [...(prevConfig.data_sources || []), {}]
        }));
    };

    const removeDataSource = (index) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: prevConfig.data_sources.filter((_, i) => i !== index)
        }));
    };

    const handleDataSourceChange = (index, field, value) => {
        const newDataSources = [...config.data_sources];
        newDataSources[index] = { ...newDataSources[index], [field]: value };
        setConfig({ ...config, data_sources: newDataSources });
    };

    return (
        <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
            <Heading size="sm" mb={3}>Data Sources</Heading>
            <Flex direction="row" justifyContent="space-between" mb={4}>
                <Button onClick={addDataSource} colorScheme="brand" borderRadius="md">
                    <AddIcon me={2} /> Add Data Source
                </Button>
            </Flex>
            {config?.data_sources?.map((dataSource, index) => (
                <Card key={index} borderRadius="md" boxShadow='md' w="100%" background="gray.200" mb={4}>
                    <VStack spacing={2} align="stretch">
                        <IconButton
                            aria-label="remove data source"
                            icon={<DeleteIcon color="gray.500" fontSize="sm" cursor="pointer" />}
                            onClick={() => removeDataSource(index)}
                            variant="ghost"
                            size="sm"
                            colorScheme="red"
                            width="50px"
                            alignSelf="end"
                        />
                        <FormControl p={2}>
                            <FormLabel color="gray.500" fontSize="sm" fontWeight="bold">Data Source {index + 1}</FormLabel>
                            <Select
                                background="white"
                                value={dataSource.source}
                                onChange={(e) => handleDataSourceChange(index, 'source', e.target.value)}
                                placeholder="Select data source"
                            >
                                <option value="Google Ads">Google Ads</option>
                                <option value="Facebook Ads">Facebook Ads</option>
                                <option value="Google Analytics 4">Google Analytics 4</option>
                                <option value="Shopify">Shopify</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel
                                color="gray.500"
                                fontSize="sm"
                                fontWeight="bold">Account ID</FormLabel>
                            <Input
                                background="white"
                                value={dataSource.account_id}
                                onChange={(e) => handleDataSourceChange(index, 'account_id', e.target.value)}
                                placeholder="Account ID"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel
                                color="gray.500"
                                fontSize="sm"
                                fontWeight="bold">Metric</FormLabel>
                            <Input
                                background="white"
                                value={dataSource.metric}
                                onChange={(e) => handleDataSourceChange(index, 'metric', e.target.value)}
                                placeholder="Metric"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel
                                color="gray.500"
                                fontSize="sm"
                                fontWeight="bold">Aggregation</FormLabel>
                            <Select
                                background="white"
                                value={dataSource.aggregation}
                                onChange={(e) => handleDataSourceChange(index, 'aggregation', e.target.value)}
                            >
                                <option value="sum">Sum</option>
                                <option value="average">Average</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel
                                color="gray.500"
                                fontSize="sm"
                                fontWeight="bold">Breakdown</FormLabel>
                            <Input
                                background="white"
                                value={dataSource.breakdown}
                                onChange={(e) => handleDataSourceChange(index, 'breakdown', e.target.value)}
                                placeholder="Breakdown"
                            />
                        </FormControl>

                        <Box
                            h="10px" />
                        <Button
                            variant="brand"
                            borderRadius="md"

                        >Save</Button>
                    </VStack>
                </Card>
            ))}
        </Box>
    );
}
