// src/components/editModals/FunnelChartWidgetModal.js
import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Checkbox,
    HStack,
    Button,
} from "@chakra-ui/react";

export default function FunnelChartWidgetModal({ widget, onSave }) {
    const [config, setConfig] = useState(widget.config || {});

    const handleChange = (e, nestedKey = null) => {
        const { name, value, type, checked } = e.target;
        setConfig(prevConfig => ({
            ...prevConfig,
            [nestedKey ? `${nestedKey}.${name}` : name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleDataSourceChange = (index, field, value) => {
        const newDataSources = [...config.data_sources];
        newDataSources[index] = { ...newDataSources[index], [field]: value };
        setConfig({ ...config, data_sources: newDataSources });
    };

    const addDataSource = () => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: [...(prevConfig.data_sources || []), {}]
        }));
    };

    const removeDataSource = (index) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: prevConfig.data_sources.filter((_, i) => i !== index)
        }));
    };

    return (
        <VStack spacing={4} align="stretch">
            {/* Data Sources */}
            {config.data_sources?.map((dataSource, index) => (
                <VStack key={index} spacing={2} align="stretch" borderWidth={1} borderRadius="md" p={2}>
                    <FormControl>
                        <FormLabel>Data Source {index + 1}</FormLabel>
                        <Input
                            value={dataSource.source}
                            onChange={(e) => handleDataSourceChange(index, 'source', e.target.value)}
                            placeholder="Source name"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Account ID</FormLabel>
                        <Input
                            value={dataSource.account_id}
                            onChange={(e) => handleDataSourceChange(index, 'account_id', e.target.value)}
                            placeholder="Account ID"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Stage Name</FormLabel>
                        <Input
                            value={dataSource.stage_name}
                            onChange={(e) => handleDataSourceChange(index, 'stage_name', e.target.value)}
                            placeholder="Stage Name"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Value Metric</FormLabel>
                        <Input
                            value={dataSource.value_metric}
                            onChange={(e) => handleDataSourceChange(index, 'value_metric', e.target.value)}
                            placeholder="Value Metric"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Aggregation</FormLabel>
                        <Select
                            value={dataSource.aggregation}
                            onChange={(e) => handleDataSourceChange(index, 'aggregation', e.target.value)}
                        >
                            <option value="sum">Sum</option>
                            <option value="average">Average</option>
                            <option value="count">Count</option>
                        </Select>
                    </FormControl>
                    <Button onClick={() => removeDataSource(index)} colorScheme="red">Remove Data Source</Button>
                </VStack>
            ))}
            <Button onClick={addDataSource} colorScheme="blue">Add Data Source</Button>

            {/* Chart Settings */}
            <FormControl>
                <FormLabel>Color Scheme</FormLabel>
                <Select
                    name="color_scheme"
                    value={config.chart_settings?.color_scheme || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                >
                    <option value="sequential">Sequential</option>
                    <option value="categorical">Categorical</option>
                </Select>
            </FormControl>

            <Checkbox
                name="show_percentages"
                isChecked={config.chart_settings?.show_percentages}
                onChange={(e) => handleChange(e, 'chart_settings')}
            >
                Show Percentages
            </Checkbox>

            <Checkbox
                name="show_values"
                isChecked={config.chart_settings?.show_values}
                onChange={(e) => handleChange(e, 'chart_settings')}
            >
                Show Values
            </Checkbox>

            <FormControl>
                <FormLabel>Funnel Type</FormLabel>
                <Select
                    name="funnel_type"
                    value={config.chart_settings?.funnel_type || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                >
                    <option value="normal">Normal</option>
                    <option value="inverted">Inverted</option>
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>Tooltip Format</FormLabel>
                <Select
                    name="tooltip_format"
                    value={config.chart_settings?.tooltip_format || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                >
                    <option value="absolute_value">Absolute Value</option>
                    <option value="percentage">Percentage</option>
                    <option value="both">Both</option>
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>Chart Title</FormLabel>
                <Input
                    name="chart_title"
                    value={config.chart_settings?.chart_title || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="Chart Title"
                />
            </FormControl>

            {/* Refresh Rate */}
            <FormControl>
                <FormLabel>Refresh Rate</FormLabel>
                <Select
                    name="refresh_rate"
                    value={config.refresh_rate || ''}
                    onChange={handleChange}
                >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                </Select>
            </FormControl>
        </VStack>
    );
}
