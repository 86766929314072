/*eslint-disable */
import {
	Avatar,
	Badge,
	Box,
	Button,
	Flex,
	HStack,
	Icon,
	Image,
	Input,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Textarea,
	useColorModeValue,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	VStack,
	Text,
	Divider,
	useDisclosure,
	InputGroup,
	InputLeftElement,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { clearRxDatabase } from 'util/RxDatabase';
import { MdApps, MdSettings } from 'react-icons/md';


// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline, MdTask, MdChat, MdChatBubbleOutline } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import { AuthContext } from 'contexts/AuthContext';
import { getAuth, signOut } from "firebase/auth";
import { log } from 'util/Logger';
import { collection, doc, getDocs, limit, onSnapshot, orderBy, query, startAfter, updateDoc, writeBatch } from 'firebase/firestore';
import { firestore } from 'firebaseApp';
import { getUnreadNotificationsCount } from 'api/notifications/getUnreadNotificationsCount';
import { DEFAULT_ROUTES } from 'layouts/admin';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import ChatWithUs from 'components/support/ChatWithUs';
import { SearchIcon } from '@chakra-ui/icons';

import shopify from "assets/img/icons/shopify.webp";
import ga4 from "assets/img/icons/ga4.png";
import google from "assets/img/icons/google.svg";
import instagram from "assets/img/icons/instagram.webp";
import meta from "assets/img/icons/meta.png";
import lightspeed from "assets/img/icons/lightspeed.jpg";
import mailchimp from "assets/img/icons/mailchimp.webp";
import amazon from "assets/img/icons/amazon.webp";
import linkedin from "assets/img/icons/linkedin.webp";
import searchconsole from "assets/img/icons/searchconsole.svg";
import salesforce from "assets/img/icons/salesforce.png";
import klaviyo from "assets/img/icons/klaviyo.webp";
import hubspot from "assets/img/icons/hubspot.webp";
import youtube from "assets/img/icons/youtube.png";
import xlogo from "assets/img/icons/xlogo.svg";
import woocommerce from "assets/img/icons/woo.png";
import googledatastudio from "assets/img/icons/googledatastudio.png";

const ConnectedAppItem = ({ app, onDisconnect }) => (
	<Flex alignItems="center" justifyContent="space-between" p={2} borderRadius="md" _hover={{ bg: "gray.100" }}>
		<Flex alignItems="center">
			<Image
				src={app.icon}
				boxSize="24px"
				mr={3}
				objectFit="contain"
				alignSelf="center"
			/>
			<Text fontWeight="medium">{app.name}</Text>
			<Badge ml={2} colorScheme="green">Connected</Badge>
		</Flex>
		<Button
			borderRadius={"sm"}
			size="sm" variant="outline" colorScheme="gray" onClick={() => onDisconnect(app)}>
			Disconnect
		</Button>
	</Flex>
);


const MarketplaceAppItem = ({ app, onConnect, onDisconnect }) => (
	<Flex alignItems="center" justifyContent="space-between" p={2} borderRadius="md" _hover={{ bg: "gray.100" }}>
		<Flex alignItems="center">

			{app.status === 'custom' ? <Icon w="25px" h="25px" me="10px" as={MdSettings} /> : <Image src={app.icon} boxSize="24px" mr={3} objectFit="contain" alignSelf="center" />}

			<Text fontWeight="medium">{app.name}</Text>
			{app.status === 'connected' && <Badge ml={2} colorScheme="green">Connected</Badge>}
		</Flex>
		{app.status === 'connected' ? (
			<Button size="sm" variant="outline" colorScheme="gray" onClick={() => onDisconnect(app)}>Disconnect</Button>
		) : (
			<Button size="sm" colorScheme="brand" variant="outline" onClick={() => onConnect(app)}>Connect</Button>
		)}
	</Flex>
);


export default function HeaderLinks(props) {
	const { secondary } = props;
	const { currentUser } = useContext(AuthContext);
	const [notifications, setNotifications] = useState([]);
	const [lastVisible, setLastVisible] = useState(null);
	const [hasMore, setHasMore] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [unreadCount, setUnreadCount] = useState(0);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [searchTerm, setSearchTerm] = useState('');

	const connectedApps = [
		{ name: 'Google Ads', icon: google },
		{ name: 'Google Analytics', icon: ga4 },
		{ name: 'Facebook Ads', icon: meta },
		{ name: 'Shopify', icon: shopify },
		{ name: 'Mailchimp', icon: mailchimp },
	];

	const marketplaceApps = [
		{ name: 'Google Ads', icon: 'google-ads-icon.png', status: 'connected' },
		{ name: 'Facebook Ads', icon: 'facebook-ads-icon.png', status: 'connected' },
		{ name: 'Google Analytics 4', icon: 'ga4-icon.png', status: 'connected' },
		{ name: 'LinkedIn Ads', icon: linkedin, status: 'available' },
		{ name: 'Google Search Console', icon: searchconsole, status: 'available' },
		{ name: 'Salesforce', icon: salesforce, status: 'available' },
		{ name: 'HubSpot', icon: hubspot, status: 'available' },
		{ name: 'Shopify', icon: 'shopify-icon.png', status: 'connected' },
		{ name: 'Twitter/X Ads', icon: xlogo, status: 'available' },
		{ name: 'YouTube', icon: youtube, status: 'available' },
		{ name: 'Mailchimp', icon: mailchimp, status: 'connected' },
		{ name: 'Klaviyo', icon: klaviyo, status: 'available' },
		{ name: 'WooCommerce', icon: woocommerce, status: 'available' },
		{ name: 'Google Data Studio', icon: googledatastudio, status: 'available' },
		{ name: 'Custom API Connector', icon: <Icon as={MdSettings} />, status: 'custom' }
	];


	const filteredMarketplaceApps = marketplaceApps.filter(app =>
		app.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const handleConnectApp = (app) => {
		// Implement the logic to connect the app
		console.log(`Connecting ${app.name}`);
	};



	const toggleNotificationRead = async (notificationId, isRead) => {
		try {
			const notificationRef = doc(firestore, `Users/${currentUser.uid}/Notifications`, notificationId);
			await updateDoc(notificationRef, { isRead: !isRead });
		} catch (error) {
			console.error("Error updating notification read status:", error);
		}
	};

	const handleSignOut = async () => {
		const auth = getAuth();
		try {
			await signOut(auth);
			await clearRxDatabase();
			log('Signed out and cleared RxDB');
		} catch (error) {
			console.error('Error signing out or clearing RxDB', error);
		}
	};

	useEffect(() => {
		if (currentUser && currentUser.uid) {
			const fetchNotifications = async () => {
				const notificationsRef = collection(firestore, `Users/${currentUser.uid}/Notifications`);
				const constraints = [orderBy("createdAt", "desc"), limit(5)];

				const notificationsQuery = query(notificationsRef, ...constraints);
				const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
					const fetchedNotifications = snapshot.docs.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}));
					console.log('notifications: ', fetchedNotifications);
					setNotifications(fetchedNotifications);
					setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
					setHasMore(fetchedNotifications.length === 5);
				}, (error) => {
					console.error('Error fetching notifications:', error);
				});

				// Cleanup function
				return () => {
					unsubscribe();
				};
			};

			fetchNotifications();
		}
	}, [currentUser]);

	useEffect(() => {
		const fetchUnreadCount = async () => {
			try {
				if (currentUser && currentUser.uid) {
					const count = await getUnreadNotificationsCount(currentUser.uid);
					setUnreadCount(count);
				}
			} catch (error) {
				console.error('Error fetching unread notifications count:', error);
			}
		};

		fetchUnreadCount();
	}, [notifications]);


	const markAllAsRead = async () => {
		try {
			const batch = writeBatch(firestore);

			notifications.forEach((notification) => {
				const notificationRef = doc(firestore, `Users/${currentUser.uid}/Notifications`, notification.id);
				batch.update(notificationRef, { isRead: true });
			});

			await batch.commit();

			setUnreadCount(0);
		} catch (error) {
			console.error("Error marking all notifications as read:", error);
		}
	};

	const handleViewMore = async () => {
		if (loadingMore || !hasMore) return;

		setLoadingMore(true);

		const notificationsRef = collection(firestore, `Users/${currentUser.uid}/Notifications`);
		const constraints = [orderBy("createdAt", "desc"), startAfter(lastVisible), limit(5)];

		const notificationsQuery = query(notificationsRef, ...constraints);
		const snapshot = await getDocs(notificationsQuery);

		const fetchedNotifications = snapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));

		setNotifications((prevNotifications) => [...prevNotifications, ...fetchedNotifications]);
		setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
		setHasMore(fetchedNotifications.length === 5);
		setLoadingMore(false);
	};

	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg="none"
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			borderRadius="999px"
		>
			<SearchBar
				borderRadius="10px"
				me='15px'
				background='#fff'
				borderwidth='1px'
				borderColor='#E0E5F2'
			/>
			<Flex
				bg={ethBg}
				display={secondary ? 'flex' : 'none'}
				borderRadius="30px"
				ms="auto"
				p="6px"
				align="center"
				me="6px">
				<Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
					<Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
				</Flex>
				<Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
					1,924
					<Text as="span" display={{ base: 'none', md: 'unset' }}>
						{' '}
						ETH
					</Text>
				</Text>
			</Flex>
			<SidebarResponsive routes={DEFAULT_ROUTES} />
			<Menu>
				<MenuButton p="0px" >
					<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
					{unreadCount > 0 && (
						<>

							<Box
								position="absolute"
								top="30px"
								right="97px"
								width="8px"
								height="8px"
								borderRadius="50%"
								bg="red.500"
							/>
						</>
					)}
				</MenuButton>

				<MenuList
					boxShadow={shadow}
					p="20px"
					mt="22px"
					me={{ base: '30px', md: 'unset' }}
					borderRadius="20px"
					bg={menuBg}
					border="none"
					maxH="400px"
					overflowY="auto"
				>
					<Flex flexDirection="column" p="20px">
						<Flex justify="space-between" align="center" mb="20px">
							<HStack>
								<Text fontSize="md" fontWeight="600" color={textColor}>
									Notifications
								</Text>
								<Badge colorScheme="red" borderRadius="50%" height="28px" ms="5px" fontSize={"sm"} textAlign={"center"} pr="10px">{unreadCount}</Badge>
							</HStack>

							{notifications.length > 0 && (
								<Text fontSize="sm" fontWeight="500" color={textColorBrand} cursor="pointer" onClick={markAllAsRead}>
									Mark all as read
								</Text>
							)}
						</Flex>
						{notifications.length > 0 ? (
							<Box>
								{notifications.map((notification, index) => (
									<Flex direction="row">
										<ItemContent
											info={notification.message}
											aName={notification.sender}
											isRead={notification.isRead}
											createdAt={notification.createdAt}
											type={notification.type}
											data={notification.data}
											onToggleRead={() => toggleNotificationRead(notification.id, notification.isRead)}
										/>
									</Flex>
								))}
							</Box>
						) : (
							<Box textAlign="center" py="20px">
								<Text fontSize="md" color={textColor}>
									No notifications found.
								</Text>
							</Box>
						)}
						{hasMore && (
							<Button
								variant="ghost"
								colorScheme="blue"
								size="sm"
								onClick={handleViewMore}
								mt="10px"
								isLoading={loadingMore}
							>
								View More
							</Button>
						)}
					</Flex>
				</MenuList>
			</Menu>

			<Menu>
				<MenuButton p='0px'>
					<Icon
						mt='6px'
						as={MdChat}
						color={"brand"}
						w='18px'
						h='18px'
						me='10px'
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p='20px'
					me={{ base: "30px", md: "unset" }}
					borderRadius='20px'
					bg={menuBg}
					border='none'
					mt='22px'
					minW={{ base: "unset" }}
					maxW={{ base: "360px", md: "unset" }}>
					<ChatWithUs />
				</MenuList>
			</Menu>

			<Menu>
				<MenuButton p='0px' onClick={onOpen}>
					<Icon
						mt='6px'
						as={MdApps}
						color={'brand'}
						w='18px'
						h='18px'
						me='10px'
					/>
				</MenuButton>

			</Menu>

			{/* <ThemeEditor navbarIcon={navbarIcon} /> */}

			<Menu>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name={currentUser.displayName}
						bg="#001842"
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hey, {currentUser.displayName}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						{/* <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Profile Settings</Text>
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							<Text fontSize="sm">Newsletter Settings</Text>
						</MenuItem> */}
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px"
							onClick={handleSignOut}>
							<Text fontSize="sm">Log out</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>

			<Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth="1px">Connected Apps</DrawerHeader>
					<DrawerBody>
						<VStack align="stretch" spacing={6}>
							<Box>
								<VStack align="stretch" spacing={2}>
									{connectedApps.map((app, index) => (
										<ConnectedAppItem key={index} app={app} />
									))}
								</VStack>
							</Box>
							<Divider />
							<Box>
								<Text fontWeight="bold" mb={2}>App Marketplace</Text>
								<InputGroup mb={4}>
									<InputLeftElement pointerEvents="none">
										<SearchIcon color="gray.300" />
									</InputLeftElement>
									<Input
										placeholder="Search apps"
										value={searchTerm}
										onChange={(e) => setSearchTerm(e.target.value)}
									/>
								</InputGroup>
								<VStack align="stretch" spacing={2} maxH="300px" overflowY="inherit" >
									{filteredMarketplaceApps.filter(app => app.status !== 'connected').map((app, index) => (
										<MarketplaceAppItem key={index} app={app} onConnect={handleConnectApp} />
									))}
								</VStack>
								<Box h="50px" />
							</Box>
						</VStack>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
