// Chakra imports
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
// Assets

function Dropzone(props) {
  const { content, onFileUpload, ...rest } = props;
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach((file) => {
      // Check file type
      if (!['image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
        console.log('Only PNG, JPG, and GIF files are allowed');
        return;
      }

      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        onFileUpload(binaryStr, file);
      }
      reader.readAsArrayBuffer(file);
    });
  }, [onFileUpload]);
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');
  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="10px"
      w="100%"
      maxW="100%"
      h={{ base: '208px', '3xl': '300px' }}
      cursor="pointer"
      {...getRootProps({ className: 'dropzone' })}
      {...rest}
    >
      <input {...getInputProps()} />
      <Button variant="no-effects">{content}</Button>
    </Flex>
  );
}

export default Dropzone;
