/*eslint-disable*/
import { Alert, AlertDescription, AlertIcon, AlertTitle, Badge, Button, CloseButton, Flex, HStack, Image, LightMode, Text, Tooltip, VStack, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import { useContext, useEffect, useState } from 'react';

import shopify from "assets/img/icons/shopify.webp";
import amazon from "assets/img/icons/amazon.webp";
import google from "assets/img/icons/google.svg";
import { InfoIcon } from '@chakra-ui/icons';

export default function Settings(props) {
  const { onSaveChanges, channels } = props;
  const { hasUnsavedChanges } = useContext(ProductInEditContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const handleSaveChanges = async () => {
    setIsLoading(true);
    onSaveChanges();
  };

  useEffect(() => {
    if (!hasUnsavedChanges) {
      setIsLoading(false);
    }
  }, [hasUnsavedChanges]);

  const getImage = (name) => {
    switch (name) {
      case 'shopify':
        return shopify;
      case 'amazon':
        return amazon;
      case 'google':
        return google;
      default:
        return ''
    }
  }

  // Chakra Color Mode
  let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

  return (

    <Card
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      w="100%"
      boxShadow="base"
      borderRadius="10px"
      px="20px"
      border="1px solid"
      borderColor={borderColor}
    // borderColor="gray.300"
    // borderWidth="1px"
    >

      <VStack width="stretch" >

        {Object.keys(channels).length > 0 && (
          <Text color={textColor} fontSize="xl" fontWeight="700" mb="10px" ms="5px" align={'left'}
            alignSelf={'flex-start'}>
            Save Changes
            <Tooltip label='Changes are automatically synced to all connected channels' fontSize='md'
            >
              <InfoIcon color='secondaryGray.500' ms='5px' />
            </Tooltip>
          </Text>
        )}

        {Object.keys(channels).map((channel) => (
          <Card
            borderRadius="10px"
            boxShadow="none"
            p="20px">
            <Flex
              justifyContent="space-between"
              flexDirection='row'
              alignItems="center"
              w="100%"
              px="10px"
            >
              <HStack>
                <Image
                  src={getImage(channel.toLocaleLowerCase())}
                  w="34px"
                  h="34px"
                />
                <Text
                  color={textColor}
                  fontSize="md"
                  me="6px"
                  fontWeight="700"
                  mb={{ base: '10px', md: '0px' }}
                >
                  {channel.charAt(0).toUpperCase() + channel.slice(1)}
                </Text>
              </HStack>
              <Flex
                align="center"
                flexDirection={{ base: 'column', md: 'row' }}
                verticalAlign="center"
              >
                <Badge colorScheme="green">Connected</Badge>
              </Flex>
            </Flex>
          </Card>
        ))}


        <HStack alignSelf="end" mb="20px" pt="20px">
          <LightMode>
            <Button
              colorScheme="red"
              variant="outline"
              p="15px 40px"
              fontSize="sm"
              fontWeight="500"
              ms={{ base: '0', md: 'auto' }}
              mb={{ base: '20px', md: '0' }}
              _hover={{ bg: 'whiteAlpha.100' }}
              _focus={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              borderRadius={"10px"}
              disabled={true}
            >
              Delete product
            </Button>
          </LightMode>
          {/* <LightMode>
            <Button
              colorScheme="gray"
              variant="outline"
              p="15px 40px"
              fontSize="sm"
              fontWeight="500"
              ms={{ base: '0', md: 'auto' }}
              mb={{ base: '20px', md: '0' }}
              me={{ base: '0', md: '20px' }}
              _hover={{ bg: 'whiteAlpha.100' }}
              _focus={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            >
              Discard draft
            </Button>
          </LightMode> */}
          <Button variant="brand" minW="183px" fontSize="sm" fontWeight="500"
            onClick={handleSaveChanges}
            isLoading={isLoading}
            borderRadius={"10px"}
            disabled={true}
          >
            Save changes
          </Button>
        </HStack>
        <Alert status="info" borderRadius="sm" variant="subtle" >
          <AlertTitle>In Preview Mode</AlertTitle>
          changes are not synced to connected channels.
        </Alert>
        {showAlert && (
          <Alert borderRadius="8px" status="success" variant="subtle">
            <Flex >
              <AlertIcon />
              <Flex direction="column">
                <AlertTitle mr="12px">Changes saved.</AlertTitle>
                <AlertDescription>
                  Product information has been successfully updated.
                </AlertDescription>
              </Flex>
            </Flex>
            <CloseButton position="absolute" right="8px" top="8px" />
          </Alert>)}
      </VStack>

    </Card>
  );
}
