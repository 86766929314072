// Chakra imports
import { Flex, Text, Avatar, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import profileAvatar from 'assets/img/avatars/avatar4.png';

export default function Profile(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';
  const borderColor = useColorModeValue('white', 'whiteAlpha.100');

  // Chakra Color Mode
  return (
    <Card
      borderRadius={"5px"}
      border="1px solid"
      borderColor={borderColor}
      boxShadow='sm'
      mb="20px" {...rest}>
      <Flex align="center">
        <Avatar src={profileAvatar} h="87px" w="87px" me="20px" />
        <Flex direction="column">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
            Adela Parkson
          </Text>
          <Text mt="1px" color={textColorSecondary} fontSize="md">
            adela@simmmple.com
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
