import React, { useState } from 'react';
import {
    Box, Heading, Text, Button, Flex, Stat, StatLabel, StatNumber, StatHelpText, StatArrow,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter,
    Alert, AlertIcon, AlertTitle, AlertDescription,
    VStack, HStack, Select, Progress, Table, Thead, Tbody, Tr, Th, Td
} from "@chakra-ui/react";
import Card from 'components/card/Card';
import LineChart from 'components/charts/LineAreaChart';
import BarChart from 'components/charts/BarChart';

const ActiveCampaigns = () => {
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [showCampaignDetails, setShowCampaignDetails] = useState(false);
    const [showEditWizard, setShowEditWizard] = useState(false);
    const [optimizationOption, setOptimizationOption] = useState(null);
    const [optimizationApplied, setOptimizationApplied] = useState(false);

    const campaigns = [
        { id: 'A', name: 'Campaign A', platform: 'Facebook', roas: 5.2 },
        { id: 'B', name: 'Campaign B', platform: 'Google Ads', roas: 3.8 },
        { id: 'C', name: 'Campaign C', platform: 'Email', roas: 1.9 },
        { id: 'D', name: 'Campaign D', platform: 'Facebook', roas: 0.8, isUnderperforming: true, conversions: 45, budgetSpent: 2500, totalBudget: 5000 }
    ];

    const campaignDetails = {
        impressions: 30000,
        clicks: 600,
        conversions: 45,
        ctr: 2,
        cpc: 4.17,
        cpa: 55,
        roas: 0.8,
        budgetSpent: 2500,
        totalBudget: 5000
    };

    const handleCampaignClick = (campaign) => {
        setSelectedCampaign(campaign);
        setShowCampaignDetails(true);
    };

    const handleOptimize = (option) => {
        setOptimizationOption(option);
        setShowEditWizard(true);
    };

    const handleConfirmOptimization = () => {
        setShowEditWizard(false);
        setOptimizationApplied(true);
        setShowCampaignDetails(false);
    };

    return (
        <Card
           
             p={6}>
            {/* <Heading size="md" mb={6}>Top Performing Campaigns</Heading> */}
            <Flex>
                <Box width="50%" pr={4}>
                    <VStack align="stretch" spacing={6}>
                        {campaigns.map(campaign => (
                            <Box
                                key={campaign.id}
                                p={5}
                                borderWidth={1}
                                borderRadius="20px"
                                boxShadow="md"
                                bg={selectedCampaign === campaign ? "brand.50" : "white"}
                                cursor="pointer"
                                onClick={() => handleCampaignClick(campaign)}
                            >
                                <Flex justify="space-between" align="center">
                                    <Text fontWeight="bold" fontSize="lg">{campaign.name} ({campaign.platform})</Text>
                                    <Stat>
                                        <StatLabel fontSize="sm">ROAS</StatLabel>
                                        <StatNumber color={campaign.roas >= 1 ? "green.500" : "red.500"} fontSize="xl">
                                            {campaign.roas}x
                                        </StatNumber>
                                    </Stat>
                                </Flex>
                                <VStack align="stretch" mt={4}>
                                    <Text fontSize="sm">Conversions: {campaign.conversions}</Text>
                                    <Text fontSize="sm">Budget spent: ${campaign.budgetSpent} of ${campaign.totalBudget}</Text>
                                </VStack>
                            </Box>
                        ))}
                    </VStack>
                </Box>
                <Box width="50%" pl={4}>
                    {selectedCampaign && (
                        <VStack align="stretch" spacing={6}>
                            <Heading size="md">{selectedCampaign.name} Performance Details</Heading>

                            <Box>
                                <Heading size="sm" mb={4}>Performance Over Time</Heading>
                                <LineChart
                                    chartData={[
                                        { name: 'Conversions', data: [10, 8, 6, 7, 5, 4, 5] },
                                        { name: 'Budget Spent', data: [500, 1000, 1500, 1800, 2100, 2300, 2500] }
                                    ]}
                                    chartOptions={{
                                        xaxis: { categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'] }
                                    }}
                                />
                            </Box>

                            <Box>
                                <Heading size="sm" mb={4}>Conversion Rate by Segment</Heading>
                                <BarChart
                                    chartData={[
                                        {
                                            name: 'Conversion Rate',
                                            data: [5.2, 2.8, 0.5],
                                        },
                                    ]}
                                    chartOptions={{
                                        xaxis: { categories: ['Champions', 'New Customers', 'At-Risk Customers'] },
                                        colors: ['#4318FF', '#6AD2FF', '#EFF4FB'],
                                        plotOptions: {
                                            bar: {
                                                borderRadius: 8,
                                                horizontal: false,
                                                columnWidth: '50%',
                                            },
                                        },
                                    }}
                                />
                            </Box>

                            <Alert status="warning" borderRadius="15px">
                                <AlertIcon />
                                <Box>
                                    <AlertTitle fontWeight="bold">Recommendation:</AlertTitle>
                                    <AlertDescription>
                                        Your campaign is underperforming for the At-Risk Customers segment. Consider adjusting the audience or offer to improve conversions.
                                    </AlertDescription>
                                </Box>
                            </Alert>

                            <HStack spacing={4}>
                                <Button colorScheme="blue" borderRadius="15px" onClick={() => handleOptimize('narrow')}>Narrow Audience</Button>
                                <Button colorScheme="green" borderRadius="15px" onClick={() => handleOptimize('adjust')}>Adjust Offer</Button>
                            </HStack>
                        </VStack>
                    )}
                </Box>
            </Flex>
        </Card>
    );
};

export default ActiveCampaigns;
