import React, { useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import Widget from '../widgets/Widget';

const ResponsiveGridLayout = WidthProvider(Responsive);

function Content({ size: { width }, layouts, 
    setLayouts, widgets, onEdit, onCopyTo, onMoveTo, onRemove, dashboards, currentDashboard }) {
    const findWidget = (id) => widgets?.find((widget) => widget.id === id);

    const getWidgetContent = (layout) => findWidget(layout.i)?.content;

    const isSpreadsheet = (layout) => findWidget(layout.i)?.isSpreadsheet === true;

    const items = layouts.lg || layouts.md || layouts.sm || layouts.xs || layouts.xxs;

    return (
        <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            width={width}
            resizeHandles={['s', 'w', 'e', 'sw', 'nw', 'se', 'ne']}
            draggableHandle=".my-drag-handle"
            onLayoutChange={(layout, layouts) => setLayouts(layouts)}
        >
            {items.map((layout) => (
                <div key={layout.i}>
                    <Widget
                        id={layout.i}
                        isSpreadsheet={isSpreadsheet(layout)}
                        onEdit={onEdit}
                        onCopyTo={onCopyTo}
                        onMoveTo={onMoveTo}
                        onRemove={onRemove}
                        dashboards={dashboards}
                        currentDashboard={currentDashboard}
                    >
                        {getWidgetContent(layout)}
                    </Widget>
                </div>
            ))}
        </ResponsiveGridLayout>
    );
}

export default withSize({ refreshMode: 'debounce', refreshRate: 60 })(Content);