import {
    Image, Flex, IconButton, Box, ButtonGroup, Text,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { MdUpload } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import Card from "components/card/Card";
import Dropzone from "./DropzoneCard";
import { useContext, useState, useCallback, useRef } from "react";
import { ProductInEditContext } from "contexts/ProductInEditContext";
import ImageViewer from "react-simple-image-viewer";

export default function Carousel(props) {
    const { setHasUnsavedChanges } = useContext(ProductInEditContext);

    const [images, setImages] = useState(images.filter((image) => image !== null && image !== undefined));
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const fileInputRef = useRef();

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const handlePrevImage = () => {
        setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
    };

    const handleNextImage = () => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    };

    const handleUpload = () => {
        fileInputRef.current.click();
    };

    const handleDelete = () => {
        setIsDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        // Remove current image from images array
        setImages(prevImages => prevImages.filter((_, index) => index !== currentImage));
        setCurrentImage(prevImage => prevImage === 0 ? 0 : prevImage - 1);
        setIsDeleteModalOpen(false);
    };

    const handleFileUpload = (fileContents, file) => {
        // Convert ArrayBuffer to Blob
        const blob = new Blob([new Uint8Array(fileContents)], { type: file.type });

        // Create object URL
        const url = URL.createObjectURL(blob);

        setImages((prevImages) => [...prevImages, url]);
        setHasUnsavedChanges(true);
    };

    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*,video/*"
                onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            handleFileUpload(reader.result, file);
                        };
                        reader.readAsArrayBuffer(file);
                    }
                }}
            />
            {images && images.length > 0 ? (
                <>
                    <Card mb="5px"
                        boxShadow="base"
                        borderRadius="10px"
                        position="relative"
                        p="0px"
                    >
                        {images.length > 1 && (
                            <>
                                <IconButton
                                    icon={<ChevronLeftIcon />}
                                    position="absolute"
                                    left="10px"
                                    top="50%"
                                    transform="translateY(-50%)"
                                    onClick={handlePrevImage}
                                />
                                <IconButton
                                    icon={<ChevronRightIcon />}
                                    position="absolute"
                                    right="10px"
                                    top="50%"
                                    transform="translateY(-50%)"
                                    onClick={handleNextImage}
                                />
                            </>
                        )}
                        <Box position="absolute" right="10px" top="10px">
                            <ButtonGroup>
                                <IconButton
                                    icon={<MdUpload />}
                                    onClick={handleUpload}
                                />
                                <IconButton
                                    icon={<IoMdTrash />}
                                    onClick={handleDelete}
                                />
                            </ButtonGroup>
                        </Box>
                        <Image
                            borderRadius="10px"
                            h={{ base: 'auto', xl: '396px' }}
                            src={images[currentImage]}
                            objectFit='cover'
                            onClick={() => openImageViewer(currentImage)}
                            style={{ cursor: 'pointer' }}
                        />
                    </Card>
                    <Text textAlign="center" mb='10px'>
                        {`${currentImage + 1}/${images.length}`}
                    </Text>
                    {isViewerOpen && (
                        <Flex>
                            <ImageViewer
                                src={images}
                                currentIndex={currentImage}
                                disableScroll={false}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                            />
                        </Flex>
                    )}
                    <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Confirm Delete</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                Are you sure you want to delete this image?
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={() => setIsDeleteModalOpen(false)}>
                                    Cancel
                                </Button>
                                <Button variant="ghost" onClick={confirmDelete}>
                                    Delete
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </>
            ) : (
                <Dropzone
                    mb="20px"
                    onFileUpload={handleFileUpload}
                />
            )}
        </>
    );
}