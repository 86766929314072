import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from "@chakra-ui/react"

export default class DrawerFactory {
    static createDrawer({
        isOpen,
        onClose,
        dismissible = true,
        onOverlayClick = onClose,
        headerComponents = [],
        bodyComponents,
        footerComponents = [],
        size = "lg",
    }) {
        return (
            <>
                <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    size={size}
                    closeOnEsc={dismissible}
                    closeOnOverlayClick={dismissible}
                    onOverlayClick={onOverlayClick}
                >
                    <DrawerOverlay />
                    <DrawerContent bg="#F4F7FE">
                        <DrawerCloseButton
                            onClick={(event) => {
                                event.preventDefault(); 
                                onOverlayClick();
                            }}
                        />
                        <DrawerHeader>{headerComponents}</DrawerHeader>

                        <DrawerBody>
                            {bodyComponents}
                        </DrawerBody>

                        <DrawerFooter>{footerComponents}</DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </>
        )
    }
}