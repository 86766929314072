import React, { useState } from "react";
import { Box, Heading, SimpleGrid, Select, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import BubbleChart from "components/charts/BubbleChart";
import AIInsight from "components/AIInsight";

// Mock data
const npsData = [
    { month: "Jan", nps: 65 },
    { month: "Feb", nps: 68 },
    { month: "Mar", nps: 70 },
    { month: "Apr", nps: 72 },
    { month: "May", nps: 75 },
    { month: "Jun", nps: 78 },
];

const sentimentData = [
    { text: "Great quality", value: 100 },
    { text: "Comfortable", value: 80 },
    { text: "Stylish", value: 75 },
    { text: "Expensive", value: 60 },
    { text: "Durable", value: 55 },
    { text: "Innovative", value: 50 },
    { text: "Poor customer service", value: 40 },
    { text: "Slow delivery", value: 35 },
    { text: "Limited sizes", value: 30 },
];

const feedbackTrendsData = [
    { month: "Jan", positive: 70, negative: 30 },
    { month: "Feb", positive: 75, negative: 25 },
    { month: "Mar", positive: 72, negative: 28 },
    { month: "Apr", positive: 78, negative: 22 },
    { month: "May", positive: 80, negative: 20 },
    { month: "Jun", positive: 82, negative: 18 },
];

const FeedbackSentiment = () => {
    const [selectedSegment, setSelectedSegment] = useState("All");
    const [selectedCategory, setSelectedCategory] = useState("All");

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <AIInsight message="Overall sentiment score has improved to 8.2/10. Recent product updates have been well-received by customers." />

            <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
                <Card>
                    <Heading size="md" mb="4">
                        NPS Tracking
                    </Heading>
                    <Select
                        mb="4"
                        value={selectedSegment}
                        onChange={(e) => setSelectedSegment(e.target.value)}
                    >
                        <option value="All">All Segments</option>
                        <option value="High Value">High Value</option>
                        <option value="Medium Value">Medium Value</option>
                        <option value="Low Value">Low Value</option>
                    </Select>
                    <Select
                        mb="4"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="All">All Categories</option>
                        <option value="Running">Running</option>
                        <option value="Basketball">Basketball</option>
                        <option value="Lifestyle">Lifestyle</option>
                    </Select>
                    <BarChart
                        chartData={[
                            {
                                name: "NPS",
                                data: npsData.map((item) => item.nps),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: npsData.map((item) => item.month),
                            },
                            yaxis: {
                                title: {
                                    text: "NPS Score",
                                },
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Sentiment Analysis
                    </Heading>
                    <BubbleChart
                        chartData={[
                            {
                                name: "Sentiment",
                                data: sentimentData.map((item) => [item.value, item.value, item.value]),
                            },
                        ]}
                        chartOptions={{
                            xaxis: { show: false },
                            yaxis: { show: false },
                            tooltip: {
                                y: {
                                    formatter: function (value, { seriesIndex, dataPointIndex }) {
                                        return sentimentData[dataPointIndex].text;
                                    },
                                },
                            },
                            bubble: {
                                minBubbleRadius: 10,
                                maxBubbleRadius: 50,
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Feedback Trends
                    </Heading>
                    <LineChart
                        chartData={[
                            {
                                name: "Positive Sentiment",
                                data: feedbackTrendsData.map((item) => item.positive),
                            },
                            {
                                name: "Negative Sentiment",
                                data: feedbackTrendsData.map((item) => item.negative),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: feedbackTrendsData.map((item) => item.month),
                            },
                            yaxis: {
                                title: {
                                    text: "Sentiment (%)",
                                },
                            },
                            markers: {
                                size: 4,
                            },
                            legend: {
                                position: "top",
                            },
                        }}
                    />
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default FeedbackSentiment;
