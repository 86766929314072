export const log = (message) => {
    if (process.env.REACT_APP_IS_DEVELOPMENT) {
        console.log(message);
    }
};

export const logData = (message, data) => {
    if (process.env.REACT_APP_IS_DEVELOPMENT) {
        console.log(message, data);
    }
};

export const error = (message) => {
    if (process.env.REACT_APP_IS_DEVELOPMENT) {
        console.error(message);
    }
};

export const errorData = (message, data) => {
    if (process.env.REACT_APP_IS_DEVELOPMENT) {
        console.error(message, data);
    }
};