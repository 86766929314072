// src/components/editModals/LineChartWidgetModal.js
import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Checkbox,
    HStack,
    Button,
    useColorModeValue,
    Box,
    Text,
    SimpleGrid,
    Heading,
    Divider,
    Flex,
    IconButton,
} from "@chakra-ui/react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';
import DataSourceSection from './DataSourceSection';

export default function LineChartWidgetModal({ widget, onSave, showPreview }) {
    const [config, setConfig] = useState({
        ...widget.config,
        data_sources: widget.config?.data_sources || []
    });
    const [previewData, setPreviewData] = useState([]);

    const borderColor = useColorModeValue('white', 'whiteAlpha.100');

    useEffect(() => {
        // Generate sample data for preview
        const sampleData = [
            { name: 'Jan', value: 400 },
            { name: 'Feb', value: 300 },
            { name: 'Mar', value: 200 },
            { name: 'Apr', value: 278 },
            { name: 'May', value: 189 },
        ];
        setPreviewData(sampleData);
    }, []);

    const handleChange = (e, nestedKey = null) => {
        const { name, value, type, checked } = e.target;
        setConfig(prevConfig => ({
            ...prevConfig,
            [nestedKey ? `${nestedKey}.${name}` : name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleDataSourceChange = (index, field, value) => {
        const newDataSources = [...config.data_sources];
        newDataSources[index] = { ...newDataSources[index], [field]: value };
        setConfig({ ...config, data_sources: newDataSources });
    };

    const addDataSource = () => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: [...(prevConfig.data_sources || []), {}]
        }));
    };

    const removeDataSource = (index) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: prevConfig.data_sources.filter((_, i) => i !== index)
        }));
    };

    return (

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="100%">
            {showPreview && (
                <Box height="600px" display="flex" alignItems="center" flexDirection="column" >
                    <Text mb={4} fontStyle="italic" fontSize="lg" textAlign="center">
                        Preview
                    </Text>
                    <Box flex="1" width="100%" display="flex" justifyContent="center" alignItems="center">
                        <ResponsiveContainer width="80%" height="80%">
                            <LineChart data={previewData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="value" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Box>
            )}
            <Box overflowY="auto" maxHeight="600px" pr={4}>
                <VStack spacing={6} align="stretch" width="100%">
                    <DataSourceSection config={config} setConfig={setConfig} onChange={handleDataSourceChange} />

                    <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
                        <Heading size="sm" mb={3}>Chart Settings</Heading>
                        <SimpleGrid columns={2} spacing={4}>
                            <FormControl>
                                <FormLabel fontWeight="medium">Line Style</FormLabel>
                                <Select
                                    name="line_style"
                                    value={config.chart_settings?.line_style || ''}
                                    onChange={(e) => handleChange(e, 'chart_settings')}
                                    bg="white"
                                >
                                    <option value="solid">Solid</option>
                                    <option value="dashed">Dashed</option>
                                    <option value="dotted">Dotted</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="medium">Tooltip Format</FormLabel>
                                <Select
                                    name="tooltip_format"
                                    value={config.chart_settings?.tooltip_format || ''}
                                    onChange={(e) => handleChange(e, 'chart_settings')}
                                    bg="white"
                                >
                                    <option value="absolute_value">Absolute Value</option>
                                    <option value="percentage">Percentage</option>
                                </Select>
                            </FormControl>
                        </SimpleGrid>
                    </Box>

                    <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
                        <Heading size="sm" mb={3}>Display Options</Heading>
                        <VStack spacing={3} align="start">
                            <Checkbox
                                name="show_legend"
                                isChecked={config.chart_settings?.show_legend}
                                onChange={(e) => handleChange(e, 'chart_settings')}
                            >
                                Show Legend
                            </Checkbox>
                            <Checkbox
                                name="show_data_labels"
                                isChecked={config.chart_settings?.show_data_labels}
                                onChange={(e) => handleChange(e, 'chart_settings')}
                            >
                                Show Data Labels
                            </Checkbox>
                        </VStack>
                    </Box>

                    <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
                        <Heading size="sm" mb={3}>Data Refresh</Heading>
                        <FormControl>
                            <FormLabel fontWeight="medium">Refresh Rate</FormLabel>
                            <Select
                                name="refresh_rate"
                                value={config.refresh_rate || ''}
                                onChange={handleChange}
                                bg="white"
                            >
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </Select>
                        </FormControl>
                    </Box>
                </VStack>
            </Box>
        </SimpleGrid>
    );
}
