/*eslint-disable*/
import {
    Flex,
    FormControl,
    Icon,
    SimpleGrid,
    Text,
    UnorderedList,
    useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useContext, useState } from 'react';
import { ReactComponent as InventoryIcon } from 'assets/img/icons/inventory.svg';
import ListItem from 'components/widgets/list/ListItem';
import { FaCheckCircle, FaEllipsisH } from 'react-icons/fa';
import UpgradeToPro from 'components/modals/UpgradeToPro';

export default function InventoryDetails(props) {
    const {
        id,
        inventory,
    } = props;

    const isDraft = id === 'new_product_draft';

    const initialInventory = isDraft ? productDraft?.inventory : inventory;

    const [productDetails, setProductDetails] = useState({
        inventory: initialInventory,
    })

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <FormControl>
            <Card mb="20px" mt="20px"
                boxShadow="base"
                borderRadius="10px">

                {/* <SimpleGrid
                    columns={{ sm: 1, md: 2 }}
                    spacing={{ base: '20px', xl: '20px' }}
                >
                    <InputField
                        mb="0px"
                        id="quantity"
                        label="Quantity"
                        placeholder="eg. 99"
                        type="number"
                        value={productDetails.inventory}
                        onChange={(e) => setProductInventory(e.target.value)}
                    />
                    <VStack
                        alignItems="flex-start"
                        spacing="0px"
                    >
                        <FormLabel
                            ms="10px"
                            htmlFor="location"
                            fontSize="sm"
                            color={textColorPrimary}
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                        >
                            Location
                        </FormLabel>
                        <Select
                            mt="0px"
                            fontSize="sm"
                            id="location"
                            variant="main"
                            h="44px"
                            maxH="44px"
                            me="20px"
                            defaultValue="Default Location"
                        >
                            <option value="location">Default Location</option>
                        </Select>
                    </VStack>
                </SimpleGrid> */}
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="40px" py="20px">
                    <Flex direction="column" ms="10px" align="center">
                        <Icon as={InventoryIcon} boxSize="50px" mb="20px" />
                        <Text fontSize="xl" color={textColorPrimary} fontWeight="bold" mb="20px">
                            Manage Inventory
                        </Text>
                        <UpgradeToPro source="Manage Inventory" />
                    </Flex>
                    <UnorderedList spacing={4} px="10px">
                        <ListItem
                            icon={FaCheckCircle}
                            title="Track Inventory in Real-Time"
                            showBadge={false}
                        />
                        <ListItem
                            icon={FaCheckCircle}
                            title="Manage Multiple Locations"
                            showBadge={false}
                        />
                        <ListItem
                            icon={FaCheckCircle}
                            title="Connect with 3rd Party APIs"
                            showBadge={false}
                        />
                        <ListItem
                            icon={FaEllipsisH}
                            title="+ more"
                            showBadge={false}
                        />
                    </UnorderedList>
                </SimpleGrid>
            </Card>

        </FormControl>
    );
}
