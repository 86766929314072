import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'assets/css/App.css';
import 'assets/css/Spreadsheet.css';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { onAuthStateChanged } from "firebase/auth";
import { AuthProvider } from 'contexts/AuthContext';
import { addRxPlugin } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { WidgetFactoryProvider } from 'contexts/WidgetFactoryContext';
import { auth } from './firebaseApp';


// if (process.env.NODE_ENV === "development") {
// 	connectAuthEmulator(auth, "http://localhost:9099");
// 	connectFirestoreEmulator(db, "localhost", 8080);
// 	connectFunctionsEmulator(functions, "localhost", 5001);
// }

addRxPlugin(RxDBDevModePlugin);

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(
		() => localStorage.getItem('isAuthenticated') === 'true'
	);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				setIsAuthenticated(true);
				localStorage.setItem('isAuthenticated', 'true');
				localStorage.setItem('displayName', user.displayName);
				localStorage.setItem('uid', user.uid);
			} else {
				setIsAuthenticated(false);
				localStorage.removeItem('isAuthenticated');
				localStorage.removeItem('displayName');
				localStorage.removeItem('uid');
			}
		});

		return () => unsubscribe();
	}, []);

	const PrivateRoute = ({ component: Component, ...rest }) => (
		<Route
			{...rest}
			render={props =>
				isAuthenticated ? (
					<WidgetFactoryProvider>
						<Component {...props} />
					</WidgetFactoryProvider>
				) : (
					<Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
				)
			}
		/>
	);

	return (
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<ThemeEditorProvider>
					<HashRouter>
						<Switch>
							{
								isAuthenticated ?
									<Redirect from='/auth' to='/admin' /> :
									null
							}
							<Route path='/auth' component={AuthLayout} />
							<PrivateRoute path={`/admin`} component={AdminLayout} />
							<Redirect from='/' to={isAuthenticated ? '/admin' : '/auth'} />
						</Switch>
					</HashRouter>
				</ThemeEditorProvider>
			</React.StrictMode>
			<ToastContainer />
		</ChakraProvider>
	);
}

ReactDOM.render(
	<AuthProvider>
		<App />
	</AuthProvider>,
	document.getElementById('root')
);