import React, { useState } from "react";
import {
    Box,
    Flex,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    Button,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    VStack,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    HStack,
    Select,
    IconButton,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { MdAdd, MdDelete } from 'react-icons/md';
import DateRangePicker from "components/datePicker/DateRangePicker";
import MiniCalendar from "components/calendar/MiniCalendar";

const eventsData = [
    { id: 1, name: "app_crash", count: 15, lastOccurred: "2023-05-20 14:30:00" },
    { id: 2, name: "button_click", count: 1250, lastOccurred: "2023-05-20 15:45:00" },
    { id: 3, name: "scroll", count: 3500, lastOccurred: "2023-05-20 15:50:00" },
    { id: 4, name: "product_view", count: 780, lastOccurred: "2023-05-20 15:55:00" },
    { id: 5, name: "add_to_cart", count: 320, lastOccurred: "2023-05-20 16:00:00" },
    { id: 6, name: "loyalty_enroll", count: 200, lastOccurred: "2023-05-20 16:05:00" },
];

const EventsAnalytics = () => {
    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const [tableData, setTableData] = useState(eventsData);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [properties, setProperties] = useState([{ property: '', condition: '', value: '' }]);
    const [newEvent, setNewEvent] = useState({
        name: '',
        loggingCriteria: {
            property: '',
            condition: '',
            value: '',
        },
        eventArguments: '',
        timeDuration: '',
    });

    const handleNewEventChange = (field, value) => {
        setNewEvent({ ...newEvent, [field]: value });
    };

    const handleLoggingCriteriaChange = (field, value) => {
        setNewEvent({
            ...newEvent,
            loggingCriteria: { ...newEvent.loggingCriteria, [field]: value },
        });
    };

    const addNewEvent = () => {
        const newEventId = tableData.length + 1;
        setTableData([
            ...tableData,
            {
                id: newEventId,
                name: newEvent.name,
                count: 0,
                lastOccurred: '-',
                status: 'Normal',
                loggingCriteria: newEvent.loggingCriteria,
                eventArguments: newEvent.eventArguments,
                timeDuration: newEvent.timeDuration,
            },
        ]);
        setNewEvent({
            name: '',
            loggingCriteria: {
                property: '',
                condition: '',
                value: '',
            },
            eventArguments: '',
            timeDuration: '',
        });
        onClose();
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <Flex justify="space-between" align="center" mb={4}>
                    <SearchBar />
                    <Button colorScheme="brand" leftIcon={<MdAdd />} onClick={onOpen}>
                        New Event
                    </Button>
                </Flex>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Event Name</Th>
                            <Th isNumeric>Count</Th>
                            <Th>Last Detected</Th>
                            <Th>Status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tableData.map((event) => (
                            <Tr key={event.id}>
                                <Td>{event.name}</Td>
                                <Td isNumeric>{event.count}</Td>
                                <Td>{event.lastOccurred}</Td>
                                <Td>
                                    <Badge colorScheme={event.name === "app_crash" ? "red" : "green"}>
                                        {event.name === "app_crash" ? "Critical" : "Normal"}
                                    </Badge>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Card>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>Create New Event</DrawerHeader>
                    <DrawerBody>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Event Name</FormLabel>
                                <Input
                                    value={newEvent.name}
                                    onChange={(e) => handleNewEventChange('name', e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Event Properties</FormLabel>
                                {properties.map(i => (
                                    <HStack mt={4}>
                                        <Input
                                            placeholder="Key"
                                            value={newEvent.loggingCriteria.property}
                                            onChange={(e) => handleLoggingCriteriaChange('property', e.target.value)}
                                        />
                                        <Select
                                            placeholder="Type"
                                            value={newEvent.loggingCriteria.condition}
                                            onChange={(e) => handleLoggingCriteriaChange('condition', e.target.value)}
                                        >
                                            <option value="string">String</option>
                                            <option value="number">Number</option>
                                            <option value="boolean">Boolean</option>
                                            <option value="json">JSON</option>
                                            {/* Add more conditions as needed */}
                                        </Select>
                                        <Input
                                            placeholder="Value"
                                            value={newEvent.loggingCriteria.value}
                                            onChange={(e) => handleLoggingCriteriaChange('value', e.target.value)}
                                        />
                                        <IconButton icon={<MdDelete />} onClick={() => setProperties(properties.filter((_, index) => index !== properties.length - 1))} />
                                    </HStack>
                                ))}
                                <Button
                                    colorScheme="ghost"
                                    color="brand.50"
                                    alignSelf={"center"}
                                    mt={4}
                                    onClick={() => setProperties([...properties, { property: '', condition: '', value: '' }])}
                                    leftIcon={<MdAdd />}
                                >Add Property</Button>
                            </FormControl>
                            {/* <FormControl>
                                <FormLabel>Event Properties</FormLabel>
                                <Input
                                    value={newEvent.eventArguments}
                                    onChange={(e) =>
                                        handleNewEventChange('eventArguments', e.target.value)
                                    }
                                />
                            </FormControl> */}
                            <FormControl >
                                <FormLabel mt={8}>Time Duration (Optional)</FormLabel>
                                <MiniCalendar />
                            </FormControl>
                        </VStack>
                    </DrawerBody>
                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={addNewEvent}>
                            Add Event
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default EventsAnalytics;
