/*eslint-disable*/
import { InfoIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
    Flex,
    Input,
    Tag,
    TagCloseButton,
    TagLabel,
    useColorModeValue,
    Icon,
    Text,
    IconButton,
    HStack,
} from '@chakra-ui/react';
// Custom components
import { useState, useContext, forwardRef } from 'react';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import Card from 'components/card/Card';

const NewAttributeOptionsEditor = forwardRef((props, ref) => {
    const { setHasUnsavedChanges } = useContext(ProductInEditContext);

    const [attributeName, setAttributeName] = useState('');
    const [tags, setTags] = useState([]);

    const {
        variants = [],
        setVariants,
        deletedVariants = [],
        setDeletedVariants,
        attributes,
        setAttributes,
        setIsAddingAttribute,
        ...rest
    } = props;

    console.log('variants:', variants);


    const handleTagsChange = (newTags) => {
        setTags(newTags);
        setHasUnsavedChanges(true);
    };

    const onSaveNewAttribute = () => {
        if (attributeName.trim() === '') {
            setInputError('Attribute name cannot be empty');
            return;
        }

        if (tags.length === 0) {
            setInputError('At least one option is required');
            return;
        }

        const newAttribute = {
            [attributeName]: tags.map((tag) => tag.name),
        };

        setAttributes({
            ...attributes,
            ...newAttribute,
        });

        const newVariants = generateVariants(
            { ...attributes, ...newAttribute }
        );

        setVariants(newVariants);
        setIsAddingAttribute(false);
    };

    const generateVariants = (attributes) => {
        const attributeNames = Object.keys(attributes);
        const attributeValues = Object.values(attributes);

        const generateCombinations = (index, currentCombination) => {
            if (index === attributeNames.length) {
                return [currentCombination];
            }

            const currentAttribute = attributeNames[index];
            const currentValues = attributeValues[index];

            const combinations = [];

            for (const value of currentValues) {
                const newCombination = { ...currentCombination, [currentAttribute]: value };
                const subCombinations = generateCombinations(index + 1, newCombination);
                combinations.push(...subCombinations);
            }

            return combinations;
        };

        const combinations = generateCombinations(0, {});

        return combinations.map((combination, index) => ({
            attributes: combination,
        }));
    };

    const onCancelNewAttribute = () => {
        setAttributeName('');
        setTags([]);
        setInputError('');
        setIsAddingAttribute(false);
    };

    const [inputError, setInputError] = useState('');

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            const newOption = e.target.value.trim();

            // Check if the new option already exists in the attributes (case-insensitive)
            if (tags.map(tag => tag.name).some((option) => option.toLowerCase() === newOption.toLowerCase())) {
                setInputError('Option already exists');
                return;
            }

            if (newOption.trim() === '') {
                return;
            }

            handleTagsChange([
                ...tags,
                {
                    name: newOption,
                    id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1,
                },
            ]);
            e.target.value = '';
            setInputError('');
        }
    };

    const handleDeleteClick = (tag) => {
        handleTagsChange(tags.filter((element) => element.id !== tag.id));
    };


    let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
    let bg = props.pillBg || 'brand.400';
    return (
        <Card
            p="12px"
            borderRadius="12px"
            border="1px solid"
            borderColor="brand.200"
            boxShadow="base"
            m="0px"
        >
            <Flex direction="row" alignItems="center">
                <Input
                    ref={ref}
                    type="text"
                    id={attributeName}
                    value={attributeName}
                    fontWeight="bold"
                    fontSize="sm"
                    variant="flushed"
                    placeholder="Attribute name"
                    ps="5px"
                    errorBorderColor="red.300"
                    _hover={{ cursor: 'text' }}
                    onChange={(e) => setAttributeName(e.target.value)}
                />
            </Flex>

            <Flex
                direction="row"
                p="12px"
                wrap="wrap"
                bg="gray.100"
                border="1px solid"
                borderColor={borderColor}
                borderRadius="10px"
                _focus={{ borderColor: 'teal.300' }}
                minH="40px"
                h="stretch"
                cursor="text"
                {...rest}
            >
                {tags.map((tag, index) => {
                    return (
                        <Tag
                            fontSize="xs"
                            h="25px"
                            mb="6px"
                            me="6px"
                            borderRadius="10px"
                            variant="solid"
                            bg={bg}
                            key={index}
                        >
                            <TagLabel w="100%">{tag.name}</TagLabel>
                            <TagCloseButton
                                justifySelf="flex-end"
                                color="white"
                                onClick={() => handleDeleteClick(tag)}
                            />
                        </Tag>
                    );
                })}

                <Input
                    variant="main"
                    bg="transparent"
                    border="none"
                    p="0px"
                    onKeyDown={(e) => keyPress(e)}
                    fontSize="sm"
                    isInvalid={inputError !== ''}
                    errorBorderColor="red.300"
                    autoFocus={true}
                    placeholder='Enter a new option'
                    _placeholder={{
                        color: 'gray.400',
                        fontSize: '14px',
                    }}
                />
                {inputError && (
                    <Text color="red.500" fontSize="sm" mt="4px">
                        {inputError}
                    </Text>
                )}
            </Flex>
            {tags.length === 0 && (
                <HStack mt="10px">
                    <Icon as={InfoIcon} color="gray.400" />
                    <Text fontSize="xs" color="gray.400">
                        To add an option, type it and press enter
                    </Text>
                </HStack>
            )}
            <HStack alignSelf="end" mb="20px" pt="20px">
                <IconButton
                    icon={<CheckIcon />}
                    onClick={onSaveNewAttribute}
                    disabled={tags.length === 0 || attributeName.trim() === ''}
                />
                <IconButton
                    icon={<CloseIcon />}
                    onClick={onCancelNewAttribute}
                />
            </HStack>
        </Card>
    );
});

export default NewAttributeOptionsEditor;