/*eslint-disable*/
import {
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import BulkEditMenu from 'components/menu/BulkEditMenu';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { DisplayOnlyRowAdapter } from 'components/widgets/table/RowAdapter';
import { RowAdapter } from 'components/widgets/table/SelectableRowAdapter';
import React, { useEffect, useMemo, useState } from 'react';
import { FaFileExport } from 'react-icons/fa';
import { IoFilter, IoTrash } from 'react-icons/io5';
import { MdChevronRight, MdChevronLeft, MdMoveToInbox, MdArrowDropDown, MdDateRange, MdCheck } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { logData } from 'util/Logger';
import ProductMenu from 'views/catalog/components/ProductMenu';
import DropzoneCardCsv from 'views/overviewProduct/components/DropzoneCardCsv';

function SearchTable2(props) {
  const {
    columnsData,
    tableData,
    onActionClicked,
    onItemClicked,
    type,
    searchPlaceholder,
    onSelectedItemsChange,
    onBulkEditMenuItemClicked,
    showActionMenu = true,
    isSelectable = true,
    title,
    loading,
    showMoreMenu = true,
    filterCount,
    canBulkEdit = true,
    setSelectedDateRange,
    selectedDateRange,
    dateRanges = [],
    onRowClick,
    selectedRow,
    customActionMenu,
  } = props;
  logData('columnsData:', columnsData);
  logData('tableData:', tableData);

  const [selectedItems, setSelectedItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() =>
    tableData.filter(item =>
      Object.values(item).some(val =>
        val.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    ), [tableData, searchValue, selectedRow]);

  const tableInstance = useTable(
    {
      columns,
      data,
      selectedRow,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const handleDateRangeChange = async (range) => {
    onMenuActionClicked('dateRange', range);
  };

  const handleBulkEditMenuClicked = (action) => {
    let data;
    switch (action) {
      case 'categories':
        const selectedItemsData = tableData.filter((item) => selectedItems.includes(item.id));
        const categories = selectedItemsData.flatMap((item) => item.tags);
        data = Array.from(new Set(categories));
        break;
      case 'price':
        break;
      case 'attributes':
        break;
      default:
        break;
    }
    onBulkEditMenuItemClicked(action, data);
  };

  const handleCheckboxChange = (event, itemId) => {
    logData('itemId:', itemId);
    const isSelected = event.target.checked;
    logData('Selected items:', selectedItems);
    if (isSelected) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    }
  };

  const onMenuActionClicked = (action, value) => {
    onActionClicked(action, value);
  };

  const onRowItemClicked = (type, item) => {
    logData('Row item clicked:', type);
    logData('Item:', item);
    onItemClicked(type, item);
  };

  useEffect(() => {
    setGlobalFilter(searchValue);
  }, [searchValue]);

  useEffect(() => {
    onSelectedItemsChange(selectedItems);
  }, [selectedItems, onSelectedItemsChange]);

  useEffect(() => {
    const filteredData = tableData.filter(item =>
      Object.values(item).some(val =>
        val.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    );
    setGlobalFilter(searchValue);
  }, [searchValue, tableData]);


  const { pageIndex, pageSize } = state;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('white', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  let bg = useColorModeValue('brand.500', 'brand.400');
  const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';



  return (
    <Card
      borderRadius={"25px"}
      border="1px solid"
      borderColor={borderColor}
      boxShadow='sm'
      px="0px"
      h="100%"
    >
      <Flex
        direction="column"
        w="100%"
        overflowX="hidden"
        h="100%"
      >
        {showActionMenu ? (
          <Flex
            align={{ sm: 'flex-start', lg: 'flex-start' }}
            justify='space-between'
            w="100%"
            px="22px"
            mb="36px"
          >
            {selectedItems.length > 0 ? (
              <Flex align="center">

                <Tag
                  fontSize="lg"
                  h="36px"
                  mb="6px"
                  me="6px"
                  borderRadius="18px"
                  variant="solid"
                  bg={bg}
                  p="10px"
                >
                  <TagLabel w="100%">{selectedItems.length} selected</TagLabel>
                  <TagCloseButton
                    justifySelf="flex-end"
                    color="white"
                    onClick={() => setSelectedItems([])}
                  />
                </Tag>

              </Flex>
            ) : (
              <SearchBar
                borderRadius="10px"
                me='15px'
                background='#ffffff'
                borderwidth='1px'
                borderColor='#E0E5F2'
                placeholder={searchPlaceholder}
                searchValue={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            )}
            {selectedItems.length > 0 ? (
              <Flex direction="row">
                {canBulkEdit && <BulkEditMenu onClick={handleBulkEditMenuClicked} />}
                <ButtonGroup>
                  <Button
                    colorScheme="brand"
                    variant="solid"
                    size="sm"
                    leftIcon={<FaFileExport />}
                    onClick={() => onMenuActionClicked('export')}
                  >
                    Export CSV
                  </Button>
                  <Button
                    colorScheme="gray"
                    variant="outline"
                    size="sm"
                    leftIcon={<IoTrash />}
                    onClick={() => onMenuActionClicked('archive')}
                    disabled
                  >
                    Archive
                  </Button>
                </ButtonGroup>
              </Flex>
            ) : (
              customActionMenu ? customActionMenu : <HStack>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<MdArrowDropDown />}
                    leftIcon={<Icon as={MdDateRange} />}
                    variant="outline"
                    size="sm"
                    ml={2}
                  >
                    Date Range{' '}
                    <Text as="span" fontSize="xs" fontWeight="normal" ml={1}>
                      ({dateRanges.find(range => range.value === selectedDateRange)?.label || ''})
                    </Text>
                  </MenuButton>
                  <MenuList>
                    {dateRanges.map((range) => (
                      <MenuItem
                        key={range.value}
                        onClick={() => handleDateRangeChange(range.value)}
                      >
                        <Flex justify="space-between" align="center" width="100%">
                          <Text>{range.label}</Text>
                          {selectedDateRange === range.value && <Icon as={MdCheck} color="green.500" />}
                        </Flex>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <ButtonGroup>

                  <Button
                    align='center'
                    justifyContent='center'
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    h='37px'
                    lineHeight='100%'
                    // onClick={onOpen1}
                    borderRadius='10px'
                    variant='transparent'
                    fontSize='sm'
                    fontWeight='500'
                    px='5px'
                    onClick={() => onMenuActionClicked('filter')}
                  >
                    <Icon as={IoFilter} color={textColor} w='16px' h='16px' me='5px' />
                    {
                      filterCount > 0 && (
                        <Badge colorScheme='brand' ml='2px'>{filterCount}</Badge>
                      )
                    }
                  </Button>

                </ButtonGroup>
              </HStack>
            )}

          </Flex>
        ) : null}
        {title ? (
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
            px="22px"
            mb="24px"
          >
            {title}
          </Text>
        ) : null}
      </Flex>
      <Flex
        direction="column"
        w="100%"
        overflowX="auto"
        h="100%"
      >

        {<Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" h="100%">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...(column.Header === 'id' ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps()))}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="flex-start"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.Header == 'name' && showActionMenu ? (
                        <Checkbox
                          colorScheme="brand"
                          size="md"
                          variant="no-effects"
                          borderColor={borderColor}
                          isChecked={selectedItems.length === tableData.length}
                          _checked={{
                            bg: 'brand.500',
                          }}
                          me="10px"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newItems = tableData.map((item) => item.id);
                              setSelectedItems(newItems);
                            } else {
                              setSelectedItems([]);
                            }
                          }}
                        />
                      ) : null}
                      {column.render('Header')}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  key={index}
                  // onClick={() => onRowClick(row)}
                  // bg={selectedRow && selectedRow.id === row.id ? "brand.100" : "white"}
                  // cursor="pointer"
                  // _hover={{ bg: "brand.100" }}
                >
                  {row.cells.map((cell, index) => {
                    let data = '';

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor={borderColor}

                      // bg={
                      //   selectedItems.includes(cell.row.original.id) ? 'brand.100' : 'transparent'
                      // }

                      >
                        {showActionMenu && isSelectable ? (
                          <RowAdapter
                            cell={cell}
                            column={cell.column}
                            onActionClicked={onMenuActionClicked}
                            onClick={onRowItemClicked}
                            onCheckboxChange={handleCheckboxChange}
                            isSelected={selectedItems.includes(cell.row.original.id)}
                          />
                        ) : (
                          <DisplayOnlyRowAdapter cell={cell} column={cell.column} />
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>}

        {loading && (
          <HStack alignSelf="center" w="100%" justifyContent="center" mt="20px">
            <CircularProgress size="24px" color="brand.500" isIndeterminate />
            <Text color="gray.400" fontSize="14px" fontWeight="500" lineHeight="100%" ml="10px">
              Loading Products...
            </Text>
          </HStack>
        )}


      </Flex>
      <Flex
        direction={{ sm: 'column', md: 'row' }}
        justify="space-between"
        align="center"
        w="100%"
        px={{ md: '22px' }}
        py="10px"
      >
        <Text
          fontSize="sm"
          color="gray.500"
          fontWeight="normal"
          mb={{ sm: '24px', md: '0px' }}
        >
          Showing {pageSize * pageIndex + 1} to{' '}
          {pageSize * (pageIndex + 1) <= tableData.length
            ? pageSize * (pageIndex + 1)
            : tableData.length}{' '}
          of {tableData.length} entries
        </Text>
        <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
          <Button
            variant="no-effects"
            onClick={() => previousPage()}
            transition="all .5s ease"
            w="40px"
            h="40px"
            borderRadius="50%"
            bg="transparent"
            border="1px solid"
            borderColor={useColorModeValue('gray.200', 'white')}
            display={
              pageSize === 5 ? 'none' : canPreviousPage ? 'flex' : 'none'
            }
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
          >
            <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
          </Button>
          {pageSize === 5 ? (
            <NumberInput
              max={pageCount - 1}
              min={1}
              w="75px"
              mx="6px"
              defaultValue="1"
              onChange={(e) => gotoPage(e)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper onClick={() => nextPage()} />
                <NumberDecrementStepper onClick={() => previousPage()} />
              </NumberInputStepper>
            </NumberInput>
          ) : (
            createPages(pageCount).map((pageNumber, index) => {
              return (
                <Button
                  variant="no-effects"
                  transition="all .5s ease"
                  onClick={() => gotoPage(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                  bg={
                    pageNumber === pageIndex + 1 ? brandColor : 'transparent'
                  }
                  border={
                    pageNumber === pageIndex + 1
                      ? 'none'
                      : '1px solid lightgray'
                  }
                  _hover={
                    pageNumber === pageIndex + 1
                      ? {
                        opacity: '0.7',
                      }
                      : {
                        bg: 'whiteAlpha.100',
                      }
                  }
                  key={index}
                >
                  <Text
                    fontSize="sm"
                    color={pageNumber === pageIndex + 1 ? '#fff' : textColor}
                  >
                    {pageNumber}
                  </Text>
                </Button>
              );
            })
          )}
          <Button
            variant="no-effects"
            onClick={() => nextPage()}
            transition="all .5s ease"
            w="40px"
            h="40px"
            borderRadius="50%"
            bg="transparent"
            border="1px solid"
            borderColor={useColorModeValue('gray.200', 'white')}
            display={pageSize === 5 ? 'none' : canNextPage ? 'flex' : 'none'}
            _hover={{
              bg: 'whiteAlpha.100',
              opacity: '0.7',
            }}
          >
            <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
          </Button>
        </Stack>
      </Flex>
    </Card>
  );
}

export default SearchTable2;
