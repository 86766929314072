import {
    Drawer, DrawerFooter, Button,
    DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Text, Checkbox, VStack, Divider
} from "@chakra-ui/react";
import { useState } from 'react';
import { logData } from "util/Logger";

export default function FilterDrawer(props) {
    const { isOpen, onClose, columns } = props;
    const [checkedItems, setCheckedItems] = useState([...columns]);
    logData('Checked Items:', checkedItems);
    const [loading, setLoading] = useState(false);

    const handleCheckboxChange = (column) => {
        setCheckedItems(prevState => {
            if (prevState.includes(column)) {
                // If the column is already in the array, remove it
                return prevState.filter(item => item !== column);
            } else {
                // If the column is not in the array, add it
                return [...prevState, column];
            }
        });
    };

    const handleFilter = async () => {
        console.log('Filtering...');
        setLoading(true);
        setLoading(false);
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="sm"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Filter</DrawerHeader>
                <DrawerBody>
                    <VStack spacing={2} align="start">
                        <Text fontSize="lg">Select Columns to show</Text>
                        <Divider />

                        {columns.map((column, index) => (
                            <Checkbox
                                key={index}
                                colorScheme="teal"
                                onChange={() => handleCheckboxChange(column)}
                                isChecked={checkedItems.includes(column)}
                            >
                                {column}
                            </Checkbox>
                        ))}
                    </VStack>
                </DrawerBody>
                <DrawerFooter mb='15px'>
                    <Button colorScheme="brand"
                        onClick={handleFilter}
                        isLoading={loading}
                    >Filter</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}