import { Box, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import DropzoneCsv from "./DropzoneCsv";
import { MdOutlineCloudUpload } from "react-icons/md";

export default function DropzoneCardCsv(props) {
    const { content, onResult, ...rest } = props;

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const brand = useColorModeValue('brand.500', 'brand.400');
    return <Card p="30px"
        // boxShadow="base"
        // borderRadius="10px"
        h="100%"
        minH="200px"
        {...rest}>
        <DropzoneCsv
            onResult={onResult}
            content={
                <Box maxW="100%">
                    <Icon
                        as={MdOutlineCloudUpload}
                        w="80px"
                        h="80px"
                        color={textColor}
                    />
                    <Text
                        mb="12px"
                        fontSize="lg"
                        w="100%"
                        maxW="100%"
                        fontWeight="700"
                        color={textColor}
                        whiteSpace="pre-wrap"
                    >
                        Drop your csv file here, or{' '}
                        <Text as="span" fontSize="lg" fontWeight="700" color={brand}>
                            click to browse
                        </Text>
                    </Text>
                </Box>
            }
        />
    </Card>
}