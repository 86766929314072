import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    Input,
    DrawerFooter,
    Button,
    Flex,
    Text,
} from "@chakra-ui/react"

function AiAssistantDrawer(props) {
    const { isOpen, onClose, btnRef } = props;

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            isCentered={true}
        // useInert={false}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Assistant</DrawerHeader>
                <Flex justify='center' align='center' h='100%' flexDirection='column' px='20px'>
                    <Text alignSelf='center' fontSize='xl' fontWeight='bold' mb='20px'>What do you need help with?</Text>
                    <Button variant='outline' fontWeight='400' alignSelf='center' mb='20px'>Import products from Shopify</Button>
                    <Button variant='outline' fontWeight='400' alignSelf='center' mb='20px'>Creating an online store</Button>
                    <Button variant='outline' fontWeight='400' alignSelf='center' mb='20px'>Talk to an agent</Button>
                </Flex>

                {/* <DrawerBody h='100%'>
                    <Text alignSelf='center' fontSize='xl' fontWeight='bold' mb='20px'>What do you need help with?</Text>
                </DrawerBody> */}

                <DrawerFooter mb='15px'>
                    <Input placeholder="Type here..." mr='5px' />
                    <Button colorScheme="brand">Send</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default AiAssistantDrawer;