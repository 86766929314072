/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import PropTypes from 'prop-types';
import Card from "components/card/Card";
import { ProductInEditContext } from "contexts/ProductInEditContext";
import { logData } from "util/Logger";
import { log } from "util/Logger";
import { Button, Center, Text, Tooltip, VStack } from "@chakra-ui/react";
import { FaMagic } from "react-icons/fa";

/* 
 * Simple editor component that takes placeholder text as a prop 
 */
const Editor = (props) => {
    const { setHasUnsavedChanges } = useContext(ProductInEditContext);

    const [generateProductDescriptionLoading, setGenerateProductDescriptionLoading] = useState(false);
    const [generatedProductDescription, setGeneratedProductDescription] = useState('');

    const {
        id,
        htmlBody,
        placeholder,

        name,
        metadata,

        handleChildValueChange,
    } = props;

    const isDraft = id === 'new_product_draft';

    const initialHtmlBody = isDraft ? productDraft?.htmlBody : htmlBody || '';

    const [editorHtml, setEditorHtml] = useState(initialHtmlBody);

    const handleChange = (html) => {
        logData('html:', html);
        if (isDraft) {
            setProductDraft((draft) => ({ ...draft, htmlBody: html }));
            logData('draft:', html);
            setEditorHtml(html);
            return;
        }
        if (initialHtmlBody != html) {
            logData('html:', html);
            logData('initialHtmlBody:', initialHtmlBody);
            handleChildValueChange({ description: html });
            setHasUnsavedChanges(true);
        }
    }

    const generateWithAI = async () => {
        log('Generate with AI');
        try {
            await generateProductDescription({
                productId: '123',
                name,
                categories: metadata.categories,
                tags: metadata.tags,

                setGenerateProductDescriptionLoading,
                setGeneratedProductDescription,
            });

        } catch (error) {
            console.error('Error generating product description: ', error);
        }
    }

    useEffect(() => {
        if (generatedProductDescription) {
            setEditorHtml(generatedProductDescription);
            setProductDraft((draft) => ({ ...draft, htmlBody: generatedProductDescription }));
            setHasUnsavedChanges(true);
        }
    }, [generatedProductDescription]);

    return (
        <Card
            boxShadow="none"
            bg="gray.100"
            borderRadius="10px"
            p="0px"
            mb="20px"
            minH="200px"
        // borderColor="gray.300"
        // borderWidth="1px"
        >
            <ReactQuill
                theme={'snow'}
                onChange={handleChange}
                defaultValue={editorHtml}
                modules={Editor.modules}
                formats={Editor.formats}
                bounds={'.app'}
                placeholder={placeholder}
            />
            {(name || (metadata && metadata.categories && metadata.categories.length > 0) || (metadata && metadata.tags && metadata.tags.length > 0))
                && (
                    <Center>
                        <VStack>
                            <Button colorScheme="brand" variant="outline" onClick={generateWithAI} width="auto"
                                isLoading={generateProductDescriptionLoading}
                                disabled={true}
                            >
                                <FaMagic style={{ marginRight: '5px' }} />
                                Generate with AI
                            </Button>
                            <Text color="gray.500" fontSize="12px" fontWeight="500" pb={4} >
                                Not available in Preview Mode
                            </Text>
                        </VStack>
                    </Center>
                )}
        </Card>
    )
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

/* 
 * PropType validation
 */
Editor.propTypes = {
    placeholder: PropTypes.string,
}

export default Editor