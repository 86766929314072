/*eslint-disable*/
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Alert, AlertDescription, AlertTitle, Box, Grid } from "@chakra-ui/react";

// Assets
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import config from '../../hgmconfig.json';
import FlowEditor from "./components/FlowEditor";
import { log } from "util/Logger";

export default function WorkflowsPage() {
  const [showTutorial, setShowTutorial] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const openEditorDrawerRef = React.useRef()

  const dismissTutorial = () => {
    setShowTutorial(false);
  }

  useEffect(() => {
    if (currentUser.token === null) return;
    const abortController = new AbortController();

    const fetchWorkflows = async () => {
      try {
        const response = await fetch(`${config.NEXT_PUBLIC_WORKFLOWS_API_URL}/${currentUser.uid}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${currentUser.token}`,
            'Content-Type': 'application/json'
          },
          signal: abortController.signal // Pass the abort signal
        });
        const { workflows } = await response.json();
        log(workflows);
        setWorkflows(workflows);

        // Rest of your code...
      } catch (error) {
        // Ignore fetch errors if the fetch was aborted
        if (error.name === 'AbortError') return;

        console.error('Error:', error);
      }
    }

    fetchWorkflows();

    // Cleanup function
    return () => {
      abortController.abort(); // Abort the fetch request
    };
  }, [currentUser.token, currentUser.uid]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        mb='20px'
        templateColumns="1fr"
        templateRows="auto"
        maxHeight="calc(100vh - 140px)"
        gap={{ base: "20px", xl: "20px" }}>
        {/* <Tutorial
          gridArea='1 / 1 / 2 / 2'
        /> */}
        {/* <Projects
          gridArea='2 / 1 / 3 / 2'
          banner={banner}
          avatar={avatar}
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
          workflows={workflows}
        /> */}
        {/* <General
          gridArea='3 / 1 / 4 / 2'
          pe='20px'
        /> */}
        <FlowEditor gridArea='1 / 1 / 1 / 1' onDismiss={dismissTutorial} showTutorial={false} />
      </Grid>
      {/* <FlowEditorDrawer openBtnRef={openEditorDrawerRef} /> */}
    </Box>
  );
}
