import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableCaption } from "@chakra-ui/react";

export const CohortAnalysisGraph = ({ data }) => {
  return (
    <Box overflowX="auto">
      <Table variant="simple">
        <TableCaption>Retention Cohort Analysis</TableCaption>
        <Thead>
          <Tr>
            <Th>Cohort</Th>
            <Th>Retention Rate</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, index) => (
            <Tr key={index}>
              <Td>{row.cohort}</Td>
              <Td>{row.retention}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
