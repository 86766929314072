import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import { logData } from "util/Logger";

export const joinWaitlist = async ({ email, source }) => {
    try {
        const joinWaitlistFunction = httpsCallable(functions, 'joinWaitlist');
        const result = await joinWaitlistFunction({ email, source });

        return result.data;
    } catch (error) {
        console.error('Error joining the Pro Waitlist:', error);
        throw error;
    }
};
