/**
 * This function takes a list of JSON objects and returns a list of column names
 */
export const mergeFields = (listOfJsonObjects) => {
    return Array.from(listOfJsonObjects.reduce((columns, object) => {
        Object.keys(object).forEach(field => {
            // if (field.toLowerCase() !== 'syncstatus' && field.toLowerCase() !== 'version' &&
            //     field.toLowerCase() !== 'externalids' && field.toLowerCase() !== 'createdat' &&
            //     field.toLowerCase() !== 'lastsync' && field.toLowerCase() !== 'id' &&
            //     field.toLocaleLowerCase() !== 'digital_assets' && field.toLowerCase() !== 'shopname' &&
            //     field.toLocaleLowerCase() !== 'currency' &&
            //     field.toLocaleLowerCase() !== 'sku' && field.toLocaleLowerCase() !== 'tags' &&
            //     field.toLocaleLowerCase() !== 'variants' && field.toLocaleLowerCase() !== 'attributes' &&
            //     field.toLocaleLowerCase() !== 'description') {
            //     columns.add(field);
            // }
            columns.add(field);
        });
        return columns;
    }, new Set()))
};

export const mergeFieldsOrders = (listOfJsonObjects) => {
    return Array.from(listOfJsonObjects.reduce((columns, object) => {
        Object.keys(object).forEach(field => {
            if (field.toLowerCase() !== 'syncstatus' && field.toLowerCase() !== 'version' &&
                field.toLowerCase() !== 'externalids' && field.toLowerCase() !== 'createdat' &&
                field.toLowerCase() !== 'lastsync' && field.toLocaleLowerCase() !== 'id' &&
                field.toLowerCase() !== 'billing_address' && field.toLowerCase() !== 'currency') {
                columns.add(field);
            }
        });
        return columns;
    }, new Set()))
};

export const mergeFieldsCustomers = (listOfJsonObjects) => {
    return Array.from(listOfJsonObjects.reduce((columns, object) => {
        Object.keys(object).forEach(field => {
            if (field.toLowerCase() !== 'syncstatus' && field.toLowerCase() !== 'version' &&
                field.toLowerCase() !== 'externalids' && field.toLowerCase() !== 'createdat' &&
                field.toLowerCase() !== 'lastsync' && field.toLowerCase() !== 'id' &&
                field.toLowerCase() !== 'address' && field.toLowerCase() !== 'city' &&
                field.toLowerCase() !== 'state' && field.toLowerCase() !== 'zip' &&
                field.toLowerCase() !== 'country') {
                columns.add(field);
            }
        });
        return columns;
    }, new Set()))
};

/**
 * Populates missing columns in a JSON objects with a placeholder value
 */
export const populateMissingColumns = (jsonObject, columns) => {
    columns.forEach(column => {
        if (!jsonObject[column]) {
            jsonObject[column] = '-';
        }
    });
    return jsonObject;
};