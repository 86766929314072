/*eslint-disable*/
import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import { useCallback, useContext, useState } from 'react';

export default function Settings(props) {
  const { setHasUnsavedChanges } = useContext(ProductInEditContext);
  const { id, price, currency, handleChildValueChange } = props;

  const isDraft = id === 'new_product_draft';

  const initialPrice = isDraft ? productDraft?.price : price;

  const [productDetails, setProductDetails] = useState({
    price: initialPrice,
  });

  const setPrice = useCallback((price) => {
    setProductDetails((details) => ({ ...details, price }));
    if (isDraft) {
      setProductDraft((draft) => ({ ...draft, price }));
      return;
    }
    handleChildValueChange({ base_price: parseFloat(price) });
    setHasUnsavedChanges(true);
  }, [isDraft]);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  return (
    <FormControl>
  
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
          mb="20px"
        >
          <InputField
            mb="0px"
            me="30px"
            id="price"
            label="Price"
            type="number"
            placeholder="eg. $99"
            value={productDetails.price}
            onChange={(e) => setPrice(e.target.value)}
          />
          {/* <InputField
            mb="0px"
            id="sku"
            label="SKU"
            placeholder="eg. 4030120241"
          /> */}
          <Flex direction="column">
            <FormLabel
              ms="10px"
              htmlFor="currency"
              fontSize="sm"
              color={textColorPrimary}
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              Currency
            </FormLabel>
            <Select
              fontSize="sm"
              id="currency"
              variant="main"
              h="44px"
              maxH="44px"
              me="20px"
              defaultValue={currency}
            >
              <option value={currency}>{currency}</option>
              {/* <option value="usd">USD</option>
              <option value="eur">EUR</option>
              <option value="gbp">GBP</option> */}
            </Select>
          </Flex>

        </SimpleGrid>
    </FormControl>
  );
}
