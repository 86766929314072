import { createContext } from "react";

import TableWidget from "components/widgets/table/TableWidget";
import QuickActionsWidget from "components/widgets/quickActions/QuickActionsWidget";
import { SpreadsheetWidget } from "components/widgets/spreadsheet/SpreadsheetWidget";
import SummaryWidget from "components/widgets/summary/SummaryWidget";
import OrderDetails from "components/widgets/orderDetails/OrderDetails";
import ListWidget from "components/widgets/list/ListWidget";
import BarChartWidget from "components/widgets/chart/BarChartWidget";
import BarChartWidgetModal from "views/dashboard/components/editModals/BarChartWidgetModal";
import LineChartWidgetModal from "views/dashboard/components/editModals/LineChartWidgetModal";
import PieChartWidgetModal from "views/dashboard/components/editModals/PieChartWidgetModal";
import HeatMapWidgetModal from "views/dashboard/components/editModals/HeatMapWidgetModal";
import BubbleChartWidgetModal from "views/dashboard/components/editModals/BubbleChartWidgetModal";
import FunnelChartWidgetModal from "views/dashboard/components/editModals/FunnelChartWidgetModal";
import WaterfallChartWidgetModal from "views/dashboard/components/editModals/WaterfallChartWidgetModal";
import AreaChartWidgetModal from "views/dashboard/components/editModals/AreaChartWidgetModal";
import HistogramWidgetModal from "views/dashboard/components/editModals/HistogramWidgetModal";
import ScatterPlotWidgetModal from "views/dashboard/components/editModals/ScatterPlotWidgetModal";

const WidgetType = {
    Table: Symbol("Table"),
    Spreadsheet: Symbol("Spreadsheet"),
    QuickActions: Symbol("QuickActions"),
    Insight: Symbol("Insight"),
    Summary: Symbol("Summary"),
    Text: Symbol("Text"),
    List: Symbol("List"),
    Calendar: Symbol("Calendar"),
    Todo: Symbol("Todo"),
    OrderDetails: Symbol("OrderDetails"),
    BarChart: Symbol("BarChart"),
}

// WidgetFactoryContext.js
class WidgetFactory {
    createWidget(type, props) {
        switch (type) {
            case 'bar_chart':
                return this.createBarChartWidget(props);
            case 'line_chart':
                return this.createLineChartWidget(props);
            case 'pie_chart':
                return this.createPieChartWidget(props);
            case 'scatter_plot':
                return this.createScatterPlotWidget(props);
            case 'heat_map':
                return this.createHeatMapWidget(props);
            case 'histogram':
                return this.createHistogramWidget(props);
            case 'bubble_chart':
                return this.createBubbleChartWidget(props);
            case 'funnel_chart':
                return this.createFunnelChartWidget(props);
            case 'waterfall_chart':
                return this.createWaterfallChartWidget(props);
            case 'area_chart':
                return this.createAreaChartWidget(props);
            default:
                throw new Error(`Unsupported widget type: ${type}`);
        }
    }

    createBarChartWidget(props) {
        return <BarChartWidgetModal {...props} />;
    }

    createLineChartWidget(props) {
        return <LineChartWidgetModal {...props} />;
    }

    createPieChartWidget(props) {
        return <PieChartWidgetModal {...props} />;
    }

    createScatterPlotWidget(props) {
        return <ScatterPlotWidgetModal {...props} />;
    }

    createHeatMapWidget(props) {
        return <HeatMapWidgetModal {...props} />;
    }

    createHistogramWidget(props) {
        return <HistogramWidgetModal {...props} />;
    }

    createBubbleChartWidget(props) {
        return <BubbleChartWidgetModal {...props} />;
    }

    createFunnelChartWidget(props) {
        return <FunnelChartWidgetModal {...props} />;
    }

    createWaterfallChartWidget(props) {
        return <WaterfallChartWidgetModal {...props} />;
    }

    createAreaChartWidget(props) {
        return <AreaChartWidgetModal {...props} />;
    }
}



export const WidgetFactoryContext = createContext();

export const WidgetFactoryProvider = ({ children }) => {
    const widgetFactory = new WidgetFactory();
    return (
        <WidgetFactoryContext.Provider value={{ widgetFactory }}>
            {children}
        </WidgetFactoryContext.Provider>
    );
};