/* eslint-disable */
import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { log } from "util/Logger";

export function SidebarLinks(props) {
  console.log('props', props);
  const [activeCategory, setActiveCategory] = React.useState("");
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("white", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("white", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("white", "brand.400");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    const fullPath = location.pathname + location.hash;
    return fullPath.includes(routeName);
  };

  const isActiveCategory = (category) => {
    log('currentCategory', category);
    return activeCategory === category;
  };

  useEffect(() => {
    const fullPath = location.pathname + location.hash;
    log('fullPath', fullPath);
    const current = routes.find(route => fullPath.includes(route.path));
    log('activeRoute', current);
    const activeCategory = location.hash ? current?.name?.toLowerCase() : null;
    setActiveCategory(activeCategory);
    log('activeCategory', activeCategory);
  }, [location.pathname, location.hash]);

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.isCategory) {
        return (
          <>
            <Accordion defaultIndex={[0]} allowMultiple className="myAccordion">
              <AccordionItem>
                <h2>
                  <AccordionButton px='10px' pb='0px'>
                    <Box as="span" flex='1' textAlign='left'>
                      <HStack
                        spacing={
                          isActiveCategory(route.name.toLowerCase()) ? "22px" : "26px"
                        }
                        py='5px'
                        ps='0px'>
                        <Flex w='100%' alignItems='center' justifyContent='center'>
                          <Box
                            color={
                              isActiveCategory(route.name.toLowerCase())
                                ? activeIcon
                                : textColor
                            }
                            me='18px'>
                            {route.icon}
                          </Box>
                          <Text
                            me='auto'
                            color={
                              isActiveCategory(route.name.toLowerCase())
                                ? activeColor
                                : textColor
                            }
                            fontWeight={
                              isActiveCategory(route.name.toLowerCase())
                                ? "bold"
                                : "normal"
                            }>
                            {route.name}
                          </Text>
                        </Flex>
                      </HStack>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  pb={4}
                  ms='20px'
                //  borderLeftWidth={3} borderColor='brand.200'  my='10px'
                >
                  {createLinks(route.items)}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {route.hideFromSidebar ? null : (
              route.icon ? (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py='5px'
                    ps={route.category ? '4px' : '10px'}>
                    <Flex w='100%' alignItems='center' justifyContent='center'>
                      <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me={route.category ? '5px' : '18px'}>
                        {route.icon}
                      </Box>
                      <Text
                        me='auto'
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }>
                        {route.name}
                      </Text>
                      {route.comingSoon && (
                        <Badge colorScheme='brand' borderRadius='md' fontSize='12px'>Coming Soon </Badge>
                      )}
                    </Flex>
                    <Box
                      h='36px'
                      w='4px'
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius='5px'
                    />
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py='5px'
                    ps='10px'>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                      }>
                      {route.name}
                    </Text>
                    <Box h='36px' w='4px' bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    } borderRadius='5px' />
                  </HStack>
                </Box>
              )
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  log('routasdasdasdasdes', routes);
  return createLinks(routes);
}

export default SidebarLinks;
