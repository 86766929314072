const { httpsCallable } = require("firebase/functions");
const { functions } = require("firebaseApp");

async function getUnreadNotificationsCount() {
    try {
        const getUnreadNotificationsCountFunction = httpsCallable(functions, 'getUnreadNotificationsCount');
        const result = await getUnreadNotificationsCountFunction();
        console.log('Unread notifications count:', result.data);    
        return result.data.unreadNotificationsCount;
    } catch (error) {
        console.error('Error fetching unread notifications count:', error);
        throw error;
    }
}

module.exports = {
    getUnreadNotificationsCount,
};