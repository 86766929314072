/*eslint-disable*/
import { Badge, Flex, FormControl, HStack, Icon, Image, SimpleGrid, Text, UnorderedList, useColorModeValue } from "@chakra-ui/react";
import { ReactComponent as SyncIcon } from 'assets/img/icons/sync.svg';

import Card from "components/card/Card.js";

import shopify from "assets/img/icons/shopify.webp";
import amazon from "assets/img/icons/amazon.webp";
import google from "assets/img/icons/google.svg";
import UpgradeToPro from "components/modals/UpgradeToPro";
import ListItem from "../list/ListItem";
import { FaCheckCircle, FaEllipsisH } from "react-icons/fa";

export default function Toggles(props) {
  const { hideMenu, channels } = props;
  console.log('channels', channels);
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const getImage = (name) => {
    switch (name) {
      case 'shopify':
        return shopify;
      case 'amazon':
        return amazon;
      case 'google':
        return google;
      default:
        return ''
    }
  }

  return (
    <>
      {Object.keys(channels).map((channel) => (
        <Card
          borderRadius="10px"
          boxShadow="none"
          p="20px"
          mb="20px">
          <Flex
            justifyContent="space-between"
            flexDirection='row'
            alignItems="center"
            w="100%"
            px="10px"
          >
            <HStack>
              <Image
                src={getImage(channel.toLocaleLowerCase())}
                w="34px"
                h="34px"
              />
              <Text
                color={textColor}
                fontSize="md"
                me="6px"
                fontWeight="700"
                mb={{ base: '10px', md: '0px' }}
              >
                {channel.charAt(0).toUpperCase() + channel.slice(1)}
              </Text>
            </HStack>
            <Flex
              align="center"
              flexDirection={{ base: 'column', md: 'row' }}
              verticalAlign="center"
            >
              <Badge colorScheme="green">Connected</Badge>
            </Flex>
          </Flex>
        </Card>
      ))}

      <Card
        borderRadius="10px"
        boxShadow="base"
        p="20px"
        mb="20px"

      >
        <FormControl>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="40px" py="20px">
            <Flex direction="column" ms="10px" align="center" h="100%">
              <Icon as={SyncIcon} boxSize="50px" mb="20px" />
              <Text fontSize="xl" fontWeight="bold" mb="20px">
                Manage Catalog in One Place
              </Text>
              <UpgradeToPro source="Manage Catalog In One Place" />
            </Flex>
            <UnorderedList spacing={4} px="10px">
              <ListItem
                icon={FaCheckCircle}
                title="Connect up to 5 Additional Channels"
                showBadge={false}
              />

              <ListItem
                icon={FaCheckCircle}
                title="Ensure Up-to-date Product Information"
                showBadge={false}
              />
              <ListItem
                icon={FaEllipsisH}
                title="+ more"
                showBadge={false}
              />
            </UnorderedList>
          </SimpleGrid>
        </FormControl>
      </Card>


    </>
  );
}
