
import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    SimpleGrid,
    VStack,
    HStack,
    Icon,
    Text,
    Box,
} from "@chakra-ui/react";
import BarChartWidgetModal from './BarChartWidgetModal';
import Card from 'components/card/Card';
import TableWidgetModal from './TableWidgetModal';
import { MdArrowBack, MdArrowBackIos } from 'react-icons/md';
import LineChartWidgetModal from './LineChartWidgetModal';
import PieChartWidgetModal from './PieChartWidgetModal';
import ScatterPlotWidgetModal from './ScatterPlotWidgetModal';
import HeatMapWidgetModal from './HeatMapWidgetModal';
import HistogramWidgetModal from './HistogramWidgetModal';
import BubbleChartWidgetModal from './BubbleChartWidgetModal';
import FunnelChartWidgetModal from './FunnelChartWidgetModal';
import WaterfallChartWidgetModal from './WaterfallChartWidgetModal';
import AreaChartWidgetModal from './AreaChartWidgetModal';
// Import other widget modals as needed

const AddWidgetModal = ({ isOpen, onClose, widgetTypes, onSelectWidgetType }) => {
    const [selectedType, setSelectedType] = useState(null);
    const [showConfig, setShowConfig] = useState(false);

    const handleCardClick = (type) => {
        setSelectedType(type);
    };

    const handleNext = () => {
        if (selectedType) {
            setShowConfig(true);
        }
    };

    const handleSave = (config) => {
        onSelectWidgetType(selectedType.toLowerCase().replace(' ', '_'), config);
        onClose();
    };

    const handleDiscard = () => {
        setSelectedType(null);
        onClose();
        setShowConfig(false);
    };

    const renderConfigView = () => {
        switch (selectedType) {
            case 'Bar Chart':
                return <BarChartWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Line Chart':
                return <LineChartWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Pie Chart':
                return <PieChartWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Scatter Plot':
                return <ScatterPlotWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Heat Map':
                return <HeatMapWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Histogram':
                return <HistogramWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Bubble Chart':
                return <BubbleChartWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Funnel Chart':
                return <FunnelChartWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Waterfall Chart':
                return <WaterfallChartWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Area Chart':
                return <AreaChartWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            case 'Table':
                return <TableWidgetModal widget={{}} onSave={handleSave} showPreview={true} />
            default:
                return null;
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={showConfig ? 'full' : 'xl'} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {showConfig && (
                        <Button
                            onClick={() => setShowConfig(false)}>

                            <Icon as={MdArrowBack} w={6} h={6} />
                        </Button>
                    )}
                    {showConfig ? `Configure ${selectedType}` : 'Select Widget Type'}

                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!showConfig ? (
                        <SimpleGrid columns={{ base: 2, md: 3 }} spacing={6}>
                            {widgetTypes.map((type) => (
                                <Card
                                    key={type.name}
                                    onClick={() => handleCardClick(type.name)}
                                    cursor="pointer"
                                    _hover={{ boxShadow: 'lg' }}
                                    bg={selectedType === type.name ? 'brand.100' : 'white'}
                                    border={'1px solid'}
                                    borderColor="gray.300"
                                >
                                    <VStack spacing={4} align="center" p={4}>
                                        <Icon as={type.icon} w={10} h={10} color="brand.500" />
                                        <Text fontWeight="bold" textAlign="center">{type.name}</Text>
                                    </VStack>
                                </Card>
                            ))}
                        </SimpleGrid>
                    ) : (
                        <HStack spacing={4} align="start">
                            {renderConfigView()}
                        </HStack>
                    )}
                </ModalBody>
                <ModalFooter>
                    {!showConfig ? (
                        <>
                            <Button variant="ghost" onClick={onClose} mr={3}>Cancel</Button>
                            {selectedType && (
                                <Button colorScheme="brand" borderRadius={"md"} onClick={handleNext}>
                                    Next
                                </Button>
                            )}
                        </>
                    ) : (
                        <HStack>
                            <Button variant="transparent"
                                color="red"
                                borderRadius={"md"}
                                onClick={handleDiscard}>
                                Discard
                            </Button>
                            <Button variant="brand"
                                borderRadius={"md"}
                                disabled={true}
                                onClick={handleSave}>
                                Save Changes
                            </Button>
                        </HStack>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddWidgetModal;
