/*eslint-disable*/
import { Flex, Spinner } from '@chakra-ui/react';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import { useCallback, useContext, useState, useEffect } from 'react';
import { FaImage } from 'react-icons/fa';
import { log } from 'util/Logger';
import { logData } from 'util/Logger';
import { AuthContext } from 'contexts/AuthContext';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from 'firebaseApp';
import OptionsCard from './OptionsCard';
import VariantListItem from './VariantListItem';

export default function ProductVariants(props) {
    const {
        id,
        currency,

        variants,
        setVariants,

        deletedVariants,
        setDeletedVariants,

        attributes,
        setAttributes,
    } = props;

    const { setHasUnsavedChanges } = useContext(ProductInEditContext);
    const { currentUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    const colors = [
        "#6EA6FF",
        "#ad99ff",
        "#ad99ff",
        "#dd8df2",
    ]

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(firestore, `Users/${currentUser.uid}/Products/${id}/Variants`),
            (querySnapshot) => {
                console.log('querySnapshot:', querySnapshot);
                const fetchedVariants = querySnapshot.docs.map((doc) => doc.data());
                setVariants(fetchedVariants);
                setIsLoading(false);
            },
            (error) => {
                console.error('Error fetching variants:', error);
                setIsLoading(false);
            }
        );

        return () => unsubscribe();
    }, [currentUser.uid, id]);

    const updateVariant = (index, field, value) => {
        const newVariants = [...variants];
        newVariants[index][field] = value;
        setVariants(newVariants);
        setHasUnsavedChanges(true);
    };

    return (
        <Flex direction="column" mb="15px" ms="10px">
            {isLoading ? (
                <Flex justify="center" align="center" h="200px">
                    <Spinner size="xl" color="brand.500" />
                </Flex>
            ) : (
                variants.map((variant, index) => (
                    <VariantListItem
                        key={index}
                        variant={variant}
                        index={index}
                        updateVariant={updateVariant}
                        currency={currency}
                    />
                ))
            )}
            {!isLoading && (
                <OptionsCard
                    colors={colors}

                    variants={variants}
                    setVariants={setVariants}

                    deletedVariants={deletedVariants}
                    setDeletedVariants={setDeletedVariants}

                    attributes={attributes}
                    setAttributes={setAttributes}
                />
            )}
        </Flex>
    );
}
