import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import { logData, log, errorData } from "util/Logger";

export const getProductsAnalytics = async ({
    token,
    uid,
    abortController = null,
}) => {
    try {
        log('Fetching getProductsAnalytics from server...');

        const getInsightsCallable = httpsCallable(functions, 'getProductsAnalytics');
        const result = await getInsightsCallable({
            token,
            uid,
        }, { signal: abortController?.signal });
        logData('getProductsAnalytics result:', result);
        return result.data;
    } catch (error) {
        errorData('Error:', error);
        throw error;
    }
};
