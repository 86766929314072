import { Flex, Image, Tag, TagLabel, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";

export default function CategotiesRowAdapter(props) {
    const { item, index, selectedItemIdsToObject } = props;

	const bg = useColorModeValue('brand.500', 'brand.400');
    const textColor = useColorModeValue("secondaryGray.900", "white");
    return <Tr key={index}>
        <Td>
            <Flex align="center">
                <Image
                    src={item.name[2]}
                    w="36px"
                    h="36px"
                    me="8px"
                    borderRadius="10px"
                />
                <Flex direction="column">
                    <Text
                        color={textColor}
                        fontSize="sm"
                        fontWeight="700"
                    >
                        {item.name[0]}
                    </Text>
                    <Text
                        color="secondaryGray.500"
                        fontSize="sm"
                        fontWeight="600"
                    >
                        {item.name[1]}
                    </Text>
                </Flex>
            </Flex>
        </Td>
        <Td>{selectedItemIdsToObject[index]?.tags?.map((category) =>
        (<Tag
            fontSize="xs"
            h="25px"
            mb="6px"
            me="6px"
            borderRadius="10px"
            variant="solid"
            bg="gray.500"
            key={index}
        >
            <TagLabel w="100%">{category}</TagLabel>

        </Tag>)
        )}</Td>
        <Td>{item.tags.map((category) =>
        (<Tag
            fontSize="xs"
            h="25px"
            mb="6px"
            me="6px"
            borderRadius="10px"
            variant="solid"
            bg={bg}
            key={index}
        >
            <TagLabel w="100%">{category}</TagLabel>

        </Tag>)
        )}</Td>
    </Tr>
}