/*eslint-disable*/
import {
  Button,
  Divider,
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import ListItemButton from './ListItemButton';

import shopify from "assets/img/icons/shopify.webp";
import google from "assets/img/icons/google.svg";
import amazon from "assets/img/icons/amazon.webp";
import ConnectDataSource from 'components/modals/ConnectDataSource';
import { logData } from 'util/Logger';
import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'firebaseApp';
import { formatDistanceToNow, isValid } from 'date-fns';

export default function Connected(props) {
  const { connectedApps, ...rest } = props;

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  const {
    isOpen: isImportOpen,
    onClose: onImportClose,
    onOpen: onImportOpen,
  } = useDisclosure();

  const [isSyncingShopify, setIsSyncingShopify] = useState(false);
  const [isSyncingGoogle, setIsSyncingGoogle] = useState(false);

  const getImage = (name) => {
    switch (name) {
      case 'shopify':
        return shopify;
      case 'google':
        return google;
      case 'amazon':
        return amazon;
      default:
        return '';
    }
  };

  /**
   * Triggers a product sync with an external sales channel.
   *
   * @param {string} externalChannel - The external sales channel to sync with.
   * @returns {Promise<{ success: boolean, jobId?: string, error?: string }>} A Promise that resolves with the result of the sync operation.
   */
  const triggerChannelsSync = async (externalChannel) => {
    try {
      const triggerChannelsSyncFunction = httpsCallable(functions, 'triggerChannelsSync');

      const result = await triggerChannelsSyncFunction({ externalChannel });

      if (result.data.success) {
        return { success: true, jobId: result.data.jobId };
      } else {
        return { success: false, error: 'Failed to trigger product sync' };
      }
    } catch (error) {
      console.error('Error triggering product sync:', error);
      return { success: false, error: error.message };
    }
  };


  const onSyncShopifyClicked = async () => {
    setIsSyncingShopify(true);

    try {
      // Trigger a product sync with Shopify
      const syncResult = await triggerChannelsSync('shopify');

      if (syncResult.success) {
        console.log('Product sync with Shopify triggered successfully. Job ID:', syncResult.jobId);
      } else {
        console.error('Failed to trigger product sync with Shopify:', syncResult.error);
      }
    } catch (error) {
      console.error('Error triggering product sync with Shopify:', error);
    } finally {
      setIsSyncingShopify(false);
    }
  }

  const onSyncGoogleClicked = async () => {
    setIsSyncingGoogle(true);

    try {
      // Trigger a product sync with Google
      const syncResult = await triggerChannelsSync('google');

      if (syncResult.success) {
        console.log('Product sync with Google triggered successfully. Job ID:', syncResult.jobId);
      } else {
        console.error('Failed to trigger product sync with Google:', syncResult.error);
      }
    } catch (error) {
      console.error('Error triggering product sync with Google:', error);
    } finally {
      setIsSyncingGoogle(false);
    }
  };

  const onSyncAppClicked = (app) => {
    logData('Sync app clicked:', app);
    switch (app) {
      case 'shopify':
        onSyncShopifyClicked();
        break;
      case 'google':
        onSyncGoogleClicked();
        break;
      default:
        break;
    }
  }

  // Helper function to get the badge text and color based on the app status
  const getBadgeProps = (appStatus) => {
    try {
      switch (appStatus.toLowerCase()) {
        case 'enabled':
          return { text: 'Active', color: 'green' };
        case 'syncing':
          return { text: 'Syncing...', color: 'blue' };
        case 'error':
          return { text: 'Error', color: 'red' };
        case 'disabled':
          return { text: 'Disabled', color: 'gray' };
        default:
          return { text: '', color: 'gray' };
      }
    } catch (error) {
      console.error('Error getting badge props:', error);
      return { text: 'Unknown', color: 'gray' };
    }
  };

  const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';


  // Chakra Color Mode
  return (
    <FormControl>
      <Card p="30px"
        borderRadius={"10px"}
        border="1px solid"
        borderColor={borderColor}
        boxShadow='sm'
        {...rest}>
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Connected Apps
          </Text>
          <Button
            variant='brand'
            borderRadius='10px'
            fontSize='sm'
            onClick={onImportOpen}
          >Connect app</Button>
        </Flex>
        <Divider mt="20px" />

        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: '20px', xl: '0px' }}
        >
          {connectedApps.map((app, index) => {
            const { text: badgeText, color: badgeColor } = getBadgeProps(app.data.status);

            return (
              <ListItemButton
                key={index}
                py="20px"
                borderBottom={index === connectedApps.length - 1 ? 'none' : '1px solid'}
                borderColor={borderColor}
                name={app.name.charAt(0).toUpperCase() + app.name.slice(1)}
                value={""}
                secondaryText={`Last synced: ${app.data.last_synced && isValid(new Date(app.data.last_synced))
                  ? formatDistanceToNow(new Date(app.data.last_synced), { addSuffix: true })
                  : 'Never'
                  }`}
                actionName="Sync Now"
                image={getImage(app.name)}
                action={onSyncAppClicked.bind(this, app.name)}
                loading={isSyncingShopify}
                badge={badgeText}
                badgeColorScheme={badgeColor}
              />
            )
          })}
        </SimpleGrid>

      </Card>

      <ConnectDataSource
        // onImportCsvResult={onImportCsvResult}
        onCloseConnectDataSourceModal={onImportClose}
        onImportClose={onImportClose}
        isImportOpen={isImportOpen}
        showCsvImport={false}
      />
    </FormControl>
  );
}
