// Chakra imports
import { Button, Flex, HStack, Icon, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "./Information";
import { MdClose, MdEmail, MdInfoOutline, MdLoyalty, MdNotificationImportant, MdOutlineArrowRightAlt } from "react-icons/md";

// Assets
export default function Tutorial(props) {
    const { onDismiss, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "gray.400";
    const cardShadow = useColorModeValue(
        "0px 18px 40px rgba(112, 144, 176, 0.12)",
        "unset"
    );

    return (
        <Card
            boxShadow={cardShadow}
            mb={{ base: "0px", "2xl": "20px" }}
            {...rest}>
            <Flex align='center' justify='space-between'>
                <HStack>
                    <Icon as={MdInfoOutline} color={textColorSecondary} fontSize='24px' />
                    <Text
                        color={textColorPrimary}
                        fontWeight='bold'
                        fontSize='2xl'
                        mt='10px'
                        mb='4px'
                        pl='10px'>
                        How it Works
                    </Text>
                </HStack>
                <Button
                    variant='transparent'
                    fontSize='sm'
                    fontWeight='bold'
                    px='24px'
                    py='5px'
                    onClick={onDismiss}
                    alignSelf='flex-end'>
                    <MdClose w='50px' h='50px' />
                </Button>
            </Flex>
            <Text color={textColorSecondary} fontSize='md' me='26px' mb='20px' mt='20px' >
                Workflows are a series of steps that automate a process. They can be simple or complex, and can be used to automate a wide range of business processes.
            </Text>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='10px'>
                <Information
                    boxShadow={cardShadow}
                    title='When a customer is added'
                    value='Send a personalized email'
                    icon={MdEmail}
                />
                <Information
                    boxShadow={cardShadow}
                    title='When product stock is low'
                    value='Notify inventory manager'
                    icon={MdNotificationImportant}
                />
                <Information
                    boxShadow={cardShadow}
                    title='When an order is delivered'
                    value='Request feedback and offer reward'
                    icon={MdLoyalty}
                />
                {/* <Information
                    boxShadow={cardShadow}
                    title='Step 1'
                    value='Choose a Trigger'
                />
                <Information
                    boxShadow={cardShadow}
                    title='Step 2'
                    value='Define an Action'
                />
                <Information
                    boxShadow={cardShadow}
                    title='Step 3'
                    value='Deploy'
                /> */}
            </SimpleGrid>
            <Button
                variant='transparent'
                fontSize='sm'
                fontWeight='bold'
                px='24px'
                py='5px'
                mt='10px'
                alignSelf='flex-end'>
                See more examples
                <MdOutlineArrowRightAlt w='50px' h='50px' />
            </Button>
        </Card>
    );
}
