/*eslint-disable*/
import {
  Box,
  Flex,
  FormControl,
  SimpleGrid,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TagsField from 'components/fields/TagsField';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import { useCallback, useContext, useState } from 'react';
import { logData } from 'util/Logger';

export default function ProductInfo(props) {
  const { setHasUnsavedChanges } = useContext(ProductInEditContext);

  logData('useContext:', productDraft);

  const {
    id,
    name: propsName,
    description: propsDescription,
    categories: propsCategories,
    vendor: propsVendor,
    tags: propsTags,
    handleChildValueChange,
  } = props;

  const isDraft = id === 'new_product_draft';

  const initialName = isDraft ? productDraft?.name : propsName;
  const initialDescription = isDraft ? productDraft?.description : propsDescription;
  const initialCategories = isDraft ? productDraft?.categories : propsCategories;
  const initialVendor = isDraft ? productDraft?.vendor : propsVendor;
  const initialTags = isDraft ? productDraft?.tags : propsTags;

  logData('initialCategories:', initialCategories);

  const [productDetails, setProductDetails] = useState({
    name: initialName,
    description: initialDescription,
    categories: initialCategories,
    vendor: initialVendor,
    tags: initialTags,
  });

  const setProductName = useCallback((name) => {
    logData('setProductName:', name);
    setProductDetails((details) => ({ ...details, name }));
    if (isDraft) {
      setProductDraft((draft) => ({ ...draft, name }));
      return;
    }

    handleChildValueChange({ name });
    setHasUnsavedChanges(true);
    logData('setting has unsaved changes:', true);
  }, [isDraft]);

  // const setProductDescription = useCallback((description) => {
  //   logData('setProductDescription:', description);
  //   setProductDetails((details) => ({ ...details, description }));
  //   if (isDraft) {
  //     setProductDraft((draft) => ({ ...draft, description }));
  //     return;
  //   }

  //   handleChildValueChange({ description });
  //   setHasUnsavedChanges(true);
  //   logData('setting has unsaved changes:', true);
  // }, [isDraft]);

  const setProductVendor = useCallback((vendor) => {
    logData('setProductVendor:', vendor);
    setProductDetails((details) => ({ ...details, vendor }));
    if (isDraft) {
      setProductDraft((draft) => ({ ...draft, vendor }));
      return;
    }
    handleChildValueChange({ vendor });
    setHasUnsavedChanges(true);
    logData('setting has unsaved changes:', true);
  }, [isDraft]);

  const setProductTags = useCallback((tags) => {
    logData('setProductTags:', tags);
    const tagsString = tags.join(',');
    setProductDetails((details) => ({ ...details, tags: tagsString }));
    if (isDraft) {
      setProductDraft((draft) => ({ ...draft, tags: tagsString }));
      return;
    }
    handleChildValueChange({ tags: tagsString });
    setHasUnsavedChanges(true);
  }, [isDraft]);

  // const setProductCategories = useCallback((categories) => {
  //   logData('setProductCategories:', categories);
  //   setProductDetails((details) => ({ ...details, categories }));
  //   if (isDraft) {
  //     setProductDraft((draft) => ({ ...draft, categories }));
  //     return;
  //   }

  //   handleChildValueChange({ categories });
  //   setHasUnsavedChanges(true);
  //   logData('setting has unsaved changes:', true);
  // }, [isDraft]);

  return (
    <FormControl>

      {/* <Card mb={{ base: '0px', xl: '20px' }}
        boxShadow="base"
        borderRadius="10px"
        > */}

        <SimpleGrid
          columns={{ base: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Flex
            direction="column"
            mb="20px"
            ms="10px"
          >
            <InputField
              mb="0px"
              me="30px"
              id="productName"
              label="Product Name"
              placeholder="eg. Elegant Chair"
              value={productDetails.name}
              onChange={(e) => setProductName(e.target.value)}
            />
            <Box h="20px" />
            {/* <InputField
              id="description"
              label="Short Description"
              mb="0px"
              style={{ height: '100%' }}
              placeholder="Short description about the product"
              value={productDetails.description}
              onChange={(e) => setProductDescription(e.target.value)}
            />
            <Box h="20px" /> */}
            <InputField
              id="vendor"
              label="Vendor"
              mb="0px"
              placeholder="Vendor name"
              value={productDetails.vendor}
              onChange={(e) => setProductVendor(e.target.value)}
            />
          </Flex>


          <Flex
            direction="column"
            mb="20px"
            p="0px"
            ms="10px">
            {/* <TagsField
              id="categories"
              label="Categories"
              m="0px"
              onChange={(tags) => setProductCategories(tags)}
              placeholderTags={productDetails.categories}
            />
            <Box h="20px" /> */}
            <TagsField
              id="tags"
              label="Tags"
              m="0px"
              onChange={(tags) => setProductTags(tags)}
              placeholderTags={productDetails.tags}
              info="Use comma to separate tags" // todo this uses custom labels in Content API
            />
          </Flex>

        </SimpleGrid>
      {/* </Card> */}
    </FormControl>
  );
}
