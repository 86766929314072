import React from "react";
import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import DonutChart from "components/charts/DonutChart";
import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import AIInsight from "components/AIInsight";

// Mock data
const loyaltyParticipationData = [70, 30];

const engagementByTierData = [
    { tier: "Bronze", purchaseFrequency: 2, pointsRedemption: 500 },
    { tier: "Silver", purchaseFrequency: 4, pointsRedemption: 1000 },
    { tier: "Gold", purchaseFrequency: 6, pointsRedemption: 2000 },
    { tier: "Platinum", purchaseFrequency: 8, pointsRedemption: 3000 },
];

const retentionComparisonData = [
    { month: "Jan", members: 90, nonMembers: 70 },
    { month: "Feb", members: 92, nonMembers: 72 },
    { month: "Mar", members: 91, nonMembers: 71 },
    { month: "Apr", members: 93, nonMembers: 73 },
    { month: "May", members: 94, nonMembers: 72 },
    { month: "Jun", members: 95, nonMembers: 74 },
];

const LoyaltyProgramAnalytics = () => {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <AIInsight message="Loyalty program participation has reached 65% of total customers. The new tiered rewards system is driving higher engagement." />


            <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
                <Card>
                    <Heading size="md" mb="4">
                        Loyalty Program Participation
                    </Heading>
                    <DonutChart
                        chartData={loyaltyParticipationData}
                        chartOptions={{
                            labels: ["Members", "Non-members"],
                            colors: ["#001842", "#FF5A5F"],
                            legend: {
                                position: "bottom",
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        labels: {
                                            show: true,
                                            total: {
                                                show: true,
                                                label: "Total Customers",
                                            },
                                        },
                                    },
                                },
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Engagement by Program Tier
                    </Heading>
                    <BarChart
                        chartData={[
                            {
                                name: "Purchase Frequency",
                                data: engagementByTierData.map((item) => item.purchaseFrequency),
                            },
                            {
                                name: "Points Redemption",
                                data: engagementByTierData.map((item) => item.pointsRedemption),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: engagementByTierData.map((item) => item.tier),
                            },
                            yaxis: [
                                {
                                    title: {
                                        text: "Purchase Frequency (per month)",
                                    },
                                },
                                {
                                    opposite: true,
                                    title: {
                                        text: "Points Redemption",
                                    },
                                },
                            ],
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    columnWidth: "55%",
                                    endingShape: "rounded",
                                },
                            },
                            colors: ["#001842", "#FF5A5F"],

                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Impact of Loyalty on Retention
                    </Heading>
                    <LineChart
                        chartData={[
                            {
                                name: "Members",
                                data: retentionComparisonData.map((item) => item.members),
                            },
                            {
                                name: "Non-members",
                                data: retentionComparisonData.map((item) => item.nonMembers),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: retentionComparisonData.map((item) => item.month),
                            },
                            yaxis: {
                                title: {
                                    text: "Retention Rate (%)",
                                },
                            },
                            markers: {
                                size: 4,
                            },
                            legend: {
                                position: "top",
                            },
                            colors: ["#001842", "#FF5A5F"],

                        }}
                    />
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default LoyaltyProgramAnalytics;
