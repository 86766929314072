import React, { useState } from "react";
import { Box, Heading, SimpleGrid, Select, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import LineChart from "components/charts/LineChart";
import BarChart from "components/charts/BarChart";
import BubbleChart from "components/charts/BubbleChart";
import AIInsight from "components/AIInsight";

// Mock data
const retentionRateData = [
  { name: "Jan", rate: 85 },
  { name: "Feb", rate: 87 },
  { name: "Mar", rate: 86 },
  { name: "Apr", rate: 88 },
  { name: "May", rate: 90 },
  { name: "Jun", rate: 89 },
];

const churnRateBySegmentData = [
  { name: "High Value", rate: 5 },
  { name: "Medium Value", rate: 8 },
  { name: "Low Value", rate: 12 },
  { name: "New Customers", rate: 15 },
];

const churnPredictionData = [
  { x: 100, y: 200, z: 30, name: "Customer A" },
  { x: 120, y: 100, z: 60, name: "Customer B" },
  { x: 170, y: 300, z: 50, name: "Customer C" },
  { x: 140, y: 250, z: 70, name: "Customer D" },
  { x: 150, y: 400, z: 20, name: "Customer E" },
];

const ChurnPrediction = () => {
  const [selectedSegment, setSelectedSegment] = useState("All");
  const [selectedChannel, setSelectedChannel] = useState("All");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <AIInsight message="Churn risk has decreased by 7% in the high-value segment. Consider expanding the loyalty program to further reduce churn." />

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <Card>
          <Heading size="md" mb="4">
            Retention Rate Over Time
          </Heading>
          <Select
            mb="4"
            value={selectedSegment}
            onChange={(e) => setSelectedSegment(e.target.value)}
          >
            <option value="All">All Segments</option>
            <option value="High Value">High Value</option>
            <option value="Medium Value">Medium Value</option>
            <option value="Low Value">Low Value</option>
          </Select>
          <Select
            mb="4"
            value={selectedChannel}
            onChange={(e) => setSelectedChannel(e.target.value)}
          >
            <option value="All">All Channels</option>
            <option value="Online">Online</option>
            <option value="In-store">In-store</option>
            <option value="Mobile">Mobile</option>
          </Select>
          <LineChart
            chartData={[
              {
                name: "Retention Rate",
                data: retentionRateData.map((item) => item.rate),
              },
            ]}
            chartOptions={{
              xaxis: {
                categories: retentionRateData.map((item) => item.name),
              },
              yaxis: {
                title: {
                  text: "Retention Rate (%)",
                },
              },
            }}
          />
        </Card>
        <Card>
          <Heading size="md" mb="4">
            Churn Rate by Segment
          </Heading>
          <BarChart
            chartData={[
              {
                name: "Churn Rate",
                data: churnRateBySegmentData.map((item) => item.rate),
              },
            ]}
            chartOptions={{
              xaxis: {
                categories: churnRateBySegmentData.map((item) => item.name),
              },
              yaxis: {
                title: {
                  text: "Churn Rate (%)",
                },
              },
            }}
          />
        </Card>
        <Card>
          <Heading size="md" mb="4">
            Churn Prediction
          </Heading>
          <BubbleChart
            chartData={[
              {
                name: "Customers",
                data: churnPredictionData.map((item) => [item.x, item.y, item.z]),
              },
            ]}
            chartOptions={{
              xaxis: {
                title: {
                  text: "Customer Lifetime Value",
                },
              },
              yaxis: {
                title: {
                  text: "Days Since Last Purchase",
                },
              },
              tooltip: {
                y: {
                  formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return `${value} (Churn Probability: ${series[seriesIndex][dataPointIndex][2]}%)`;
                  },
                },
              },
            }}
          />
          <Text mt="4" fontSize="sm" color="gray.500">
            Bubble size represents churn probability. Larger bubbles indicate higher risk.
          </Text>
        </Card>
      </SimpleGrid>
    </Box>
  );
};

export default ChurnPrediction;
