import { logData } from "util/Logger";

export function convertToRowData(firestoreModelData) {
    logData('convertToRowData:', firestoreModelData);
    // Extract data from input
    const {
        product_id,
        name,
        description,
        base_price,
        currency,
        categories,
        shop_name,
        sku,
        tags,
        vendor,
        digital_assets,
        external_ids,
        sync_status,
        last_sync,
        version,
        bulk_edit_id,
        created_at,
        updated_at,
        variants,
        attributes,
    } = firestoreModelData;

    // Create output data
    const outputData = {};

    if (product_id) outputData.id = product_id;
    if (name) outputData.name = [
        name,
        product_id,
        digital_assets.length ? digital_assets[0] : '',
    ];
    if (description) outputData.description = description;
    if (base_price) outputData.price = `${base_price.toFixed(2)}`;
    if (categories) outputData.categories = categories.filter(c => c.length > 0);
    if (attributes) outputData.attributes = attributes;

    if (digital_assets) {
        outputData.digital_assets = digital_assets;
    }

    if (external_ids) {
        const externalIdKeys = Object.keys(external_ids);
        outputData.externalIds = externalIdKeys.map((key) => `${key}: ${external_ids[key]}`).join(', ');
        outputData.channel = externalIdKeys[0];
    }

    if (sync_status) {
        const syncStatusKeys = Object.keys(sync_status);
        outputData.syncStatus = syncStatusKeys.map((key) => `${key}: ${sync_status[key]}`).join(', ');
    }

    if (last_sync) {
        outputData.lastSync = last_sync;
    }

    if (version) outputData.version = version;
    if (bulk_edit_id) outputData.bulkEditId = bulk_edit_id;

    if (created_at) {
        outputData.createdAt = new Date(created_at).toLocaleString();
    }

    if (updated_at) {
        outputData.updatedAt = new Date(updated_at).toLocaleString();
    }

    if (currency) outputData.currency = currency;
    if (shop_name) outputData.shopName = shop_name;
    if (sku) outputData.sku = sku;
    if (tags) outputData.tags = tags.split(',');
    if (vendor) outputData.vendor = vendor;
    if (variants) outputData.variants = variants;

    logData('Converted product data:', outputData);
    return outputData;
}

export function convertToFirestoreModel(rowData, existingModel) {
    logData('convertToFirestoreModel:', rowData);

    // Create Firestore product model
    const firestoreProductModel = existingModel || {};

    // Iterate over the changed values and update the Firestore product model
    for (const [key, value] of Object.entries(rowData)) {
        firestoreProductModel[key] = value;
    }

    logData('Converted Firestore product model:', firestoreProductModel);
    return firestoreProductModel;
}
