import React, { useState } from "react";
import { Box, Heading, SimpleGrid, Select, Table, Thead, Tbody, Tr, Th, Td, Button } from "@chakra-ui/react";
import Card from "components/card/Card";
import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import AIInsight from "components/AIInsight";

// Mock data
const clvDistributionData = [
    { range: "0-100", count: 500 },
    { range: "101-200", count: 800 },
    { range: "201-300", count: 1200 },
    { range: "301-400", count: 900 },
    { range: "401-500", count: 600 },
    { range: "501+", count: 300 },
];

const clvGrowthData = [
    { month: "Jan", highValue: 500, mediumValue: 300, lowValue: 100 },
    { month: "Feb", highValue: 520, mediumValue: 310, lowValue: 105 },
    { month: "Mar", highValue: 550, mediumValue: 320, lowValue: 110 },
    { month: "Apr", highValue: 580, mediumValue: 340, lowValue: 115 },
    { month: "May", highValue: 600, mediumValue: 360, lowValue: 120 },
    { month: "Jun", highValue: 650, mediumValue: 380, lowValue: 125 },
];

const topCustomersData = [
    { id: 1, name: "John Doe", clv: 1500, lastPurchase: "2023-05-15" },
    { id: 2, name: "Jane Smith", clv: 1400, lastPurchase: "2023-05-10" },
    { id: 3, name: "Alice Johnson", clv: 1300, lastPurchase: "2023-05-12" },
    { id: 4, name: "Bob Brown", clv: 1200, lastPurchase: "2023-05-08" },
    { id: 5, name: "Charlie Davis", clv: 1100, lastPurchase: "2023-05-14" },
];

const CustomerLifetimeValue = () => {
    const [selectedSegment, setSelectedSegment] = useState("All");

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <AIInsight message="Average CLV has grown by 12% this quarter. The 'Premium Rewards' initiative is showing positive results." />

            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
                <Card>
                    <Heading size="md" mb="4">
                        CLV Distribution
                    </Heading>
                    <Select
                        mb="4"
                        value={selectedSegment}
                        onChange={(e) => setSelectedSegment(e.target.value)}
                    >
                        <option value="All">All Segments</option>
                        <option value="High Value">High Value</option>
                        <option value="Medium Value">Medium Value</option>
                        <option value="Low Value">Low Value</option>
                    </Select>
                    <BarChart
                        chartData={[
                            {
                                name: "Customer Count",
                                data: clvDistributionData.map((item) => item.count),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: clvDistributionData.map((item) => item.range),
                                title: { text: "CLV Range ($)" },
                            },
                            yaxis: {
                                title: { text: "Number of Customers" },
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        CLV Growth Over Time
                    </Heading>
                    <LineChart
                        chartData={[
                            {
                                name: "High Value",
                                data: clvGrowthData.map((item) => item.highValue),
                            },
                            {
                                name: "Medium Value",
                                data: clvGrowthData.map((item) => item.mediumValue),
                            },
                            {
                                name: "Low Value",
                                data: clvGrowthData.map((item) => item.lowValue),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: clvGrowthData.map((item) => item.month),
                            },
                            yaxis: {
                                title: { text: "Average CLV ($)" },
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        High-CLV Customer Identification
                    </Heading>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>CLV</Th>
                                <Th>Last Purchase</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {topCustomersData.map((customer) => (
                                <Tr key={customer.id}>
                                    <Td>{customer.name}</Td>
                                    <Td>${customer.clv}</Td>
                                    <Td>{customer.lastPurchase}</Td>
                                    <Td>
                                        <Button size="sm" colorScheme="blue">
                                            View Profile
                                        </Button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default CustomerLifetimeValue;
