/*eslint-disable*/
import { addRxPlugin, createRxDatabase, isRxDatabase } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { errorData, logData } from './Logger';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { auth } from 'firebaseApp';

addRxPlugin(RxDBUpdatePlugin);


const setupRxDBFirebaseSync = async (db) => {
    const firestore = getFirestore();
    if (auth.currentUser == null) return;
    const userId = auth.currentUser.uid;

    // Ensure the facebook_campaigns collection exists
    if (!db.facebook_campaigns) {
        await createFacebookCampaignsCollection(db);
    }

    // Ensure the app_status collection exists
    if (!db.app_status) {
        await createAppStatusCollection(db);
    }

    // Sync facebook_campaigns
    // db.facebook_campaigns.$.subscribe(async (changeEvent) => {
    //     if (changeEvent.operation === 'INSERT' || changeEvent.operation === 'UPDATE') {
    //         const campaign = changeEvent.documentData;
    //         await setDoc(doc(firestore, `Users/${userId}/FacebookCampaigns/${campaign.id}`), campaign);
    //     }
    // });

    // Sync app_status
    db.app_status.$.subscribe(async (changeEvent) => {
        if (changeEvent.operation === 'INSERT' || changeEvent.operation === 'UPDATE') {
            const status = changeEvent.documentData;
            await setDoc(doc(firestore, `Users/${userId}/Apps/facebook`), status);
        }
    });
};


export const getRxDatabase = async () => {
    try {
        const rxDatabase = await createRxDatabase({
            name: 'hgm-admin-db',
            storage: getRxStorageDexie(),
            ignoreDuplicate: true,
        });

        await setupRxDBFirebaseSync(rxDatabase);

        return rxDatabase;
    } catch (error) {
        if (error?.parameters?.errors?.length != null && isRxDatabase(error.parameters.errors[0])) {
            // The database already exists, return the existing database
            return error.parameters.errors[0].rxDatabase;
        } else {
            // Some other error occurred, rethrow the error
            throw error;
        }
    }
}


const FACEBOOK_CAMPAIGNS_SCHEMA = {
    version: 0,
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
            maxLength: 36,
        },
        name: {
            type: 'string',
        },
        lastUpdated: {
            type: 'number',
        },
    },
    additionalProperties: true,
};


const APP_STATUS_SCHEMA = {
    version: 0,
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
            maxLength: 100  // Set an appropriate max length
        },
        status: {
            type: 'string'
        },
        accessToken: {
            type: 'string'
        },
        campaignIds: {
            type: 'array',
            items: {
                type: 'string'
            }
        },
        adAccount: {
            type: 'string'
        },
        lastUpdated: {
            type: 'number'
        }
    }
};

const DATE_RANGE_SCHEMA = {
    version: 0,
    type: 'object',
    primaryKey: 'id',
    properties: {
        id: {
            type: 'string',
            maxLength: 100
        },
        preset: {
            type: 'string'
        },
        lastUpdated: {
            type: 'number'
        }
    }
};

const createDateRangeCollection = async (db) => {
    return db.addCollections({
        date_range: {
            schema: DATE_RANGE_SCHEMA
        }
    });
};

export const updateDateRange = async (dateRange) => {
    const db = await getRxDatabase();
    if (!db.collections.date_range) {
        await createDateRangeCollection(db);
    }
    await db.date_range.upsert({
        id: 'facebook_ads_date_range',
        ...dateRange,
        lastUpdated: Date.now()
    });
};

export const getDateRange = async () => {
    const db = await getRxDatabase();
    if (!db.collections.date_range) {
        await createDateRangeCollection(db);
    }
    return db.date_range.findOne('facebook_ads_date_range').exec();
};

const createAppStatusCollection = async (db) => {
    return db.addCollections({
        app_status: {
            schema: APP_STATUS_SCHEMA
        }
    });
};

export const updateAppStatus = async (status) => {
    const db = await getRxDatabase();
    if (!db.collections.app_status) {
        await createAppStatusCollection(db);
    }
    await db.app_status.upsert({
        id: 'facebook_status',
        ...status,
        lastUpdated: Date.now()
    });
};

export const getAppStatusRx = async () => {
    const db = await getRxDatabase();
    if (!db.collections.app_status) {
        await createAppStatusCollection(db);
    }
    return db.app_status.findOne('facebook_status').exec();
};

export const createFacebookCampaignsCollection = async (db) => {
    return db.addCollections({
        facebook_campaigns: {
            schema: FACEBOOK_CAMPAIGNS_SCHEMA,
        }
    });
};

export const addFacebookCampaigns = async (campaigns) => {
    const db = await getRxDatabase();
    if (!db.collections.facebook_campaigns) {
        await createFacebookCampaignsCollection(db);
    }
    await db.facebook_campaigns.bulkInsert(campaigns.map(campaign => ({
        ...campaign,
        lastUpdated: Date.now(),
    })));
};

export const getFacebookCampaigns = async () => {
    const db = await getRxDatabase();
    if (!db.collections.facebook_campaigns) {
        await createFacebookCampaignsCollection(db);
    }
    return db.facebook_campaigns.find().exec();
};

export const clearRxDatabase = async () => {
    try {
        const db = await getRxDatabase();
        if (db) {
            await db.remove();
        }
    } catch (error) {
        console.error('Error clearing RxDatabase:', error);
    }
};