import { useDisclosure, Flex, HStack, Icon, Portal, IconButton, Menu, MenuGroup, MenuDivider, MenuButton, MenuItem, MenuList, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import React from "react";
import { IoMdTrash } from "react-icons/io";
import { MdDragHandle, MdEdit, MdMoreVert } from "react-icons/md";

export default function Widget(props) {
    const { id, onRemoveItem, dashboards, currentDashboard, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';



    const borderColor = useColorModeValue('secondaryGray.200', 'whiteAlpha.100');

    return (
        <Card
            borderRadius='5px'
            boxShadow='sm'
            // backgroundColor={props.isSpreadsheet ? 'transparent' : 'white'}
            // _hover={{ boxShadow: shadow }}
            // _hover={bgShadow}
            // _focus={shadow}
            // _active={shadow}
            border='1px solid'
            borderColor={'white'}
            px='0px'
            py='0px'
            w='100%'
            h='100%'
            display='flex'
            flexDirection='column'
            {...rest}>
            <Flex direction="row" width="100%" justify={"space-between"}>
                <Flex direction='row' justifyContent='center' alignItems='flex-start' m='0'
                    // backgroundColor='#dde1ea'
                    className="my-drag-handle"
                    w='100%'
                    cursor='pointer'
                    borderTopLeftRadius="10px"
                    borderTopRightRadius="10px"
                >
                    <Icon
                        as={MdDragHandle}
                        color='secondaryGray.500'
                        w='18px'
                        h='24px'
                        cursor='pointer'
                    />
                </Flex>
                <HStack spacing={1}>
                    <IconButton
                        aria-label="Edit Widget"
                        icon={<MdEdit />}
                        color={"secondaryGray.500"}
                        size="sm"
                        onClick={() => props.onEdit(props.id)}
                    />
                    <Menu isOpen={isOpen} onClose={onClose}>
                        <MenuButton
                            as={IconButton}
                            aria-label="More options"
                            icon={<MdMoreVert />}
                            color={"secondaryGray.500"}
                            size="sm"
                            onClick={onOpen}
                        />
                        <Portal>
                            <MenuList zIndex={1000} >
                                <MenuGroup title="Copy To">
                                    {Object.keys(dashboards).map(dashboardName => (
                                        dashboardName !== currentDashboard && (
                                            <MenuItem key={`copy-${dashboardName}`} onClick={() => props.onCopyTo(props.id, dashboardName)}>
                                                {dashboardName}
                                            </MenuItem>
                                        )
                                    ))}
                                </MenuGroup>
                                <MenuDivider />
                                <MenuGroup title="Move To">
                                    {Object.keys(dashboards).map(dashboardName => (
                                        dashboardName !== currentDashboard && (
                                            <MenuItem key={`move-${dashboardName}`} onClick={() => props.onMoveTo(props.id, dashboardName)}>
                                                {dashboardName}
                                            </MenuItem>
                                        )
                                    ))}
                                </MenuGroup>
                                <MenuDivider />
                                <MenuItem
                                    color="red"
                                    onClick={() => props.onRemove(props.id)}>
                                    <IoMdTrash me={2} color="red" />
                                    Delete Widget</MenuItem>
                            </MenuList>
                        </Portal>
                    </Menu>
                </HStack>
            </Flex>
            {props.children}
        </Card>
    );
}
