import React, { useState } from 'react';
import { VStack, Button, Select, Input, Text } from "@chakra-ui/react";

export const CampaignWizard = ({ onComplete }) => {
  const [step, setStep] = useState(1);
  const [campaignData, setCampaignData] = useState({
    audience: '',
    goal: '',
    creative: '',
    channels: [],
    budget: '',
    duration: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignData(prev => ({ ...prev, [name]: value }));
  };

  const handleNext = () => {
    if (step < 5) setStep(step + 1);
    else onComplete(campaignData);
  };

  return (
    <VStack spacing={4} align="stretch">
      {step === 1 && (
        <>
          <Text fontWeight="bold">Step 1: Audience</Text>
          <Select name="audience" value={campaignData.audience} onChange={handleChange}>
            <option value="at-risk">At-Risk Customers</option>
            <option value="champions">Champions</option>
            <option value="new">New Customers</option>
          </Select>
        </>
      )}
      {step === 2 && (
        <>
          <Text fontWeight="bold">Step 2: Campaign Goal</Text>
          <Input name="goal" value={campaignData.goal} onChange={handleChange} placeholder="Enter campaign goal" />
        </>
      )}
      {step === 3 && (
        <>
          <Text fontWeight="bold">Step 3: Ad Creative</Text>
          <Input name="creative" value={campaignData.creative} onChange={handleChange} placeholder="Enter ad creative" />
        </>
      )}
      {step === 4 && (
        <>
          <Text fontWeight="bold">Step 4: Channels</Text>
          <Select name="channels" multiple value={campaignData.channels} onChange={handleChange}>
            <option value="facebook">Facebook Ads</option>
            <option value="email">Email</option>
            <option value="google">Google Ads</option>
          </Select>
        </>
      )}
      {step === 5 && (
        <>
          <Text fontWeight="bold">Step 5: Budget & Schedule</Text>
          <Input name="budget" value={campaignData.budget} onChange={handleChange} placeholder="Enter budget" />
          <Input name="duration" value={campaignData.duration} onChange={handleChange} placeholder="Enter duration (in days)" />
        </>
      )}
      <Button onClick={handleNext} colorScheme="blue">
        {step === 5 ? 'Launch Campaign' : 'Next'}
      </Button>
    </VStack>
  );
};
