import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";

export const importFromShopify = async ({
    importProducts, importOrders, importCustomers,
}) => {
    const importFromShopifyFunction = httpsCallable(functions, 'importFromShopify');

    try {
        const result = await importFromShopifyFunction({
            importProducts,
            importOrders,
            importCustomers,
        });

        return result.data;
    } catch (error) {
        console.error('Error calling importFromShopify:', error);
        throw error;
    }
};
