import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Input,
  VStack,
  FormControl,
  FormLabel,
  ModalFooter,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SimpleGrid,
  ButtonGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Select,
  StatArrow,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  IconButton,
  Alert,
  AlertIcon,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid, Line } from 'recharts';
import ReactFlow from 'reactflow';
import { FaEnvelope, FaMagic } from "react-icons/fa";

import 'reactflow/dist/style.css';
import { MdEdit, MdMoreHoriz, MdClose, MdDelete, MdAdd, MdAutoAwesome, MdThumbUp, MdThumbDown } from "react-icons/md";
import Card from "components/card/Card";
import { CgImport } from "react-icons/cg";
import SearchTable2 from "components/table/SearchTableOverview";
import { IoFilter } from "react-icons/io5";
import AIInsight from "components/AIInsight";
import LineChart from "components/charts/LineChart";

// Mock Data
const mockPreviewData = [
  { id: 1, name: 'John Doe', lastPurchase: '2023-08-15', totalSpend: 1500 },
  { id: 2, name: 'Jane Smith', lastPurchase: '2023-08-10', totalSpend: 2300 },
  { id: 3, name: 'Alice Johnson', lastPurchase: '2023-07-22', totalSpend: 3200 },
  { id: 4, name: 'Bob Brown', lastPurchase: '2023-09-01', totalSpend: 1200 },
  // More mock customers
];

const mockSegmentData = [
  { name: "High Value Customers", size: 1000, clv: "$500", lastUpdated: "2023-05-15" },
  { name: "At-Risk Customers", size: 500, clv: "$200", lastUpdated: "2023-05-14" },
  { name: "New Customers", size: 2000, clv: "$100", lastUpdated: "2023-05-13" },
];

const mockBarChartData = [
  { name: 'Jan', value: 400 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 500 },
  // More mock data points
];

const mockFlowElements = [
  { id: '1', data: { label: 'Start' }, position: { x: 250, y: 0 } },
  { id: '2', data: { label: 'Customer Interaction' }, position: { x: 250, y: 100 } },
  { id: '3', data: { label: 'Retention' }, position: { x: 250, y: 200 } },
  { id: 'e1-2', source: '1', target: '2', type: 'smoothstep' },
  { id: 'e2-3', source: '2', target: '3', type: 'smoothstep' },
  // More flow elements
];

// Criteria Options
const criteriaOptions = [
  { id: '1', content: 'Age: 25-35', filterFunction: (customer) => customer.age >= 25 && customer.age <= 35 },
  {
    id: '2', content: 'Last Purchase: Last 30 Days', filterFunction: (customer) => {
      const lastPurchaseDate = new Date(customer.lastPurchase);
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      return lastPurchaseDate >= thirtyDaysAgo;
    }
  },
  { id: '3', content: 'Total Spend: > $2000', filterFunction: (customer) => customer.totalSpend > 2000 },
  // Add more criteria as needed
];

// Page Header Component
const PageHeader = () => (
  <Flex justifyContent="space-between" alignItems="center" mb={4}>
    {/* <Heading>Customer Segmentation</Heading> */}
    <HStack>
      <Button colorScheme="blue">Create New Segment</Button>
      <Button variant="outline">Import Segment</Button>
    </HStack>
  </Flex>
);

// Segment Builder Component
const SegmentBuilder = ({ criteria, onDragEnd, removeCriterion, previewData }) => (
  <Card mt={8} mb="25px">
    <Flex justify="space-between" align="center">
      <Heading size="md">Segment Builder</Heading>
      <Button leftIcon={<MdAdd />} colorScheme="brand" size="sm">
        Add Criteria
      </Button>
    </Flex>
    <Box>
      <VStack spacing={4} align="stretch">
        <Box bg="gray.50" p={4} borderRadius="md">
          <Text fontWeight="bold" mb={2}>Drag and drop criteria here</Text>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="criteria">
              {(provided) => (
                <VStack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  spacing={2}
                  align="stretch"
                  minHeight="100px"
                >
                  {criteria.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          bg="white"
                          p={3}
                          borderRadius="md"
                          boxShadow="sm"
                          border="1px solid"
                          borderColor="gray.200"
                        >
                          <Flex justify="space-between" align="center">
                            <Text>{item.content}</Text>
                            <IconButton
                              icon={<MdClose />}
                              size="sm"
                              variant="ghost"
                              colorScheme="red"
                              onClick={() => removeCriterion(index)}
                            />
                          </Flex>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </VStack>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
        <Flex justify="space-between" align="center">
          <Text fontWeight="bold">Segment Size: {previewData.length}</Text>
          <Button colorScheme="brand" size="sm">
            Save Segment
          </Button>
        </Flex>
      </VStack>
    </Box>
  </Card>
);

// Preview Segment Table Component
const PreviewSegmentTable = ({ previewData }) => (
  <Table variant="simple">
    <Thead>
      <Tr>
        <Th>Customer ID</Th>
        <Th>Name</Th>
        <Th>Last Purchase</Th>
        <Th>Total Spend</Th>
      </Tr>
    </Thead>
    <Tbody>
      {previewData.map((customer) => (
        <Tr key={customer.id}>
          <Td>{customer.id}</Td>
          <Td>{customer.name}</Td>
          <Td>{customer.lastPurchase}</Td>
          <Td>{customer.totalSpend}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

// Segment Analysis Component
const SegmentAnalysis = () => (
  <Box width="50%" pl={4}>
    <Heading size="md" mb={4}>Segment Analysis</Heading>
    <SimpleGrid columns={2} spacing={4} mb={4}>
      <Stat>
        <StatLabel>Average CLV</StatLabel>
        <StatNumber>$1,200</StatNumber>
        <StatHelpText>
          <StatArrow type="increase" />
          5% from last month
        </StatHelpText>
      </Stat>
      <Stat>
        <StatLabel>Churn Rate</StatLabel>
        <StatNumber>2.5%</StatNumber>
        <StatHelpText>
          <StatArrow type="decrease" />
          1% from last month
        </StatHelpText>
      </Stat>
    </SimpleGrid>

    <Box height="300px" mb={4}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={mockBarChartData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#26A0FC" />
        </BarChart>
      </ResponsiveContainer>
    </Box>

    <Box height="300px">
      <ReactFlow elements={mockFlowElements} />
    </Box>
  </Box>
);

// Segment Management Component
const SegmentManagement = () => (
  <Box mt={8}>
    {/* <Heading size="md" mb={4}>Segment Management</Heading> */}
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Segment Name</Th>
          <Th>Size</Th>
          <Th>CLV</Th>
          <Th>Last Updated</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {mockSegmentData.map((segment) => (
          <Tr key={segment.name}>
            <Td>{segment.name}</Td>
            <Td>{segment.size}</Td>
            <Td>{segment.clv}</Td>
            <Td>{segment.lastUpdated}</Td>
            <Td>
              <Button size="sm" variant="outline" colorScheme="blue" mr={2}>Edit</Button>
              <Button size="sm" variant="outline" colorScheme="red">Delete</Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);


const CLVWidget = () => {
  const [showPredictiveModel, setShowPredictiveModel] = useState(false);
  const [showAssumptions, setShowAssumptions] = useState(false);
  const [purchaseFrequency, setPurchaseFrequency] = useState(12);
  const [averageOrderValue, setAverageOrderValue] = useState(100);

  const predictiveCLVData = [
    { month: 1, Champions: 1000, AtRisk: 500, NewCustomers: 300 },
    { month: 6, Champions: 1200, AtRisk: 450, NewCustomers: 400 },
    { month: 12, Champions: 1500, AtRisk: 400, NewCustomers: 600 },
    // ... more data points
  ];

  return (
    <Box borderWidth="1px" borderRadius="lg" p={6}>
      <Heading size="md" mb={4}>Customer Lifetime Value (CLV)</Heading>
      <Text mb={4}>
        Here is your Customer Lifetime Value (CLV). In this advanced breakdown, you can assess future revenue potential by segment, and deploy strategies to maximize it.
      </Text>
      <Stat mb={4}>
        <StatLabel>Average CLV</StatLabel>
        <StatNumber>$1,200</StatNumber>
        <StatHelpText>
          <StatArrow type="increase" />
          23.36%
        </StatHelpText>
      </Stat>
      <Button onClick={() => setShowPredictiveModel(true)} colorScheme="blue" mb={4}>
        Access Predictive CLV Modeling
      </Button>

      <Modal isOpen={showPredictiveModel} onClose={() => setShowPredictiveModel(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Predictive CLV Model</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Expected CLV based on customer behavior, purchase history, and engagement:</Text>
            <Box height="300px">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={predictiveCLVData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Champions" stroke="#8884d8" />
                  <Line type="monotone" dataKey="AtRisk" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="NewCustomers" stroke="#ffc658" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Button onClick={() => setShowAssumptions(true)} mt={4} colorScheme="blue">
              Adjust CLV Calculation Assumptions
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showAssumptions} onClose={() => setShowAssumptions(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adjust CLV Assumptions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Purchase Frequency (per year)</FormLabel>
                <Input type="number" value={purchaseFrequency} onChange={(e) => setPurchaseFrequency(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Average Order Value</FormLabel>
                <Input type="number" value={averageOrderValue} onChange={(e) => setAverageOrderValue(e.target.value)} />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setShowAssumptions(false)}>
              Apply Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Text fontWeight="bold" mt={4}>Key Insight:</Text>
      <Text>
        Leverage predictive CLV to prioritize your marketing spend. Invest more in segments with the highest future value and create retention strategies for those with lower CLV projections.
      </Text>
    </Box>
  );
};

const CustomerSegmentationPage = () => {
  const [criteria, setCriteria] = useState(criteriaOptions);
  const [previewData, setPreviewData] = useState(mockPreviewData);
  const [selectedSegment, setSelectedSegment] = useState(null);


  const columns = [
    { Header: "Segment Name", accessor: "name" },
    { Header: "Size", accessor: "size" },
    { Header: "CLV", accessor: "clv" },
    { Header: "Last Updated", accessor: "lastUpdated" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <Menu>
          <MenuButton as={Button} rightIcon={<MdMoreHoriz />} variant="ghost">
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem icon={<Icon as={MdEdit} />} onClick={() => handleEdit(row.original)}>
              Edit
            </MenuItem>
            <MenuItem icon={<Icon as={MdDelete} />} onClick={() => handleDelete(row.original)}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ];

  useEffect(() => {
    setSelectedSegment(mockSegmentData[0]);
  }, []);

  const handleEdit = (segment) => {
    console.log("Edit segment:", segment);
  };

  const handleDelete = (segment) => {
    console.log("Delete segment:", segment);
  };

  const handleCreateNewSegment = () => {
    console.log("Create new segment");
  };

  const handleImportSegment = () => {
    console.log("Import segment");
  };

  const handleRowClick = (row) => {
    setSelectedSegment(row);
    console.log("selected segment:", row);
  };

  const segmentNames = mockSegmentData.map((segment) => segment.name);


  const SegmentAnalysis = ({ segment }) => {
    if (!segment) return null;

    return (
      <Box borderRadius="25px" >

        <Flex direction="row" justify="flex-end" alignItems="flex-end" mb={8}>
          <Select
            value={selectedSegment}
            onChange={(e) => setSelectedSegment(e.target.value)}
            borderRadius="25px"
            boxShadow="md"
          >
            {segmentNames.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </Flex>
        <SimpleGrid columns={2} spacing={4} mb={4}>
          <Stat>
            <StatLabel>Average CLV</StatLabel>
            <StatNumber>${segment.clv}</StatNumber>
            <StatHelpText>
              <StatArrow type="increase" />
              5% from last month
            </StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Churn Rate</StatLabel>
            <StatNumber>2.5%</StatNumber>
            <StatHelpText>
              <StatArrow type="decrease" />
              1% from last month
            </StatHelpText>
          </Stat>
        </SimpleGrid>
        <Box height="300px">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={[
              { name: 'Revenue', value: 4000 },
              { name: 'Orders', value: 3000 },
              { name: 'Customers', value: 2000 },
            ]}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#001842" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const newCriteria = Array.from(criteria);
    const [removed] = newCriteria.splice(source.index, 1);
    newCriteria.splice(destination.index, 0, removed);

    setCriteria(newCriteria);
    updatePreview(newCriteria);
  };

  const addCriterion = (criterion) => {
    setCriteria([...criteria, criterion]);
    updatePreview([...criteria, criterion]);
  };

  const removeCriterion = (index) => {
    const newCriteria = criteria.filter((_, i) => i !== index);
    setCriteria(newCriteria);
    updatePreview(newCriteria);
  };

  const updatePreview = (criteria) => {
    let filteredData = mockPreviewData;

    criteria.forEach(criterion => {
      filteredData = filteredData.filter(criterion.filterFunction);
    });

    setPreviewData(filteredData);
  };


  return (
    <Box pt={{ base: "130px", md: "60px" }}>

      <AIInsight message="Customer segments are performing well! No immediate actions needed." />

      <Flex width="100%" mt={8}>
        <SearchTable2
          px={0}
          mx={0}
          h="100%"
          minHeight="100%"
          tableData={mockSegmentData}
          columnsData={columns}
          searchPlaceholder="Search segments..."
          onRowClick={handleRowClick}
          selectedRow={selectedSegment}
          onSelectedItemsChange={() => { }}
          customActionMenu={<Flex>
            <Button leftIcon={<MdAdd />} colorScheme="brand" onClick={handleCreateNewSegment} mr={2}>
              Create New Segment
            </Button>
            <Button variant="outline" onClick={handleImportSegment}>
              <CgImport />
            </Button>
          </Flex>}
        />
      </Flex>

      <Flex width="100%" mt={0}>
        <SegmentBuilder
          criteria={criteria}
          onDragEnd={onDragEnd}
          removeCriterion={removeCriterion}
          previewData={previewData}
        />
        <SegmentAnalysis />
      </Flex>

    </Box>
  );
};

export default CustomerSegmentationPage;
