import React from "react";
import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import BubbleChart from "components/charts/BubbleChart";
import AIInsight from "components/AIInsight";

// Mock data
const channelRetentionData = [
    { channel: "Online", retentionRate: 75 },
    { channel: "In-store", retentionRate: 70 },
    { channel: "Mobile App", retentionRate: 80 },
    { channel: "Social Media", retentionRate: 65 },
];

const crossChannelBehaviorData = [
    { name: "Online to In-store", value: 30 },
    { name: "In-store to Online", value: 25 },
    { name: "Mobile App to In-store", value: 20 },
    { name: "Social Media to Online", value: 15 },
    { name: "Online to Mobile App", value: 10 },
];

const channelEngagementData = [
    { channel: "Online", pageViews: 100, timeSpent: 5, conversionRate: 3 },
    { channel: "In-store", footTraffic: 50, avgTimeInStore: 20, purchaseRate: 15 },
    { channel: "Mobile App", activeUsers: 80, sessionDuration: 8, inAppPurchases: 5 },
    { channel: "Social Media", followers: 200, engagement: 10, clickThroughRate: 2 },
];

const ChannelPerformance = () => {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <AIInsight message="Channel performance is strong across all platforms. Mobile app engagement has increased by 15% this month." />

            <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
                <Card>
                    <Heading size="md" mb="4">
                        Channel Retention Rates
                    </Heading>
                    <BarChart
                        chartData={[
                            {
                                name: "Retention Rate",
                                data: channelRetentionData.map((item) => item.retentionRate),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: channelRetentionData.map((item) => item.channel),
                            },
                            yaxis: {
                                title: {
                                    text: "Retention Rate (%)",
                                },
                            },
                            colors: ["#001842", "#FF5A5F"],

                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Cross-Channel Behavior
                    </Heading>
                    <LineChart
                        chartData={[
                            {
                                name: "Customer Flow",
                                data: crossChannelBehaviorData.map((item) => item.value),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: crossChannelBehaviorData.map((item) => item.name),
                            },
                            yaxis: {
                                title: {
                                    text: "Customer Flow (%)",
                                },
                            },
                            markers: {
                                size: 6,
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Channel Engagement Comparison
                    </Heading>
                    <BubbleChart
                        chartData={[
                            {
                                name: "Engagement",
                                data: channelEngagementData.map((item) => [
                                    item.pageViews || item.footTraffic || item.activeUsers || item.followers,
                                    item.timeSpent || item.avgTimeInStore || item.sessionDuration || item.engagement,
                                    item.conversionRate || item.purchaseRate || item.inAppPurchases || item.clickThroughRate,
                                ]),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                title: {
                                    text: "Traffic/Users",
                                },
                            },
                            yaxis: {
                                title: {
                                    text: "Time Spent/Engagement",
                                },
                            },
                            tooltip: {
                                y: {
                                    formatter: function (value, { seriesIndex, dataPointIndex }) {
                                        return `${channelEngagementData[dataPointIndex].channel}: ${value}`;
                                    },
                                },
                            },
                        }}
                    />
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default ChannelPerformance;
