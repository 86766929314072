// Chakra imports
import { Flex, Text, useColorModeValue, Badge, Icon } from '@chakra-ui/react';
import { MdArrowRightAlt } from 'react-icons/md';

export default function ListItem(props) {
  const { subtitle, textEnd, image, title, iconColor, clickableRows, icon, showBadge = true, ...rest } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Flex justifyContent="center" alignItems="center" w="100%"
      cursor={clickableRows ? 'pointer' : 'default'}
      _hover={{ bg: clickableRows ? 'gray.100' : '' }}
      borderRadius={clickableRows ? '5px' : ''}
      {...rest}>
      <Icon h="34px" w="34px" as={icon} me="14px" color={iconColor || 'gray.400'} />
      <Flex direction="column" align="start" me="auto">
        <Text color={textColor} fontSize="md" me="6px" fontWeight="700">
          {title}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {subtitle}
        </Text>
      </Flex>
      {clickableRows ? (
        <Icon as={MdArrowRightAlt} />
      ) : showBadge && (
        <Badge
          ms="auto"
          color="green.500"
          colorScheme='green'
          fontSize="sm"
        >
          {textEnd}
        </Badge>
      )}
    </Flex>
  );
}
