import React, { useState } from 'react';
import {
    Button,
    Input,
    Textarea,
    VStack,
    Text,
    useToast,
    useColorModeValue,
    Flex,
    Alert,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react';
import Airtable from 'airtable';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const ChatWithUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [alertStatus, setAlertStatus] = useState(null);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');


    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appbw9FhuHO7M735N');

            await base('tblIcfqJl0zmIFjz6').create([
                {
                    "fields": {
                        "Name": name,
                        "Email": email,
                        "Phone": phone,
                        "Message": message
                    }
                }
            ]);

            setAlertStatus({ status: 'success', message: "Message sent. We'll get back to you soon!" });
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
            setAlertStatus({ status: 'error', message: "Failed to send message. Please try again." });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <VStack spacing={4}>
            <Text fontSize='lg' color={textColor} fontWeight='bold' mb='10px'>
                Chat with us
            </Text>

            <Text fontSize='sm' color={textColor} w='300px' mb='20px'>
                Please leave your message and we will get back to you as soon as possible.
            </Text>

            <Flex flexDirection='column'>
                <Input placeholder='Enter your name' mb='10px' w='300px' value={name} onChange={(e) => setName(e.target.value)} />
                <Input placeholder='Email' mb='10px' w='300px' value={email} onChange={(e) => setEmail(e.target.value)} />
                <Input placeholder='Phone Number (Optional)' mb='10px' w='300px' value={phone} onChange={(e) => setPhone(e.target.value)} />
                <Textarea
                    placeholder='Type your message...'
                    borderRadius='10px'
                    h='60px'
                    w='300px'
                    border='1px solid'
                    borderColor={borderColor}
                    mb='20px'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                    w='100%'
                    h='44px'
                    mb='10px'
                    variant='brand'
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    isDisabled={name.trim() == '' || email.trim() == '' || message.trim() == ''}
                >
                    Send Message
                </Button>
                {/* <Link
							w='100%'>
							<Button
								w='100%'
								h='44px'
								mb='10px'
								border='1px solid'
								bg='transparent'
								borderColor={borderButton}>
								Schedule Call
							</Button>
						</Link> */}
                <Button
                    w='100%'
                    h='44px'
                    variant='no-hover'
                    color={textColor}
                    bg='transparent'
                    as='a'
                    href='https://sinteli.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    Visit Our Website <ExternalLinkIcon ml='2px' />
                </Button>
            </Flex>

            {alertStatus && (
                <Alert status={alertStatus.status} mt={4}>
                    <AlertIcon />
                    <AlertTitle mr={2}>{alertStatus.message}</AlertTitle>
                </Alert>
            )}
        </VStack>
    );
};

export default ChatWithUs;
