// src/components/editModals/PieChartWidgetModal.js
import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Checkbox,
  HStack,
  Button,
  Box,
  SimpleGrid,
  Text,
  useColorModeValue,
  Heading,
  Divider,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import DataSourceSection from './DataSourceSection';


export default function PieChartWidgetModal({ widget, onSave, showPreview }) {
  const [config, setConfig] = useState({
    ...widget.config,
    data_sources: widget.config?.data_sources || []
  });
  const [previewData, setPreviewData] = useState([]);
  const borderColor = useColorModeValue('white', 'whiteAlpha.100');

  useEffect(() => {
    // Generate sample data for preview
    const sampleData = [
      { name: 'Category A', value: 400 },
      { name: 'Category B', value: 300 },
      { name: 'Category C', value: 200 },
      { name: 'Category D', value: 100 },
    ];
    setPreviewData(sampleData);
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const handleChange = (e, nestedKey = null) => {
    const { name, value, type, checked } = e.target;
    setConfig(prevConfig => ({
      ...prevConfig,
      [nestedKey ? `${nestedKey}.${name}` : name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDataSourceChange = (index, field, value) => {
    const newDataSources = [...config.data_sources];
    newDataSources[index] = { ...newDataSources[index], [field]: value };
    setConfig({ ...config, data_sources: newDataSources });
  };

  const addDataSource = () => {
    setConfig(prevConfig => ({
      ...prevConfig,
      data_sources: [...(prevConfig.data_sources || []), {}]
    }));
  };

  const removeDataSource = (index) => {
    setConfig(prevConfig => ({
      ...prevConfig,
      data_sources: prevConfig.data_sources.filter((_, i) => i !== index)
    }));
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="100%">
      {showPreview && (
        <Box height="600px" display="flex" alignItems="center" flexDirection="column">
          <Text mb={4} fontStyle="italic" fontSize="lg" textAlign="center">
            Preview
          </Text>
          <Box flex="1" width="100%" display="flex" justifyContent="center" alignItems="center">
            <ResponsiveContainer width="80%" height="80%">
              <PieChart>
                <Pie
                  data={previewData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {previewData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      )}
      <Box overflowY="auto" maxHeight="600px" pr={4}>
        <VStack spacing={6} align="stretch" width="100%">
          <DataSourceSection config={config} setConfig={setConfig} borderColor={borderColor} />


          <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
            <Heading size="sm" mb={3}>Chart Settings</Heading>
            <SimpleGrid columns={2} spacing={4}>
              <FormControl>
                <FormLabel fontWeight="medium">Inner Radius</FormLabel>
                <Select
                  name="innerRadius"
                  value={config.chart_settings?.innerRadius || ''}
                  onChange={(e) => handleChange(e, 'chart_settings')}
                  bg="white"
                >
                  <option value="0">0% (Full Pie)</option>
                  <option value="0.25">25%</option>
                  <option value="0.5">50% (Donut)</option>
                  <option value="0.75">75%</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="medium">Outer Radius</FormLabel>
                <Select
                  name="outerRadius"
                  value={config.chart_settings?.outerRadius || ''}
                  onChange={(e) => handleChange(e, 'chart_settings')}
                  bg="white"
                >
                  <option value="80%">80%</option>
                  <option value="90%">90%</option>
                  <option value="100%">100%</option>
                </Select>
              </FormControl>
            </SimpleGrid>
          </Box>

          <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
            <Heading size="sm" mb={3}>Display Options</Heading>
            <VStack spacing={3} align="start">
              <Checkbox
                name="showLabels"
                isChecked={config.chart_settings?.showLabels}
                onChange={(e) => handleChange(e, 'chart_settings')}
              >
                Show Labels
              </Checkbox>
              <Checkbox
                name="showLegend"
                isChecked={config.chart_settings?.showLegend}
                onChange={(e) => handleChange(e, 'chart_settings')}
              >
                Show Legend
              </Checkbox>
              <Checkbox
                name="showPercentage"
                isChecked={config.chart_settings?.showPercentage}
                onChange={(e) => handleChange(e, 'chart_settings')}
              >
                Show Percentage
              </Checkbox>
            </VStack>
          </Box>
          <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
            <Heading size="sm" mb={3}>Data Refresh</Heading>
            <FormControl>
              <FormLabel fontWeight="medium">Refresh Rate</FormLabel>
              <Select
                name="refresh_rate"
                value={config.refresh_rate || ''}
                onChange={handleChange}
                bg="white"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </Select>
            </FormControl>
          </Box>
        </VStack>
      </Box>
    </SimpleGrid>

  );
}
