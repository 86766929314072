import React, { useEffect, useState } from 'react';
import { Box, Input, Text } from '@chakra-ui/react';

export default function PriceEditor(props) {
    const { updatePrice } = props;

    const [price, setPrice] = useState('');
    const [error, setError] = useState(null);

    const handlePriceChange = (event) => {
        const newPrice = event.target.value;

        // Validate the new price
        if (isNaN(newPrice) || newPrice < 0) {
            setError('Invalid price. Please enter a positive number.');
        } else {
            setError(null);
            updatePricingDetails(newPrice);
        }
    };

    const updatePricingDetails = (newPrice) => {
        setPrice(newPrice);
        updatePrice(price);
    };

    return (
        <Box>
            <Input
                value={price}
                onChange={handlePriceChange}
                placeholder="Enter price..."
                bg="transparent"
                border="1px solid"
                borderRadius="10px"
                p="10px"
                autoFocus
            />
            {error && <Text color="red.500">{error}</Text>}
        </Box>
    );
}