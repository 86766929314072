import { Button, Flex, Select } from "@chakra-ui/react"
import PriceEditor from "components/editors/PriceEditor"

export const BulkEditPrice = (props) => {
    const { setBulkEditPrice } = props;

    return <Flex direction="row">
        <PriceEditor updatePrice={(newPrice) => setBulkEditPrice(newPrice)} />
        <Flex direction="column" ms="10px" >
            <Select
                fontSize="sm"
                id="currency"
                variant="main"
                h="44px"
                maxH="44px"
                me="20px"
                defaultValue="usd"
            >
                <option value="usd">CAD</option>
                {/* <option value="eur">EUR</option>
                <option value="gbp">GBP</option> */}
            </Select>
        </Flex>
    </Flex>
}

export const BulkEditPriceFooter = (props) => {
    const { onBulkEditClose, onPreviewBulkEditPrice } = props;

    return <>
        <Button variant='ghost' onClick={onBulkEditClose}>Discard</Button>
        <Button colorScheme='brand' mr={3} onClick={onPreviewBulkEditPrice}>
            Preview changes
        </Button>
    </>
}