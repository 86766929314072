/*eslint-disable */
import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);


// Get a reference to the Firebase services
export const functions = getFunctions(app);
// Connect to the Firebase Functions Emulator
if (process.env.REACT_APP_IS_DEVELOPMENT == 'true') {
	connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const auth = getAuth(app);
if (process.env.REACT_APP_IS_DEVELOPMENT === 'true') {
	connectAuthEmulator(auth, 'http://localhost:9099');
}

export const firestore = getFirestore(app);
// Connect to the Firestore Emulator
if (process.env.REACT_APP_IS_DEVELOPMENT === 'true') {
	connectFirestoreEmulator(firestore, 'localhost', 8081);
}

export const storage = getStorage(app);