import React from "react";
import { Box, Heading, SimpleGrid, Text, VStack, HStack, Badge } from "@chakra-ui/react";
import Card from "components/card/Card";
import BarChart from "components/charts/BarChart";
import LineChart from "components/charts/LineChart";
import AIInsight from "components/AIInsight";

// Mock data
const engagementScoreData = [
    { segment: "High Value", online: 85, inStore: 75, mobile: 90 },
    { segment: "Medium Value", online: 70, inStore: 65, mobile: 75 },
    { segment: "Low Value", online: 55, inStore: 50, mobile: 60 },
    { segment: "New Customers", online: 65, inStore: 60, mobile: 70 },
];

const topEngagementDrivers = [
    { driver: "Personalized Product Recommendations", score: 85 },
    { driver: "Limited Edition Releases", score: 80 },
    { driver: "Nike Run Club App", score: 78 },
    { driver: "Sustainability Initiatives", score: 75 },
    { driver: "Loyalty Program Rewards", score: 72 },
];

const engagementTrendsData = [
    { month: "Jan", appUsage: 65, storeVisits: 40, websiteVisits: 80 },
    { month: "Feb", appUsage: 68, storeVisits: 42, websiteVisits: 82 },
    { month: "Mar", appUsage: 72, storeVisits: 45, websiteVisits: 85 },
    { month: "Apr", appUsage: 75, storeVisits: 48, websiteVisits: 88 },
    { month: "May", appUsage: 80, storeVisits: 50, websiteVisits: 90 },
    { month: "Jun", appUsage: 85, storeVisits: 55, websiteVisits: 95 },
];

const EngagementAnalytics = () => {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <AIInsight message="User engagement with the mobile app has spiked by 20%. The new feature rollout is driving increased daily active users." />

            <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap="20px" mb="20px">
                <Card>
                    <Heading size="md" mb="4">
                        Engagement Score Distribution
                    </Heading>
                    <BarChart
                        chartData={[
                            {
                                name: "Online",
                                data: engagementScoreData.map((item) => item.online),
                            },
                            {
                                name: "In-Store",
                                data: engagementScoreData.map((item) => item.inStore),
                            },
                            {
                                name: "Mobile",
                                data: engagementScoreData.map((item) => item.mobile),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: engagementScoreData.map((item) => item.segment),
                            },
                            yaxis: {
                                title: {
                                    text: "Engagement Score",
                                },
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    columnWidth: "55%",
                                    endingShape: "rounded",
                                },
                            },
                        }}
                    />
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Top Engagement Drivers
                    </Heading>
                    <VStack align="stretch" spacing={4}>
                        {topEngagementDrivers.map((driver, index) => (
                            <HStack key={index} justify="space-between">
                                <Text>{driver.driver}</Text>
                                <Badge colorScheme="blue" fontSize="1em" p={2} borderRadius="full">
                                    Score: {driver.score}
                                </Badge>
                            </HStack>
                        ))}
                    </VStack>
                </Card>
                <Card>
                    <Heading size="md" mb="4">
                        Engagement Trends
                    </Heading>
                    <LineChart
                        chartData={[
                            {
                                name: "App Usage",
                                data: engagementTrendsData.map((item) => item.appUsage),
                            },
                            {
                                name: "Store Visits",
                                data: engagementTrendsData.map((item) => item.storeVisits),
                            },
                            {
                                name: "Website Visits",
                                data: engagementTrendsData.map((item) => item.websiteVisits),
                            },
                        ]}
                        chartOptions={{
                            xaxis: {
                                categories: engagementTrendsData.map((item) => item.month),
                            },
                            yaxis: {
                                title: {
                                    text: "Engagement Score",
                                },
                            },
                            markers: {
                                size: 4,
                            },
                            legend: {
                                position: "top",
                            },
                        }}
                    />
                </Card>
            </SimpleGrid>
        </Box>
    );
};

export default EngagementAnalytics;
