import { Button, ButtonGroup, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import CategotiesRowAdapter from "views/overviewProduct/uiAdapters/CategoriesRowAdapter";

export const PreviewCategories = (props) => {
    const { updatedItems, selectedItemIdsToObject } = props;
    return <Table variant="simple">
        <Thead>
            <Tr>
                <Th>Name</Th>
                <Th>Before</Th>
                <Th>After</Th>
            </Tr>
        </Thead>
        <Tbody>
            {updatedItems.map((item, index) => (
                <CategotiesRowAdapter
                    item={item}
                    index={index}
                    selectedItemIdsToObject={selectedItemIdsToObject}
                />
            ))}
        </Tbody>
    </Table>
}

export const Footer = (props) => {
    const { onPreviewCategoriesSubmit, onPreviewCategoriesDiscard } = props;
    return <ButtonGroup>
        <Button variant='ghost' onClick={onPreviewCategoriesDiscard}>Discard</Button>
        <Button colorScheme='brand' mr={3} onClick={onPreviewCategoriesSubmit} disabled >
            Save Changes
        </Button>
    </ButtonGroup>
}