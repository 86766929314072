import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CheckboxGroup,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Grid,
    Image,
    List,
    ListItem,
    Radio,
    RadioGroup,
    Stack,
    Text,
    VStack,
    VisuallyHidden
} from "@chakra-ui/react";

import { useState, useEffect } from "react";

import amazon from "assets/img/icons/amazon.webp";
import google from "assets/img/icons/google.svg";
import instagram from "assets/img/icons/instagram.webp";
import magento from "assets/img/icons/magento.svg";
import meta from "assets/img/icons/meta.png";
import salesforce from "assets/img/icons/salesforce.png";
import shopify from "assets/img/icons/shopify.webp";
import woo from "assets/img/icons/woo.png";
import Card from "components/card/Card";
import { logData } from "util/Logger";

export default function ExportProductsDrawer(props) {
    const { isOpen, onClose, columns } = props;
    logData('ExportProductsDrawer', { isOpen, columns });
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('columns');
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [platforms, setPlatforms] = useState([
        { icon: shopify, name: 'Shopify', checked: false },
        { icon: amazon, name: 'Amazon', checked: false },
        { icon: google, name: 'Google', checked: false },
        { icon: instagram, name: 'Instagram', checked: false },
        { icon: magento, name: 'Magento', checked: false },
        { icon: meta, name: 'Meta', checked: false },
        { icon: salesforce, name: 'Salesforce', checked: false },
        { icon: woo, name: 'Woo', checked: false },
    ]);

    const handleCheckChange = (index) => {
        const newPlatforms = [...platforms];
        newPlatforms[index].checked = !newPlatforms[index].checked;
        setPlatforms(newPlatforms);
    }

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        if (option === 'columns') {
            // setSelectedColumns(columns); // replace with actual columns
        } else {
            setPlatforms(platforms.map(platform => ({ ...platform, checked: false })));
        }
    };

    const handleColumnsChange = (columns) => {
        setSelectedColumns(columns);
        logData('handleColumnsChange', columns);
    };

    const getCsvFileNames = () => {
        return platforms.filter(platform => platform.checked).map(platform => `${platform.name}.csv`);
    };

    const onExport = async () => {
        setLoading(true);
        if (selectedOption === 'columns') {
        } else {
            const selectedPlatforms = platforms.filter(platform => platform.checked).map(platform => platform.name);
        }
        setLoading(false);
        onClose();
    };

    useEffect(() => {
        setSelectedColumns(columns);
    }, [columns]);

    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="md"
        >
            <DrawerOverlay />
            <DrawerContent
            >
                <DrawerCloseButton />
                <DrawerHeader>Export Products</DrawerHeader>
                <DrawerBody>
                    <Flex direction='column' justify='center'
                    >
                        <Card direction='column' justify='center'
                            mt={4} p={4} >
                            <RadioGroup onChange={handleOptionChange} value={selectedOption}>
                                <Stack direction="column" spacing={4}>
                                    <Radio value="columns" size="lg" colorScheme="green">Select Columns</Radio>
                                    <Flex align="center" my={2}>
                                        <Box flex="1" height="1px" bg="gray.300" />
                                        <Text px={2} color="gray.500">or</Text>
                                        <Box flex="1" height="1px" bg="gray.300" />
                                    </Flex>
                                    <Radio value="templates" size="lg" colorScheme="green">Select one or more templates</Radio>
                                </Stack>
                            </RadioGroup>
                        </Card>
                        {selectedOption === 'columns' && (
                            <Card
                                p={4}
                                boxShadow="base"
                                borderRadius="lg"
                                bg="gray.50"
                                mt={4}
                            >
                                <Text mt={4} mb={4} fontSize="xl" fontWeight="bold">Select Columns</Text>
                                <CheckboxGroup colorScheme="green" value={selectedColumns} onChange={handleColumnsChange}>

                                    <VStack align="stretch" spacing={2}>
                                        {columns.map((column, index) => (
                                            <Box as="label" borderWidth="1px" borderRadius="lg" p={3} bg="white"
                                                cursor={"pointer"} key={index}>
                                                <Checkbox
                                                    value={column}
                                                    checked={selectedColumns.includes(column)}
                                                >{column}</Checkbox>
                                            </Box>
                                        ))}
                                    </VStack>
                                </CheckboxGroup>
                                <Text mt={4} color="gray.500">This will create one CSV file with the following headers:</Text>
                                <Text mt={2}>{selectedColumns.join(', ')}</Text>
                            </Card>
                        )}
                        {selectedOption === 'templates' && (
                            <Card
                                p={4}
                                boxShadow="base"
                                borderRadius="lg"
                                bg="gray.50"
                                mt={4}>
                                <Text mt={4} mb={4} fontSize="xl" fontWeight="bold">
                                    CSV Templates
                                </Text>
                                <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"]} gap={6} mt={4}>
                                    {platforms.map((platform, index) => (
                                        <Box
                                            as="label"
                                            key={index}
                                            textAlign="center"
                                            borderWidth="1px"
                                            borderRadius="lg"
                                            p={3}
                                            bg={platform.checked ? "green.100" : "white"}
                                            _hover={{ cursor: "pointer", bg: "green.50" }}
                                        >
                                            <VisuallyHidden>
                                                <Checkbox colorScheme="green" size="lg" isChecked={platform.checked} onChange={() => handleCheckChange(index)} />
                                            </VisuallyHidden>
                                            <Image src={platform.icon} alt={platform.name} boxSize="50px" m="auto" objectFit="contain" objectPosition="center" />
                                        </Box>
                                    ))}
                                </Grid>

                                <Text mt={4} color="gray.500">This will create one CSV file for each selected platform</Text>
                                <List mt={2}>
                                    {getCsvFileNames().map((fileName, index) => (
                                        <ListItem key={index}>{fileName}</ListItem>
                                    ))}
                                </List>
                            </Card>
                        )}
                    </Flex>
                </DrawerBody>

                <DrawerFooter>
                    <Flex
                        direction='column'
                    >

                        <Alert status="info" mt={4}
                            direction='column' justify='center'
                        >
                            <AlertIcon />
                            <Flex direction='column' justify='center'>
                                {/* <AlertTitle>Export in progress</AlertTitle> */}
                                <AlertDescription>

                                    We will send you an email with a link to download the exported files once the export is complete.

                                </AlertDescription>
                            </Flex>
                        </Alert>
                        <ButtonGroup mt={4} spacing={2}
                            alignSelf={"flex-end"}
                        >
                            <Button variant="outline" mr={3} onClick={onClose} disabled={loading} >
                                Cancel
                            </Button>
                            <Button colorScheme="brand"
                                onClick={onExport}
                                isLoading={loading}
                                disabled={loading}
                            >Export</Button>
                        </ButtonGroup>
                    </Flex>


                </DrawerFooter>
            </DrawerContent>
        </Drawer >
    );
}