import { Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberInput, NumberInputField, SimpleGrid, Text } from "@chakra-ui/react";
import TagsField from "components/fields/TagsField";
import { useState } from "react";

export default function ProductFilterModal({
    isOpen,
    onClose,
    filters,
    setFilters,
    onApplyFilters,
    setFilterActive,
}) {
    const [tags, setTags] = useState(filters.tags);

    const tagsChange = (value) => {
        setTags(value);
        setFilters({ ...filters, tags: value });
    };

    const handleApplyFilters = () => {
        onApplyFilters(filters);
        onClose();
    };

    const handleResetFilters = () => {
        setTags([]);
        setFilterActive(false);
        setFilters({
            name: '',
            minPrice: '',
            maxPrice: '',
            category: '',
            tags: [],
            vendor: '',
        });
        onApplyFilters({
            name: '',
            minPrice: '',
            maxPrice: '',
            category: '',
            tags: [],
            vendor: '',
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Filter Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <SimpleGrid columns={2} spacing={4}>
                        <Text>Min Price</Text>
                        <NumberInput
                            value={filters.minPrice}
                            onChange={(value) => setFilters(prev => ({ ...prev, minPrice: value }))}
                        >
                            <NumberInputField placeholder="Min Price" />
                        </NumberInput>

                        <Text>Max Price</Text>
                        <NumberInput
                            value={filters.maxPrice}
                            onChange={(value) => setFilters(prev => ({ ...prev, maxPrice: value }))}
                        >
                            <NumberInputField placeholder="Max Price" />
                        </NumberInput>

                        <Text>Tags</Text>
                        <TagsField
                            id="tags"
                            label="Tags"
                            m="0px"
                            value={tags}
                            showLabel={false}
                            onChange={tagsChange}
                            placeholderTags={tags}
                            key={filters.tags.join(',')} // Add this line

                            info="Use comma to separate tags" // todo this uses custom labels in Content API
                        />

                        <Text>Vendor</Text>
                        <Input
                            value={filters.vendor}
                            onChange={(e) => setFilters(prev => ({ ...prev, vendor: e.target.value }))}
                            placeholder="Enter vendor"
                        />
                    </SimpleGrid>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="red.700"
                        variant="transparent"
                        onClick={handleResetFilters}
                    >
                        Reset Filters
                    </Button>
                    <Button colorScheme="blue" mr={3} onClick={handleApplyFilters}>
                        Apply Filters
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
