// Chakra imports
import { InfoIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormLabel,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
// Custom components
import { useState } from 'react';
import { logData } from 'util/Logger';

function TagsField(props) {
  const { label, id, placeholderTags, onChange, showLabel = true, ...rest } = props;
  let initialTags = [];
  logData('placeholderTags:', placeholderTags);

  if (placeholderTags && Array.isArray(placeholderTags)) {
    initialTags = placeholderTags.map((tag, index) => {
      return {
        name: tag,
        id: index + 1,
      };
    }
    );
  }
  const [tags, setTags] = useState(initialTags);

  const handleTagsChange = (tags) => {
    setTags(tags);
    if (onChange) {
      onChange(tags.map((tag) => tag.name));
    }
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleTagsChange([
        ...tags,
        {
          name: e.target.value,
          id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1,
        },
      ]);
      e.target.value = '';
    }
  };

  let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  let bg = props.pillBg || 'brand.400';
  return (
    <>
      {showLabel && (
        <FormLabel htmlFor={id} fontWeight="bold" fontSize="sm" mb="8px" ms="8px" >
          {label}{" "}{props.info &&
            <Icon as={InfoIcon} fontSize="sm" color="secondaryGray.500" ms="2px" />}
        </FormLabel>
      )}
      <Flex
        direction="row"
        p="12px"
        wrap="wrap"
        bg="gray.100"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="10px"
        _focus={{ borderColor: 'teal.300' }}
        minH="40px"
        h="stretch"
        cursor="text"
        {...rest}
      >
        {tags.map((tag, index) => {
          return (
            <Tag
              fontSize="xs"
              h="25px"
              mb="6px"
              me="6px"
              borderRadius="10px"
              variant="solid"
              bg={bg}
              key={index}
            >
              <TagLabel w="100%">{tag.name}</TagLabel>
              <TagCloseButton
                justifySelf="flex-end"
                color="white"
                onClick={() =>
                  handleTagsChange([...tags.filter((element) => element.id !== tag.id)])
                }
              />
            </Tag>
          );
        })}
        <Input
          variant="main"
          bg="transparent"
          border="none"
          p="0px"
          onKeyDown={(e) => keyPress(e)}
          fontSize="sm"
        />
      </Flex>
    </>
  );
}

export default TagsField;
