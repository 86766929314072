// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';

export default function Information(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';
  const borderColor = useColorModeValue('white', 'whiteAlpha.100');

  // Chakra Color Mode
  return (
    <FormControl>
      <Card
        borderRadius={"5px"}
        border="1px solid"
        borderColor={borderColor}
        boxShadow='sm'
        mb="20px" {...rest}>
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Account Settings
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Here you can change user account information
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <InputField
            mb="0px"
            me="30px"
            id="username"
            label="Username"
            placeholder="@simmmple.web"
          />
          <InputField
            mb="0px"
            id="email"
            label="Email Address"
            placeholder="mail@simmmple.com"
          />
          <InputField
            mb="20px"
            me="30px"
            id="first_name"
            label="First Name"
            placeholder="John"
          />
          <InputField
            mb="20px"
            id="last_name"
            label="Last Name"
            placeholder="William"
          />
        </SimpleGrid>
        <InputField id="job" label="Job" placeholder="Web Developer" />
        <TextField
          id="about"
          label="About Me"
          h="100px"
          placeholder="Tell something about yourself in 150 characters!"
        />
      </Card>
    </FormControl>
  );
}
