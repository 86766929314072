// src/components/editModals/ScatterPlotWidgetModal.js
import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Checkbox,
    HStack,
    Button,
} from "@chakra-ui/react";

export default function ScatterPlotWidgetModal({ widget, onSave }) {
    const [config, setConfig] = useState(widget.config || {});

    const handleChange = (e, nestedKey = null) => {
        const { name, value, type, checked } = e.target;
        setConfig(prevConfig => ({
            ...prevConfig,
            [nestedKey ? `${nestedKey}.${name}` : name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleDataSourceChange = (index, field, value) => {
        const newDataSources = [...config.data_sources];
        newDataSources[index] = { ...newDataSources[index], [field]: value };
        setConfig({ ...config, data_sources: newDataSources });
    };

    const addDataSource = () => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: [...(prevConfig.data_sources || []), {}]
        }));
    };

    const removeDataSource = (index) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: prevConfig.data_sources.filter((_, i) => i !== index)
        }));
    };

    return (
        <VStack spacing={4} align="stretch">
            {/* Data Sources */}
            {config.data_sources?.map((dataSource, index) => (
                <VStack key={index} spacing={2} align="stretch" borderWidth={1} borderRadius="md" p={2}>
                    <FormControl>
                        <FormLabel>Data Source {index + 1}</FormLabel>
                        <Input
                            value={dataSource.source}
                            onChange={(e) => handleDataSourceChange(index, 'source', e.target.value)}
                            placeholder="Source name"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Account ID</FormLabel>
                        <Input
                            value={dataSource.account_id}
                            onChange={(e) => handleDataSourceChange(index, 'account_id', e.target.value)}
                            placeholder="Account ID"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>X Metric</FormLabel>
                        <Input
                            value={dataSource.x_metric}
                            onChange={(e) => handleDataSourceChange(index, 'x_metric', e.target.value)}
                            placeholder="X Metric"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Y Metric</FormLabel>
                        <Input
                            value={dataSource.y_metric}
                            onChange={(e) => handleDataSourceChange(index, 'y_metric', e.target.value)}
                            placeholder="Y Metric"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Size Metric</FormLabel>
                        <Input
                            value={dataSource.size_metric}
                            onChange={(e) => handleDataSourceChange(index, 'size_metric', e.target.value)}
                            placeholder="Size Metric"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Aggregation</FormLabel>
                        <Select
                            value={dataSource.aggregation}
                            onChange={(e) => handleDataSourceChange(index, 'aggregation', e.target.value)}
                        >
                            <option value="sum">Sum</option>
                            <option value="average">Average</option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Breakdown</FormLabel>
                        <Input
                            value={dataSource.breakdown}
                            onChange={(e) => handleDataSourceChange(index, 'breakdown', e.target.value)}
                            placeholder="Breakdown"
                        />
                    </FormControl>
                    <Button onClick={() => removeDataSource(index)} colorScheme="red">Remove Data Source</Button>
                </VStack>
            ))}
            <Button onClick={addDataSource} colorScheme="blue">Add Data Source</Button>

            {/* Chart Settings */}
            <FormControl>
                <FormLabel>Colors</FormLabel>
                {config.data_sources?.map((dataSource, index) => (
                    <HStack key={index}>
                        <Input
                            value={config.chart_settings?.colors?.[dataSource.source] || ''}
                            onChange={(e) => handleChange({
                                target: {
                                    name: `colors.${dataSource.source}`,
                                    value: e.target.value
                                }
                            }, 'chart_settings')}
                            placeholder={`Color for ${dataSource.source}`}
                        />
                    </HStack>
                ))}
            </FormControl>

            <Checkbox
                name="show_legend"
                isChecked={config.chart_settings?.show_legend}
                onChange={(e) => handleChange(e, 'chart_settings')}
            >
                Show Legend
            </Checkbox>

            <FormControl>
                <FormLabel>Tooltip Format</FormLabel>
                <Select
                    name="tooltip_format"
                    value={config.chart_settings?.tooltip_format || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                >
                    <option value="absolute_value">Absolute Value</option>
                    <option value="percentage">Percentage</option>
                </Select>
            </FormControl>

            <Checkbox
                name="show_trendline"
                isChecked={config.chart_settings?.show_trendline}
                onChange={(e) => handleChange(e, 'chart_settings')}
            >
                Show Trendline
            </Checkbox>

            <FormControl>
                <FormLabel>X Axis Label</FormLabel>
                <Input
                    name="x_axis_label"
                    value={config.chart_settings?.x_axis_label || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="X Axis Label"
                />
            </FormControl>

            <FormControl>
                <FormLabel>Y Axis Label</FormLabel>
                <Input
                    name="y_axis_label"
                    value={config.chart_settings?.y_axis_label || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="Y Axis Label"
                />
            </FormControl>

            <FormControl>
                <FormLabel>Size Legend Label</FormLabel>
                <Input
                    name="size_legend_label"
                    value={config.chart_settings?.size_legend_label || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="Size Legend Label"
                />
            </FormControl>

            {/* Refresh Rate */}
            <FormControl>
                <FormLabel>Refresh Rate</FormLabel>
                <Select
                    name="refresh_rate"
                    value={config.refresh_rate || ''}
                    onChange={handleChange}
                >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                </Select>
            </FormControl>
        </VStack>
    );
}
