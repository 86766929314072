import { Button } from "@chakra-ui/react";
import TagsField from "components/fields/TagsField"

export const BulkEditCategories = (props) => {
    const { data, setTags } = props;

    return <TagsField
        id="categories"
        label=""
        mb="0px"
        onChange={(newTags) => setTags(newTags)}
        placeholderTags={data}
        tags={data}
    />
}

export const BulkEditCategoriesFooter = (props) => {
    const { onBulkEditClose, onPreviewBulkEditCategories, data } = props;

    return <>
        <Button variant='ghost' onClick={onBulkEditClose}>Discard</Button>
        <Button colorScheme='brand' mr={3} onClick={() => onPreviewBulkEditCategories(data)}>
            Preview changes
        </Button>
    </>
}