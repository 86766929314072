import {
    Flex,
    Icon,
    SimpleGrid,
    Text,
    UnorderedList,
    useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ReactComponent as ProductIcon } from 'assets/img/icons/product.svg';
import ListItem from 'components/widgets/list/ListItem';
import { FaCheckCircle, FaEllipsisH } from 'react-icons/fa';
import UpgradeToPro from 'components/modals/UpgradeToPro';

export default function ProductUpsell() {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';

    return (
        <Card
            bg="secondaryGray.100"
            boxShadow="base"
            borderRadius="10px">
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing="40px" py="20px">
                <Flex direction="column" ms="10px" align="center">
                    <Icon as={ProductIcon} boxSize="50px" mb="20px" />
                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold" mb="20px" textAlign={"center"}>
                        Enhance Your Product Management
                    </Text>
                    <UpgradeToPro source="Enhance Your Product Management" />
                </Flex>
                <UnorderedList spacing={4} px="10px">
                    <ListItem
                        icon={FaCheckCircle}
                        title="Manage up to 10,000 SKUs"
                        showBadge={false}
                    />
                    <ListItem
                        icon={FaCheckCircle}
                        title="Advanced Product Attributes"
                        showBadge={false}
                    />
                    <ListItem
                        icon={FaCheckCircle}
                        title="Multi-channel Inventory Sync"
                        showBadge={false}
                    />
                    <ListItem
                        icon={FaEllipsisH}
                        title="+ more"
                        showBadge={false}
                    />
                </UnorderedList>
            </SimpleGrid>
        </Card>
    );
}
