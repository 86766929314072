import { HStack, Text } from "@chakra-ui/react";
import Card from 'components/card/Card';
import { FaBolt, FaShopify, FaFacebook, FaGoogle } from "react-icons/fa";
import { IoBarChart } from "react-icons/io5";
import { MdDelete, MdEdit } from "react-icons/md";

const CustomNode = ({ data }) => (
    <div style={{ position: 'relative' }}>
        {data.label}
        <div style={{ position: 'absolute', top: 0, right: -30, display: 'flex', flexDirection: 'column' }}>
            <MdEdit size={20} onClick={() => data.onEdit(data.id)} />
            <MdDelete size={20} onClick={() => data.onDelete(data.id)} />
        </div>
    </div>
);


export const nodes = [
    {
        id: 'crm-automation-trigger',
        type: 'input',
        data: {
            label: <Card
                bg='#FF6B6B'
                px='10px'
                py='5px'
                borderRadius='5px'
                color='secondaryGray.900'
                fontWeight='500'
            >
                <HStack spacing='10px'>
                    <FaBolt color='white' size='20px' />
                    <Text color='white'>CRM Automation Trigger</Text>
                </HStack>
            </Card>,
            options: {
                triggerType: 'new_lead',
                leadSource: 'all',
                delay: 0,
                conditions: []
            },
            onEdit: (id) => {/* Function to handle editing */ },
            onDelete: (id) => {/* Function to handle deletion */ }
        },
        position: { x: 900, y: 50 },
        style: { border: 'none', backgroundColor: '#FF6B6B' },
    }




    // {
    //     id: 'google-ads',
    //     type: 'input',
    //     data: {
    //         label: <Card
    //             bg='#4285F4'
    //             px='10px'
    //             py='5px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='10px'>
    //                 <FaGoogle color='white' size='20px' />
    //                 <Text color='white'>Google Ads</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 100, y: 50 },
    //     style: { border: 'none', backgroundColor: '#4285F4' },
    // },
    // {
    //     id: 'google-analytics',
    //     type: 'input',
    //     data: {
    //         label: <Card
    //             bg='#E37400'
    //             px='10px'
    //             py='5px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='10px'>
    //                 <IoBarChart color='white' size='20px' />
    //                 <Text color='white'>Google Analytics 4</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 300, y: 50 },
    //     style: { border: 'none', backgroundColor: '#E37400' },
    // },
    // {
    //     id: 'shopify',
    //     type: 'input',
    //     data: {
    //         label: <Card
    //             bg='#96BF48'
    //             px='10px'
    //             py='5px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='10px'>
    //                 <FaShopify color='white' size='20px' />
    //                 <Text color='white'>Shopify</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 500, y: 50 },
    //     style: { border: 'none', backgroundColor: '#96BF48' },
    // },
    // {
    //     id: 'facebook-ads',
    //     type: 'input',
    //     data: {
    //         label: <Card
    //             bg='#1877F2'
    //             px='10px'
    //             py='5px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='10px'>
    //                 <FaFacebook color='white' size='20px' />
    //                 <Text color='white'>Facebook Ads</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 700, y: 50 },
    //     style: { border: 'none', backgroundColor: '#1877F2' },
    // },
    // {
    //     id: 'a',
    //     type: 'output',
    //     data: {
    //         label: <Card
    //             bg='transparent'
    //             px='5px'
    //             py='0px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='5px'>
    //                 <FaDatabase color='brand' size='80px' />
    //                 <Text color='brand' fontWeight={'bold'}>High-Value Clicks DB</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 200, y: 200 },
    //     style: { border: 'none', backgroundColor: 'transparent' },
    // },
    // {
    //     id: 'b',
    //     type: 'output',
    //     data: {
    //         label: <Card
    //             bg='transparent'
    //             px='5px'
    //             py='0px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='5px'>
    //                 <FaDatabase color='brand' size='80px' />
    //                 <Text color='brand' fontWeight={'bold'}>Recent Conversions DB</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 400, y: 200 },
    //     style: { border: 'none', backgroundColor: 'transparent' },
    // },
    // {
    //     id: 'c',
    //     type: 'output',
    //     data: {
    //         label: <Card
    //             bg='transparent'
    //             px='5px'
    //             py='0px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='5px'>
    //                 <FaDatabase color='brand' size='80px' />
    //                 <Text color='brand' fontWeight={'bold'}>Frequent Shoppers DB</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 600, y: 200 },
    //     style: { border: 'none', backgroundColor: 'transparent', padding: '0px' },
    // },
    // {
    //     id: 'd',
    //     type: 'output',
    //     data: {
    //         label: <Card
    //             bg='transparent'
    //             px='5px'
    //             py='0px'
    //             borderRadius='5px'
    //             color='secondaryGray.900'
    //             fontWeight='500'
    //         >
    //             <HStack spacing='5px'>
    //                 <FaDatabase color='brand' size='80px' />
    //                 <Text color='brand' fontWeight={'bold'}>High-Value Purchases DB</Text>
    //             </HStack>
    //         </Card>
    //     },
    //     position: { x: 400, y: 350 },
    //     style: { border: 'none', backgroundColor: 'transparent' },
    // },
];

export default nodes;
