import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";

export default function UnsavedChangesModal(props) {
    const { isConfirmOpen, onConfirmClose, discardChanges } = props;

    return (
        <Modal
            isOpen={isConfirmOpen}
            onClose={onConfirmClose}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Unsaved Changes</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    You have unsaved changes. Are you sure you want to leave?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='brand' mr={3} onClick={onConfirmClose}>
                        Stay
                    </Button>
                    <Button variant='ghost' onClick={discardChanges}>Leave</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}