import Card from 'components/card/Card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SpendTrackingChart = ({ data }) => (
    <Card>
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
                <XAxis dataKey="date" />
                <YAxis />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="spend" stroke="#001842" />
            </LineChart>
        </ResponsiveContainer>
    </Card>
);

export default SpendTrackingChart;