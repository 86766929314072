import { Flex, Text, useColorModeValue, Badge, Image, Box } from "@chakra-ui/react";
import Shopify from '../../../assets/img/icons/shopify.webp';
import Amazon from '../../../assets/img/icons/amazon.webp';

export function DisplayOnlyRowAdapter({ cell, column }) {
    const textColor = useColorModeValue("secondaryGray.900", "white");

    if (column.Header === 'ID' || column.Header === 'id') {
        return (
            <Text color={textColor} fontSize='xs'>
                {cell.value}
            </Text>
        );
    }

    if (cell.column.Header.toLowerCase() === 'categories' && Array.isArray(cell.value)) {
        cell.value.map((category, index) => (
            <Badge colorScheme='blue' key={index} me='1' mt='1'>
                {category}
            </Badge>
        ));
    }


    if (column.Header.toLowerCase() === 'name') {
        return (
            <Text color={textColor} fontSize='sm' isTruncated>
                {cell.value}
            </Text>
        );
    }

    if (column.Header.toLowerCase() === 'description') {
        return (
            <Text color={textColor} fontSize='sm' isTruncated>
                {cell.value}
            </Text>
        );
    }

    if (column.Header.toLowerCase() === 'categories') {
        cell.value.map((category, index) => (
            <Badge colorScheme='blue' key={index} fontSize='xs' me='1' mt='1'>
                {category}
            </Badge>
        ));
    }

    if (column.Header.toLowerCase() === 'status') {
        switch (cell.value?.toLowerCase()) {
            case 'pending':
                return <Badge colorScheme='yellow'>Pending</Badge>;
            case 'processing':
                return <Badge colorScheme='blue'>Processing</Badge>;
            case 'delivered':
            case 'active':
            case 'approved':
                return <Badge colorScheme='green'>{cell.value}</Badge>;
            case 'rejected':
            case 'cancelled':
                return <Badge colorScheme='red'>{cell.value}</Badge>;
            case 'shipped':
                return <Badge colorScheme='purple'>Shipped</Badge>;
            default:
                return <Badge colorScheme='gray'>{cell.value}</Badge>;
        }
    }

    if (column.Header.toLowerCase() === 'priority') {
        switch (cell.value.toLowerCase()) {
            case 'high':
                return <Badge colorScheme='red'>{cell.value}</Badge>;
            case 'medium':
                return <Badge colorScheme='yellow'>{cell.value}</Badge>;
            default:
                return <Badge colorScheme='gray'>{cell.value}</Badge>;
        }
    }

    if (column.Header.toLowerCase() === 'date') {
        return (
            <Badge colorScheme='gray' fontWeight='normal'>{cell.value}</Badge>
        );
    }

    if (column.Header.toLowerCase() === 'images') {
        const images = cell.value;
        return (
            <Flex>
                {images.map((image, index) => (
                    <Image
                        key={index}
                        src={image}
                        w="36px"
                        h="36px"
                        me={index === images.length - 1 ? '0' : '8px'}
                        borderRadius="10px"
                    />
                ))}
                {images.length > 3 && <Text color={textColor} fontSize="sm" ml="8px">+{images.length - 3}</Text>}
            </Flex>
        );
    }

    if (column.Header.toLowerCase() === 'channel') {
        let logo;
        switch (cell.value.toLowerCase()) {
            case 'shopify':
                logo = <Image src={Shopify} />
                break;
            case 'amazon':
                logo = <Image src={Amazon} />;
                break;
            default:
                logo = null;
        }
        return logo ? (
            <Badge colorScheme='gray' >
                <Flex align='center'>
                    <Box w='20px' me='5px'>
                        {logo}
                    </Box>
                    <Text color={textColor} fontSize='xs'>{cell.value}</Text>
                </Flex>
            </Badge>

        ) : <Badge colorScheme='gray'>{cell.value}</Badge>;

    }

    if (column.Header.toLowerCase() === 'customer') {
        return (
            <Text color={textColor} fontSize='sm' isTruncated>
                {cell.value}
            </Text>
        );
    }

    if (column.Header.toLowerCase() === 'product') {
        return (
            <Text color={textColor} fontSize='sm' isTruncated>
                {cell.value}
            </Text>
        );
    }

    if (column.Header.toLowerCase() === 'revenue') {
        return (
            <Text color={textColor} fontSize='md'>
                {cell.value}
            </Text>
        );
    }

    if (cell.column.Header === 'rating') {
        return (
            <Flex>
                <Text
                    me="6px"
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                >
                    {cell.value[0]}
                </Text>
                <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="400"
                >
                    ({cell.value[1]} votes)
                </Text>
            </Flex>
        );
    }

    if (cell.column.Header === 'price' || cell.column.Header === 'amount') {
        return (
            <Text
                color={'green.500'}
                fontSize="sm"
                fontWeight="600"
            >
                {cell.value}
            </Text>
        );
    }

    if (cell.column.Header === 'sales') {
        return (
            <Text color={textColor} fontSize="md" fontWeight="500">
                {cell.value}
            </Text>
        );
    }

    if (cell.column.Header.toLowerCase() === 'customer') {
        return (
            <Text color={textColor} fontSize='sm' fontWeight="700">
                {cell.value}
            </Text>
        );
    }

    if (cell.column.Header.toLowerCase() === 'name' && Array.isArray(cell.value)) {
        return (
            <Flex align="center">
                <Image
                    src={cell.value[2]}
                    w="36px"
                    h="36px"
                    me="8px"
                    borderRadius="10px"
                />
                <Flex direction="column">
                    <Text
                        color={textColor}
                        fontSize="sm"
                        fontWeight="700"
                    >
                        {cell.value[0]}
                    </Text>
                    <Text
                        color="secondaryGray.500"
                        fontSize="sm"
                        fontWeight="600"
                    >
                        {cell.value[1]}
                    </Text>
                </Flex>
            </Flex>
        );
    }

    if (cell.column.Header === 'metadata') {
        return (
            <Flex align="center">
                <Text
                    color={textColor}
                    fontSize="sm"
                    fontWeight="700"
                    me="8px"
                >
                    None
                </Text>
            </Flex>
        );

    }

    // For all other columns, render the cell value as text
    return (
        <Text color={textColor} fontSize='md'>
            {cell.value}
        </Text>
    );
}