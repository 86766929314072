import React, { useContext, useEffect, useState } from "react";
import { Box, MenuList, MenuItem, FormLabel, FormControl, CircularProgress, Flex, Heading, Icon, SimpleGrid, Text, IconButton, useColorModeValue, useDisclosure, Alert, AlertTitle, AlertDescription, HStack, VStack, Divider, Menu, Badge, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Select, ModalFooter, Input, MenuButton, UnorderedList, ListItem, Progress, AlertIcon, Stat, StatLabel, StatNumber, StatHelpText, StatArrow } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { WidgetFactoryContext } from "contexts/WidgetFactoryContext";
import { AuthContext } from "contexts/AuthContext";
import { log } from "util/Logger";
import OrderDetailsComponent from "components/orderDetails";
import QuickActions from "components/orderDetails/components/QuickActions";
import DrawerFactory from "components/drawers/DrawerFactory";
import { logData } from "util/Logger";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { IoMdAlert, IoMdCart, IoMdPeople, IoMdTime, IoMdTrendingUp, IoMdInformationCircle, IoIosApps, IoIosConstruct } from "react-icons/io";
import { MdAdd, MdMoreHoriz, MdEdit, MdShare, MdDelete, MdContentCopy, MdAddShoppingCart, MdAreaChart, MdBarChart, MdBubbleChart, MdChevronLeft, MdChevronRight, MdFilterAlt, MdGridOn, MdInsertChart, MdOutlineShoppingCart, MdPieChart, MdScatterPlot, MdStars, MdTableChart, MdTimeline, MdWaterfallChart, MdTouchApp, MdShoppingCart } from "react-icons/md";
import Card from "components/card/Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaFileExport, FaMagic } from "react-icons/fa";
import { getInsights } from "api/analytics/getInsights";
import { set } from "date-fns";
import ConnectedAppsTableOrders from "views/orderList/components/ConnectedAppsTableOrders";
import DashboardUpsell from "./components/DashboardUpsell";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import Skeleton from "components/dashboard/Skeleton";
import { CgImport } from "react-icons/cg";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell, FunnelChart, Funnel, LabelList } from "recharts";
import EditWidgetModal from "./components/EditModal";
import { v4 as uuidv4 } from 'uuid';
import AddWidgetModal from "./components/editModals/AddWidgetModal";
import NewDashboardModal from "./components/editModals/NewDashboardModal";
import { MdCampaign, MdTrendingUp, MdSettings, MdAttachMoney } from "react-icons/md";
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption } from "@chakra-ui/react";
import BubbleChart from "components/charts/BubbleChart";
import LineChart from "components/charts/LineChart";
import { CohortAnalysisGraph } from "./components/CohortAnalysisGraph";
import { CampaignWizard } from "./components/CampaignWizard";
import ActiveCampaigns from "./components/ActiveCampaigns";

const mockSegmentEngagementData = [
  { name: "Segment A", engagement: 90 },
  { name: "Segment B", engagement: 75 },
  { name: "Segment C", engagement: 50 },
  { name: "Segment D", engagement: 30 },
];

const mockProductViewsData = [
  { name: "Product X", views: 500 },
  { name: "Product Y", views: 350 },
  { name: "Product Z", views: 200 },
];

const clvGrowthData = [
  { month: "Jan", highValue: 500, mediumValue: 300, lowValue: 100 },
  { month: "Feb", highValue: 520, mediumValue: 310, lowValue: 105 },
  { month: "Mar", highValue: 550, mediumValue: 320, lowValue: 110 },
  { month: "Apr", highValue: 580, mediumValue: 340, lowValue: 115 },
  { month: "May", highValue: 600, mediumValue: 360, lowValue: 120 },
  { month: "Jun", highValue: 650, mediumValue: 380, lowValue: 125 },
];

const CLVWidget = () => {
  const [showPredictiveModel, setShowPredictiveModel] = useState(false);
  const [showAssumptions, setShowAssumptions] = useState(false);
  const [purchaseFrequency, setPurchaseFrequency] = useState(12);
  const [averageOrderValue, setAverageOrderValue] = useState(100);

  const predictiveCLVData = [
    { month: 1, Champions: 1000, AtRisk: 500, NewCustomers: 300 },
    { month: 6, Champions: 1200, AtRisk: 450, NewCustomers: 400 },
    { month: 12, Champions: 1500, AtRisk: 400, NewCustomers: 600 },
    // ... more data points
  ];



  return (
    <Box
      ms={4}
      borderWidth="1px"
      borderRadius="lg" p={6}>
      <Heading size="md" mb={4}>Customer Lifetime Value (CLV)</Heading>
      <Text mb={4}>
        Here is your Customer Lifetime Value (CLV). In this advanced breakdown, you can assess future revenue potential by segment, and deploy strategies to maximize it.
      </Text>
      <HStack spacing={4}>
        <Stat minW="100px" mb={4}>
          <StatLabel>Average CLV</StatLabel>
          <StatNumber>$1,200</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            23.36%
          </StatHelpText>
        </Stat>
        <Alert status="success" gridColumn="span 2" mb="4">
          <AlertIcon />
          <AlertDescription>Champions segment shows a 15% increase in CLV. Focus on replicating successful engagement strategies across other segments.</AlertDescription>
        </Alert>
      </HStack>
      <Button onClick={() => setShowPredictiveModel(true)} colorScheme="blue" mb={4} mt={8}>
        Access Predictive CLV Modeling
      </Button>

      <Modal isOpen={showPredictiveModel} onClose={() => setShowPredictiveModel(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Predictive CLV Model</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Expected CLV based on customer behavior, purchase history, and engagement:</Text>
            <Box height="300px">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={predictiveCLVData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Champions" stroke="#8884d8" />
                  <Line type="monotone" dataKey="AtRisk" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="NewCustomers" stroke="#ffc658" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Button onClick={() => setShowAssumptions(true)} mt={4} colorScheme="blue">
              Adjust CLV Calculation Assumptions
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showAssumptions} onClose={() => setShowAssumptions(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adjust CLV Assumptions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Purchase Frequency (per year)</FormLabel>
                <Input type="number" value={purchaseFrequency} onChange={(e) => setPurchaseFrequency(e.target.value)} />
              </FormControl>
              <FormControl>
                <FormLabel>Average Order Value</FormLabel>
                <Input type="number" value={averageOrderValue} onChange={(e) => setAverageOrderValue(e.target.value)} />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setShowAssumptions(false)}>
              Apply Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



      <Text fontWeight="bold" mt={4}>Key Insight:</Text>
      <Text>
        Leverage predictive CLV to prioritize your marketing spend. Invest more in segments with the highest future value and create retention strategies for those with lower CLV projections.
      </Text>
    </Box>
  );
};

const mockBarChartData = [
  { name: 'Jan', value: 400 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 500 },
  // More mock data points
];

const mockSegmentData = [
  { name: "High Value Customers", size: 1000, clv: "$500", lastUpdated: "2023-05-15" },
  { name: "At-Risk Customers", size: 500, clv: "$200", lastUpdated: "2023-05-14" },
  { name: "New Customers", size: 2000, clv: "$100", lastUpdated: "2023-05-13" },
];

const SegmentAnalysis = ({ segment, setSelectedSegment }) => {
  if (!segment) return null;

  const segmentNames = mockSegmentData.map((segment) => segment.name);


  return (
    <Box borderRadius="25px" >

      <Flex direction="row" justify="flex-end" alignItems="flex-end" mb={8}>
        <Select
          value={segment}
          onChange={(e) => setSelectedSegment(e.target.value)}
          borderRadius="25px"
          bg="gray.100"
          boxShadow="sm"
          borderColor="gray.300"
        >
          {segmentNames.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </Flex>
      <SimpleGrid columns={2} spacing={4} mb={4}>
        <Stat>
          <StatLabel>Average CLV</StatLabel>
          <StatNumber>${segment.clv}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            5% from last month
          </StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Churn Rate</StatLabel>
          <StatNumber>2.5%</StatNumber>
          <StatHelpText>
            <StatArrow type="decrease" />
            1% from last month
          </StatHelpText>
        </Stat>
      </SimpleGrid>
      <Box height="300px">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={[
            { name: 'Revenue', value: 4000 },
            { name: 'Orders', value: 3000 },
            { name: 'Customers', value: 2000 },
          ]}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#001842" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export const CampaignPerformance = () => {
  const performance = {
    impressions: 50000,
    clicks: 1500,
    conversions: 250,
    roas: 3.5,
    retentionImprovement: 5
  };

  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="xl" fontWeight="bold">Campaign Metrics (after 1 week)</Text>
      <HStack justify="space-between">
        <Text>Impressions:</Text>
        <Text fontWeight="bold">{performance.impressions.toLocaleString()}</Text>
      </HStack>
      <HStack justify="space-between">
        <Text>Clicks:</Text>
        <Text fontWeight="bold">{performance.clicks.toLocaleString()}</Text>
      </HStack>
      <HStack justify="space-between">
        <Text>Conversions:</Text>
        <Text fontWeight="bold">{performance.conversions}</Text>
      </HStack>
      <HStack justify="space-between">
        <Text>ROAS:</Text>
        <Text fontWeight="bold">{performance.roas}x</Text>
      </HStack>
      <Text>Retention Improvement:</Text>
      <Progress value={performance.retentionImprovement} max={15} />
      <Text textAlign="center">{performance.retentionImprovement}% (Goal: 15%)</Text>
      <Alert status="success">
        <AlertIcon />
        Your campaign is exceeding expectations with a {performance.roas}x ROAS. Consider increasing the budget to capture more opportunities.
      </Alert>
    </VStack>
  );
};

export default function Default() {
  const [dashboards, setDashboards] = useState({});
  const [showCohortAnalysis, setShowCohortAnalysis] = useState(false);
  const [isAddWidgetModalOpen, setIsAddWidgetModalOpen] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState("All");
  const [selectedChannel, setSelectedChannel] = useState("All");
  const [retentionRate, setRetentionRate] = useState(72);
  const [previousRate, setPreviousRate] = useState(85);
  const [showRetentionAnalysis, setShowRetentionAnalysis] = useState(false);
  const [showCampaignWizard, setShowCampaignWizard] = useState(false);

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");

  const [showRevenueDetails, setShowRevenueDetails] = useState(false);
  const [showLoyaltyCampaignWizard, setShowLoyaltyCampaignWizard] = useState(false);
  const [campaignLaunched, setCampaignLaunched] = useState(false);

  const revenueData = {
    total: 200000,
    growth: 15,
    segments: [
      { name: 'Champions', value: 120000, color: 'blue.500' },
      { name: 'Loyal Customers', value: 50000, color: 'green.500' },
      { name: 'At-Risk Customers', value: 20000, color: 'yellow.500' },
      { name: 'New Customers (returning)', value: 10000, color: 'purple.500' },
    ]
  };

  const cohortData = [
    { cohort: 'Jan 2023', '30 days': 90, '60 days': 85, '90 days': 80 },
    { cohort: 'Feb 2023', '30 days': 92, '60 days': 88, '90 days': 83 },
    { cohort: 'Mar 2023', '30 days': 88, '60 days': 82, '90 days': 78 },
  ];

  const [selectedStat, setSelectedStat] = useState("App Engagement Rate");


  const getMiniStats = () => [
    {
      title: "App Engagement Rate",
      amount: `${retentionRate}%`,
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={MdTouchApp} color={brandColor} />} />,
    },
    {
      title: "App Conversion Rate",
      amount: "3.2%",
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={MdShoppingCart} color={brandColor} />} />,
    },
    {
      title: "Avg. Customer Lifetime Value",
      amount: "$1,200",
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={MdTrendingUp} color={brandColor} />} />,
    },
    {
      title: "Revenue from Retained Customers",
      amount: "$45,000",
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />} />,
    },
  ];


  const handleExploreRetention = () => {
    setShowRetentionAnalysis(true);
  };

  const handleLaunchCampaign = () => {
    setShowCampaignWizard(true);
  };

  const handleCampaignComplete = () => {
    setShowCampaignWizard(false);
    setCampaignLaunched(true);
  };


  const handleStatSelect = (statTitle) => {
    setSelectedStat(statTitle);
  };

  let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');


  const renderSelectedWidget = () => {
    switch (selectedStat) {
      case "App Engagement Rate":
        return (
          <Card
            boxShadow="md"
            borderColor="gray.200"
            borderWidth="1px"
            h="100%"
          >
            <Flex h="100%" direction={{ base: "column", md: "row" }}>
              {/* Left Side (Insights and Controls) */}
              <Box
                alignContent={"center"}
                verticalAlign={"middle"}
                flex="1"
                pr={{ md: "4" }}
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Heading size="lg" mb="4">
                  Customer Engagement Insights
                </Heading>
                <Divider
                  mb="4"
                  color={"gray.800"}
                  borderWidth={"1.5px"}
                  colorScheme="gray"
                  borderColor={"gray.200"}
                />
                <Text fontSize="md" mb="4">
                  Real-time view of how well you're engaging customers. Let's
                  dive into how engagement impacts revenue.
                </Text>



                <Alert status="warning" mt="4">
                  <AlertIcon />
                  <AlertDescription>
                    Retention rate has dropped by 13% over the last 6 months.
                  </AlertDescription>
                </Alert>
                <Button
                  mt="4"
                  colorScheme="brand"
                  onClick={handleExploreRetention}
                >
                  Access Retention Cohort Analysis
                </Button>
              </Box>

              {/* Right Side (Charts) */}
              <Box flex="1" pl={{ md: "4" }}>
                <Flex direction="column" h="90%">
                  {/* Retention Rate Line Chart */}
                  <VStack spacing={0}>
                    <Select
                      mb="4"
                      value={selectedSegment}
                      onChange={(e) => setSelectedSegment(e.target.value)}
                      borderRadius="25px"
                      bg="gray.100"
                      boxShadow="sm"
                      borderColor="gray.300"
                    >
                      <option value="All">All Segments</option>
                      <option value="High Value">High Value</option>
                      <option value="Medium Value">Medium Value</option>
                      <option value="Low Value">Low Value</option>
                    </Select>

                    <Select
                      mb="4"
                      value={selectedChannel}
                      onChange={(e) => setSelectedChannel(e.target.value)}
                      borderRadius="25px"
                      bg="gray.100"
                      boxShadow="sm"
                      borderColor="gray.300"
                    >
                      <option value="All">All Channels</option>
                      <option value="Online">Online</option>
                      <option value="In-store">In-store</option>
                      <option value="Mobile">Mobile</option>
                    </Select>
                  </VStack>
                  <Card h="100%">

                    <LineChart
                      chartData={[
                        {
                          name: "High Value",
                          data: clvGrowthData.map((item) => item.highValue),
                        },
                        {
                          name: "Medium Value",
                          data: clvGrowthData.map((item) => item.mediumValue),
                        },
                        {
                          name: "Low Value",
                          data: clvGrowthData.map((item) => item.lowValue),
                        },
                      ]}
                      chartOptions={{
                        xaxis: {
                          categories: clvGrowthData.map((item) => item.month),
                        },
                        yaxis: {
                          title: { text: "Average CLV ($)" },
                        },
                      }}
                    />
                  </Card>


                </Flex>
              </Box>
            </Flex>
          </Card>
        );
      case "Customer Retention Rate":
        return (
          <Card
            boxShadow="md"
            borderColor='gray.200'
            borderWidth='1px'
            h="100%"
          >
            <Flex h="100%" direction={{ base: "column", md: "row" }}>
              <Box
                alignContent={"center"}
                verticalAlign={"middle"}
                flex="1" pr={{ md: "4" }} h="100%" justifyContent="center" alignItems="center" >
                <Heading size="lg" mb="4">
                  Customer Retention Insights
                </Heading>
                <Divider mb="4" color={"gray.800"} borderWidth={"1.5px"} colorScheme="gray" borderColor={"gray.200"} />
                <Text fontSize="md" mb="4">
                  Real-time view of how well you're retaining high-value customers. Let's dive into how retention impacts revenue.
                </Text>

                <Select
                  mb="4"
                  value={selectedSegment}
                  onChange={(e) => setSelectedSegment(e.target.value)}
                  borderRadius="25px"
                  bg="gray.100"
                  boxShadow="sm"
                  borderColor="gray.300"
                >
                  <option value="All">All Segments</option>
                  <option value="High Value">High Value</option>
                  <option value="Medium Value">Medium Value</option>
                  <option value="Low Value">Low Value</option>
                </Select>

                <Select
                  mb="4"
                  value={selectedChannel}
                  onChange={(e) => setSelectedChannel(e.target.value)}
                  borderRadius="25px"
                  bg="gray.100"
                  boxShadow="sm"
                  borderColor="gray.300"
                >
                  <option value="All">All Channels</option>
                  <option value="Online">Online</option>
                  <option value="In-store">In-store</option>
                  <option value="Mobile">Mobile</option>
                </Select>


                <Alert status="warning" mt="4">
                  <AlertIcon />
                  <AlertDescription>
                    Retention rate has dropped by 13% over the last 6 months.
                  </AlertDescription>
                </Alert>
                <Button mt="4" colorScheme="brand" onClick={handleExploreRetention}>
                  Access Retention Cohort Analysis
                </Button>
              </Box>
              <Box flex="1" pl={{ md: "4" }}>
                <LineChart
                  chartData={[
                    {
                      name: "Retention Rate",
                      data: [85, 83, 80, 78, 75, 72],
                    },
                  ]}
                  chartOptions={{
                    xaxis: {
                      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                    },
                    yaxis: {
                      title: {
                        text: "Retention Rate (%)",
                      },
                    },
                    animations: {
                      enabled: false
                    }
                  }}
                />
              </Box>
            </Flex>

          </Card>

        );
      case "App Engagement Rate":
        return (
          <Card
            boxShadow="md"
            borderColor="gray.200"
            borderWidth="1px"
            h="100%"
          >
            <Flex h="100%" direction={{ base: "column", md: "row" }}>
              {/* Left Side (Insights and Controls) */}
              <Box
                alignContent={"center"}
                verticalAlign={"middle"}
                flex="1"
                pr={{ md: "4" }}
                h="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Heading size="lg" mb="4">
                  Customer Engagement Insights
                </Heading>
                <Divider
                  mb="4"
                  color={"gray.800"}
                  borderWidth={"1.5px"}
                  colorScheme="gray"
                  borderColor={"gray.200"}
                />
                <Text fontSize="md" mb="4">
                  Real-time view of how well you're engaging customers. Let's
                  dive into how engagement impacts revenue.
                </Text>

                <Select
                  mb="4"
                  value={selectedSegment}
                  onChange={(e) => setSelectedSegment(e.target.value)}
                  borderRadius="25px"
                  bg="gray.100"
                  boxShadow="sm"
                  borderColor="gray.300"
                >
                  <option value="All">All Segments</option>
                  <option value="High Value">High Value</option>
                  <option value="Medium Value">Medium Value</option>
                  <option value="Low Value">Low Value</option>
                </Select>

                <Select
                  mb="4"
                  value={selectedChannel}
                  onChange={(e) => setSelectedChannel(e.target.value)}
                  borderRadius="25px"
                  bg="gray.100"
                  boxShadow="sm"
                  borderColor="gray.300"
                >
                  <option value="All">All Channels</option>
                  <option value="Online">Online</option>
                  <option value="In-store">In-store</option>
                  <option value="Mobile">Mobile</option>
                </Select>

                <Alert status="warning" mt="4">
                  <AlertIcon />
                  <AlertDescription>
                    Retention rate has dropped by 13% over the last 6 months.
                  </AlertDescription>
                </Alert>
                <Button
                  mt="4"
                  colorScheme="brand"
                  onClick={handleExploreRetention}
                >
                  Access Retention Cohort Analysis
                </Button>
              </Box>

              {/* Right Side (Charts) */}
              <Box flex="1" pl={{ md: "4" }}>
                <VStack align="stretch" spacing={6}>
                  {/* Retention Rate Line Chart */}
                  <Box>
                    <LineChart
                      chartData={[
                        {
                          name: "Retention Rate",
                          data: [85, 83, 80, 78, 75, 72],
                        },
                      ]}
                      chartOptions={{
                        xaxis: {
                          categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                          ],
                        },
                        yaxis: {
                          title: {
                            text: "Retention Rate (%)",
                          },
                        },
                        animations: {
                          enabled: false,
                        },
                      }}
                    />
                  </Box>

                  {/* Most Engaged Segments Bar Chart */}
                  <Box>
                    <Heading size="sm" mb={2}>
                      Engagement (Most Engaged Segments)
                    </Heading>
                    <BarChart
                      chartData={mockSegmentEngagementData
                        .slice()
                        .sort((a, b) => b.engagement - a.engagement)
                        .slice(0, 5)}
                      chartOptions={{
                        xaxis: {
                          categories: mockSegmentEngagementData
                            .slice()
                            .sort((a, b) => b.engagement - a.engagement)
                            .slice(0, 5)
                            .map((item) => item.name),
                        },
                        yaxis: {
                          title: {
                            text: "Engagement (%)",
                          },
                        },
                        animations: {
                          enabled: false,
                        },
                      }}
                    />
                  </Box>

                  {/* Least Engaged Segments Bar Chart */}
                  <Box>
                    <Heading size="sm" mb={2}>
                      Engagement (Least Engaged Segments)
                    </Heading>
                    <BarChart
                      chartData={mockSegmentEngagementData
                        .slice()
                        .sort((a, b) => a.engagement - b.engagement)
                        .slice(0, 5)}
                      chartOptions={{
                        xaxis: {
                          categories: mockSegmentEngagementData
                            .slice()
                            .sort((a, b) => a.engagement - b.engagement)
                            .slice(0, 5)
                            .map((item) => item.name),
                        },
                        yaxis: {
                          title: {
                            text: "Engagement (%)",
                          },
                        },
                        animations: {
                          enabled: false,
                        },
                      }}
                    />
                  </Box>

                  {/* Top Products Viewed Bar Chart */}
                  <Box>
                    <Heading size="sm" mb={2}>
                      Product Performance (Top Products Viewed)
                    </Heading>
                    <BarChart
                      chartData={mockProductViewsData}
                      chartOptions={{
                        xaxis: {
                          categories: mockProductViewsData.map(
                            (item) => item.name
                          ),
                        },
                        yaxis: {
                          title: {
                            text: "Number of Views",
                          },
                        },
                        animations: {
                          enabled: false,
                        },
                      }}
                    />
                  </Box>
                </VStack>
              </Box>
            </Flex>
          </Card>
        ); case "Active Campaigns":
        return (
          <Card
            boxShadow="md"
            borderColor='gray.200'
            borderWidth='1px'
            h="100%"
            borderRadius="25px"
          >
            {/* <Heading size="md" mb="4">Active Campaigns</Heading> */}
            <ActiveCampaigns />
          </Card>
        );
      case "Avg. Customer Lifetime Value":
        return (
          <SimpleGrid columns={2} width="100%" pl={4} bg="gray.100" borderRadius="25px" boxShadow="sm"
            pt={4} px={6} pb={4}
          >
            <SegmentAnalysis segment={selectedSegment} setSelectedSegment={setSelectedSegment} />
            <CLVWidget />

          </SimpleGrid>
        );
      case "Revenue from Retained Customers":
        return (
          <Card h="100%">
            <Flex h="100%" direction={{ base: "column", md: "row" }}>
              <Box flex="1" pr={{ md: "4" }}>
                <Heading size="md" mb="4">Revenue from Retained Customers</Heading>
                <Stat>
                  <StatNumber fontSize="3xl" fontWeight="bold" color="brand.500">
                    ${revenueData.total.toLocaleString()}
                  </StatNumber>
                  <StatHelpText>
                    <StatArrow type="increase" />
                    {revenueData.growth}% from last month
                  </StatHelpText>
                </Stat>
                <Text mt="2" fontSize="sm" color="gray.600">
                  Revenue from your returning customers is growing steadily.
                </Text>
                <Alert status="info" mt="4">
                  <AlertIcon />
                  <AlertDescription>Loyal customers contribute 60% of retained revenue. Implement a loyalty program to boost this further.</AlertDescription>
                </Alert>
                <Button mt="4" colorScheme="brand" onClick={() => setShowRevenueDetails(true)}>
                  Explore segment contributions
                </Button>
              </Box>
              <Box flex="1" h="100%" pl={{ md: "4" }}>
                <LineChart
                  chartData={[
                    {
                      name: "Revenue",
                      data: [150000, 160000, 175000, 185000, 195000, 200000],
                    },
                  ]}
                  chartOptions={{
                    xaxis: {
                      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                    },
                    yaxis: {
                      title: {
                        text: "Revenue ($)",
                      },
                    },
                    colors: ["#4318FF"],
                    stroke: {
                      curve: "smooth",
                    },
                  }}
                />
              </Box>
            </Flex>
          </Card>
        );

      default:
        return null;
    }
  };

  const handleLaunchLoyaltyCampaign = () => {
    setShowLoyaltyCampaignWizard(true);
  };


  return (
    <Flex flexDirection="column" height="calc(100vh - 80px)" overflowY="inherit">
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} height="calc(100vh - 8px)" overflowY="inherit">
        <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='20px' mb='20px'>
          {getMiniStats().map((stat, index) => (
            <MiniStatistics
              key={index}
              startContent={stat.icon}
              name={stat.title}
              value={stat.amount}
              onClick={() => handleStatSelect(stat.title)}
              cursor="pointer"
              isSelected={selectedStat === stat.title}
            />
          ))}
        </SimpleGrid>


        {selectedStat && (
          <Box mb='20px' h="80%">
            {renderSelectedWidget()}
          </Box>
        )}

        <SimpleGrid columns={{ base: 1, md: 1 }} gap='20px' mb='20px'>

          {/* Keep existing cards */}

          {campaignLaunched && (
            <Card>
              <CampaignPerformance />
            </Card>
          )}
        </SimpleGrid>
      </Box>

      <Modal isOpen={showRetentionAnalysis} onClose={() => setShowRetentionAnalysis(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detailed Retention Analysis</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CohortAnalysisGraph data={cohortData} />
            <Alert status="error" mt={4}>
              <AlertIcon />
              <AlertDescription>At-Risk Customers are disengaging rapidly. Retention has dropped by 20% in this segment.</AlertDescription>
            </Alert>
            <Button onClick={handleLaunchCampaign} mt={4} colorScheme="green">
              Launch Win-Back Campaign for At-Risk Customers
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showCampaignWizard} onClose={() => setShowCampaignWizard(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Campaign Wizard</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CampaignWizard onComplete={handleCampaignComplete} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showRevenueDetails} onClose={() => setShowRevenueDetails(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Revenue from Retained Customers - Detailed Breakdown</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box height="300px">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={revenueData.segments}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value">
                    {revenueData.segments.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Alert status="info" mt="4">
              <AlertIcon />
              Your Champions are driving the bulk of your returning revenue, followed by Loyal Customers. At-Risk Customers contribute significantly less.
            </Alert>
            <Button mt="4" onClick={handleLaunchLoyaltyCampaign}>Create Loyalty Campaign for Champions</Button>
            <Button mt="4" ml="4" variant="outline">Re-engage At-Risk Customers</Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={showLoyaltyCampaignWizard} onClose={() => setShowLoyaltyCampaignWizard(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Loyalty Campaign Wizard</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CampaignWizard onComplete={handleCampaignComplete} />
          </ModalBody>
        </ModalContent>
      </Modal>

      {campaignLaunched && (
        <Card mt="4">
          <Heading size="md" mb="4">Loyalty Campaign Performance</Heading>
          <CampaignPerformance />
        </Card>
      )}
    </Flex>
  );
}
