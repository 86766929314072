import { Box, Text, Input, HStack, Flex, useColorModeValue, IconButton, SimpleGrid, InputGroup, InputLeftAddon, InputRightElement, Icon, Divider } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import { useContext } from 'react';
import { FaImage } from 'react-icons/fa';

const VariantListItem = ({ variant, index, updateVariant, currency, isEditable = true }) => {
    const { setHasUnsavedChanges } = useContext(ProductInEditContext);

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
    );

    const handlePriceChange = (e) => {
        const newPrice = e.target.value;
        updateVariant(index, 'price', newPrice);
        setHasUnsavedChanges(true);
    };

    const handleSkuChange = (e) => {
        const newSku = e.target.value;
        updateVariant(index, 'sku', newSku);
        setHasUnsavedChanges(true);
    };

    return (
        <Card key={index} boxShadow="none" borderRadius="10px">
            {isEditable ? (
                <HStack key={index} spacing="4" alignItems={"flex-end"}>
                    <IconButton
                        ms="10px"
                        align='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        lineHeight='100%'
                        borderRadius='10px'>
                        <Icon as={FaImage} color={"gray.500"} w='24px' h='24px' />
                    </IconButton>

                    {Object.entries(variant.attributes).length < 2 ? (
                        <SimpleGrid columns={{ base: 1, md: 1 }} w={"100%"} >
                            {Object.entries(variant.attributes).map(([key, value], attrIndex) => (
                                key !== "title" && (
                                    <Flex key={attrIndex} direction="column" ms="10px" w="100%">
                                        <Text fontSize="sm" color={textColorPrimary} fontWeight="bold">
                                            {key}
                                        </Text>
                                        <Box
                                            borderRadius="8px"
                                            border="1px solid"
                                            borderColor="secondaryGray.100"
                                            p="2"
                                            noOfLines={1}
                                        >
                                            {value}
                                        </Box>
                                    </Flex>
                                )
                            ))}
                        </SimpleGrid>
                    ) : (
                        <SimpleGrid columns={{ base: 1, md: 2 }} w={"100%"} >
                            {Object.entries(variant.attributes).map(([key, value], attrIndex) => (
                                key !== "title" && (
                                    <Flex key={attrIndex} direction="column" ms="10px">
                                        <Text fontSize="sm" color={textColorPrimary} fontWeight="bold">
                                            {key}
                                        </Text>
                                        <Box
                                            borderRadius="8px"
                                            border="1px solid"
                                            borderColor="secondaryGray.100"
                                            p="2"
                                        >
                                            {value}
                                        </Box>
                                    </Flex>
                                )
                            ))}
                        </SimpleGrid>
                    )}

                    <Input
                        placeholder="SKU"
                        value={variant.sku}
                        onChange={handleSkuChange}
                        ms="10px"
                    />
                    <InputGroup>
                        <InputLeftAddon children="$" />
                        <Input
                            placeholder="Price"
                            value={variant.price}
                            onChange={handlePriceChange}
                            type='number'
                        />
                        <InputRightElement children={currency} textColor={"secondaryGray.500"} me="5px" />
                    </InputGroup>


                </HStack>
            ) : (
                <HStack key={index} spacing="4" alignItems={"flex-end"}>
                    <Flex align="center" alignItems="center" justify={"center"} direction="column">
                        <Box
                            w="36px"
                            h="36px"
                            borderRadius="10px"
                            alignSelf={"center"}
                        >
                            <Icon as={FaImage} color={"gray.500"} w='100%' h='100%' />
                        </Box>



                    </Flex>

                    {Object.entries(variant.attributes).length < 2 ? (
                        <SimpleGrid columns={{ base: 1, md: 1 }} w={"100%"} >
                            {Object.entries(variant.attributes).map(([key, value], attrIndex) => (
                                <Flex key={attrIndex} direction="column" ms="10px" w="100%">
                                    <Text fontSize="sm" color={textColorPrimary} fontWeight="bold">
                                        {key}
                                    </Text>
                                    <Box
                                        borderRadius="8px"
                                        border="1px solid"
                                        borderColor="secondaryGray.100"
                                        p="2"
                                        noOfLines={1}
                                    >
                                        {value}
                                    </Box>
                                </Flex>
                            ))}
                        </SimpleGrid>
                    ) : (
                        <SimpleGrid columns={{ base: 1, md: 2 }} w={"100%"} >
                            {Object.entries(variant.attributes).map(([key, value], attrIndex) => (
                                <Flex key={attrIndex} direction="column" ms="10px">
                                    <Text fontSize="sm" color={textColorPrimary} fontWeight="bold">
                                        {key}
                                    </Text>
                                    <Box
                                        borderRadius="8px"
                                        border="1px solid"
                                        borderColor="secondaryGray.100"
                                        p="2"
                                    >
                                        {value}
                                    </Box>
                                </Flex>
                            ))}
                        </SimpleGrid>
                    )}

                </HStack>
            )}
            {/* <Input
                placeholder="SKU"
                value={variant.sku}
                onChange={(e) => updateVariant(index, 'sku', e.target.value)}
                ms="10px"
            />
            <InputGroup>
                <InputLeftAddon children="$" />
                <Input
                    placeholder="Price"
                    value={variant.price}
                    onChange={(e) => updateVariant(index, 'price', e.target.value)}
                    type='number'
                />
                <InputRightElement children={currency} textColor={"secondaryGray.500"} me="5px" />
            </InputGroup> */}
            <Divider orientation="horizontal" mt="35px" />

        </Card>
    );
};

export default VariantListItem;