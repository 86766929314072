/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, Flex, HStack, SimpleGrid, Tag, TagLabel, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

import Delete from './components/Delete';
import Details from './components/PricingDetails';
import Info from './components/Info';
import { logData } from 'util/Logger';
import Toggles from 'components/widgets/toggles/Toggles';
import InventoryDetails from './components/InventoryDetails';
import { log } from 'util/Logger';
import { useContext, useState } from 'react';
import { ProductInEditContext } from 'contexts/ProductInEditContext';
import { Bounce, toast } from 'react-toastify';
import ProductVariants from './components/ProductVariants';
import Editor from './components/Editor';
import Carousel from './components/Carousel';
import PromotionDetails from './components/PromotionDetails';

export default function ProductSettings(props) {
  const { onDismissDrawer, id, item } = props;

  let productData;
  if (id === 'new_product_draft') {
    productData = null;
  } else {
    productData = item;
  }

  const {
    name = ["", "", ""],
    description = "",
    price = 0,
    currency = "",
    categories = [],
    digital_assets = [],
    attributes: propsAttributes = [],
    inventory = 0,
    vendor = "",
    tags = [],
    variants: propsVariants = [],
    lastSync = {},
  } = productData || {}; // Add this line

  const { setHasUnsavedChanges } = useContext(ProductInEditContext);

  // Product Data
  const [childValues, setChildValues] = useState({});
  const [variants, setVariants] = useState(propsVariants);
  const [deletedVariants, setDeletedVariants] = useState([]);
  const [attributes, setAttributes] = useState(propsAttributes)

  const handleChildValueChange = (newValue) => {
    setChildValues((prevValues) => ({ ...prevValues, ...newValue }));
  };

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box >
      <SimpleGrid
        columns={{ sm: 1, xl: 1 }}
        spacing={{ base: '20px', xl: '20px' }}
      >
        {/* Column Left */}
        <Box /> {/* Spacer */}
        <Flex direction="column">
          <Carousel id={id} images={digital_assets} />
          <Accordion allowMultiple allowToggle mt={"15px"} defaultIndex={[0, 1, 2]} className="myAccordion">

            <Card
              borderRadius="10px"
              boxShadow="base"
              p="0px"
              border="1px solid"
              borderColor={borderColor}
            >
              <AccordionItem defaultChecked index={0}>
                <AccordionButton>
                  <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                      Product Information
                    </Text>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <Info
                    name={name[0]}
                    description={description}
                    id={id}
                    categories={categories}
                    handleChildValueChange={handleChildValueChange}
                    vendor={vendor}
                    tags={tags}
                  />

                </AccordionPanel>
              </AccordionItem>
            </Card>

            <Box h="20px" />

            <Card
              borderRadius="10px"
              boxShadow="base"
              p="0px"
              border="1px solid"
              borderColor={borderColor}
            >
              <AccordionItem defaultChecked index={1}>
                <AccordionButton>
                  <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                      Description
                    </Text>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <Editor
                    id={id}
                    htmlBody={description}
                    placeholder={'Product Details...'}
                    name={name[0]}
                    handleChildValueChange={handleChildValueChange}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Card>

            <Box h="20px" />

            <Card
              borderRadius="10px"
              boxShadow="base"
              p="0px"
              border="1px solid"
              borderColor={borderColor}
            >
              <AccordionItem defaultChecked index={2}>
                <AccordionButton>
                  <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                      Pricing Details
                    </Text>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <Details
                    price={price}
                    id={id}
                    currency={currency}
                    handleChildValueChange={handleChildValueChange}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Card>

            <Box h="20px" />

            <Card
              borderRadius="10px"
              boxShadow="base"
              p="0px"
              border="1px solid"
              borderColor={borderColor}
            // bg="secondaryGray.300"
            >

              <AccordionItem index={3}>
                <AccordionButton>
                  <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                    <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                      Product Variants <Tag
                        fontSize="sm"
                        h="30px"
                        w="30px"
                        me="6px"
                        borderRadius="18px"
                        variant="solid"
                        bg="secondaryGray.600"
                        p="5px"
                      >
                        <TagLabel w="100%">{variants.length}</TagLabel>
                      </Tag>
                    </Text>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <ProductVariants
                    id={id}
                    currency={currency}

                    variants={variants}
                    setVariants={setVariants}

                    deletedVariants={deletedVariants}
                    setDeletedVariants={setDeletedVariants}

                    attributes={attributes}
                    setAttributes={setAttributes}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Card>

            {/* <AccordionItem index={4}>
              <AccordionButton>
                <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                  <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                    Custom Attributes
                  </Text>
                  <AccordionIcon />
                </Flex>
              </AccordionButton>
              <AccordionPanel>
                <CustomAttributes id={id} attributes={[]} />
              </AccordionPanel>
            </AccordionItem> */}

            <Box h="20px" />

            <Card
              borderRadius="10px"
              boxShadow="base"
              p="0px"
              bg="secondaryGray.200"
              border="1px solid"
              borderColor="secondaryGray.300"
            >

              <AccordionItem index={4}>
                <AccordionButton>
                  <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                    <HStack>
                      <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                        Inventory
                      </Text>
                      <Badge fontSize="md" colorScheme="brand" borderRadius={"5px"}>Coming Soon</Badge>
                    </HStack>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <InventoryDetails id={id} inventory={inventory} />
                </AccordionPanel>
              </AccordionItem>
            </Card>


            <Box h="20px" />

            <Card
              borderRadius="10px"
              boxShadow="base"
              p="0px"
              bg="secondaryGray.200"
              border="1px solid"
              borderColor="secondaryGray.300"
            >

              <AccordionItem index={5}>
                <AccordionButton>
                  <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                    <HStack>
                      <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                        Promotions
                      </Text>
                      <Badge fontSize="md" colorScheme="brand" borderRadius={"5px"}>Coming Soon</Badge>
                    </HStack>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <PromotionDetails id={id} />
                </AccordionPanel>
              </AccordionItem>
            </Card>

            <Box h="20px" />

            <Card
              borderRadius="10px"
              boxShadow="base"
              p="0px"
              bg="secondaryGray.200"
              border="1px solid"
              borderColor="secondaryGray.300"
            >
              <AccordionItem index={6}>
                <AccordionButton>
                  <Flex direction="row" w="full" justify="space-between" my="10px" ms="10px">
                    <HStack>
                      <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
                        Sales Channels
                      </Text>
                      <Badge fontSize="md" colorScheme="brand" borderRadius={"5px"}>Coming Soon</Badge>
                    </HStack>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel>
                  <Toggles channels={lastSync} />
                </AccordionPanel>
              </AccordionItem>
            </Card>

          </Accordion>

          <Box h="20px" />
        </Flex>
        {/* Column Right */}
        {/* <Flex direction="column">
          <Socials id={id} />
        </Flex> */}
      </SimpleGrid>

      {id === 'new_product_draft' ? (
        null
      ) : (
        <Delete id={id} channels={lastSync} />
      )
      }
    </Box>
  );
}
