// src/components/editModals/HistogramWidgetModal.js
import React, { useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Checkbox,
    HStack,
    Button,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from "@chakra-ui/react";

export default function HistogramWidgetModal({ widget, onSave }) {
    const [config, setConfig] = useState(widget.config || {});

    const handleChange = (e, nestedKey = null) => {
        const { name, value, type, checked } = e.target;
        setConfig(prevConfig => ({
            ...prevConfig,
            [nestedKey ? `${nestedKey}.${name}` : name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleDataSourceChange = (index, field, value) => {
        const newDataSources = [...config.data_sources];
        newDataSources[index] = { ...newDataSources[index], [field]: value };
        setConfig({ ...config, data_sources: newDataSources });
    };

    const addDataSource = () => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: [...(prevConfig.data_sources || []), {}]
        }));
    };

    const removeDataSource = (index) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: prevConfig.data_sources.filter((_, i) => i !== index)
        }));
    };

    return (
        <VStack spacing={4} align="stretch">
            {/* Data Sources */}
            {config.data_sources?.map((dataSource, index) => (
                <VStack key={index} spacing={2} align="stretch" borderWidth={1} borderRadius="md" p={2}>
                    <FormControl>
                        <FormLabel>Data Source {index + 1}</FormLabel>
                        <Input
                            value={dataSource.source}
                            onChange={(e) => handleDataSourceChange(index, 'source', e.target.value)}
                            placeholder="Source name"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Account ID</FormLabel>
                        <Input
                            value={dataSource.account_id}
                            onChange={(e) => handleDataSourceChange(index, 'account_id', e.target.value)}
                            placeholder="Account ID"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Metric</FormLabel>
                        <Input
                            value={dataSource.metric}
                            onChange={(e) => handleDataSourceChange(index, 'metric', e.target.value)}
                            placeholder="Metric"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Aggregation</FormLabel>
                        <Select
                            value={dataSource.aggregation}
                            onChange={(e) => handleDataSourceChange(index, 'aggregation', e.target.value)}
                        >
                            <option value="count">Count</option>
                            <option value="sum">Sum</option>
                            <option value="average">Average</option>
                        </Select>
                    </FormControl>
                    <Button onClick={() => removeDataSource(index)} colorScheme="red">Remove Data Source</Button>
                </VStack>
            ))}
            <Button onClick={addDataSource} colorScheme="blue">Add Data Source</Button>

            {/* Chart Settings */}
            <FormControl>
                <FormLabel>Number of Bins</FormLabel>
                <NumberInput
                    name="number_of_bins"
                    value={config.chart_settings?.number_of_bins || 10}
                    onChange={(valueString) => handleChange({ target: { name: 'number_of_bins', value: parseInt(valueString) } }, 'chart_settings')}
                    min={1}
                    max={100}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </FormControl>

            <FormControl>
                <FormLabel>Bar Color</FormLabel>
                <Input
                    name="bar_color"
                    value={config.chart_settings?.bar_color || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="Bar Color (e.g., #FF0000)"
                />
            </FormControl>

            <Checkbox
                name="show_kde"
                isChecked={config.chart_settings?.show_kde}
                onChange={(e) => handleChange(e, 'chart_settings')}
            >
                Show Kernel Density Estimation
            </Checkbox>

            <FormControl>
                <FormLabel>KDE Color</FormLabel>
                <Input
                    name="kde_color"
                    value={config.chart_settings?.kde_color || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="KDE Color (e.g., #0000FF)"
                />
            </FormControl>

            <Checkbox
                name="show_legend"
                isChecked={config.chart_settings?.show_legend}
                onChange={(e) => handleChange(e, 'chart_settings')}
            >
                Show Legend
            </Checkbox>

            <FormControl>
                <FormLabel>Tooltip Format</FormLabel>
                <Select
                    name="tooltip_format"
                    value={config.chart_settings?.tooltip_format || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                >
                    <option value="absolute_value">Absolute Value</option>
                    <option value="percentage">Percentage</option>
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>X Axis Label</FormLabel>
                <Input
                    name="x_axis_label"
                    value={config.chart_settings?.x_axis_label || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="X Axis Label"
                />
            </FormControl>

            <FormControl>
                <FormLabel>Y Axis Label</FormLabel>
                <Input
                    name="y_axis_label"
                    value={config.chart_settings?.y_axis_label || ''}
                    onChange={(e) => handleChange(e, 'chart_settings')}
                    placeholder="Y Axis Label"
                />
            </FormControl>

            {/* Refresh Rate */}
            <FormControl>
                <FormLabel>Refresh Rate</FormLabel>
                <Select
                    name="refresh_rate"
                    value={config.refresh_rate || ''}
                    onChange={handleChange}
                >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                </Select>
            </FormControl>
        </VStack>
    );
}
