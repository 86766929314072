import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Flex,
    HStack,
    Input,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { errorData } from "util/Logger";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";

export default function ConnectGoogle(props) {
    const { onImportClose } = props;
    const [serviceAccountJson, setServiceAccountJson] = useState("");
    const [merchantId, setMerchantId] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isImportingProducts, setIsImportingProducts] = useState(false);
    const [importStatus, setImportStatus] = useState(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setServiceAccountJson(reader.result);
            };
            reader.readAsText(file);
        }
    };

    const uploadServiceAccountJson = async () => {
        setIsUploading(true);
        try {
            const uploadFunction = httpsCallable(functions, "uploadServiceAccountJson");
            const result = await uploadFunction({ serviceAccountJson });

            if (result.data.success) {
                // No-op
            } else {
                errorData("Failed to upload service account JSON file:", result.data.error);
            }
        } catch (error) {
            errorData("Error uploading service account JSON file:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const importProductsFromGoogle = async () => {
        setIsImportingProducts(true);
        setImportStatus(null);

        try {
            const importProductsFunction = httpsCallable(functions, "importProductsFromGoogle");
            const result = await importProductsFunction({ merchantId });

            if (result.data.success) {
                setImportStatus("success");
                onImportClose(); // Dismiss the modal on successful import
            } else {
                setImportStatus("error");
                errorData("Failed to import products from Google Merchant:", result.data.error);
            }
        } catch (error) {
            setImportStatus("error");
            errorData("Error importing products from Google Merchant:", error);
        } finally {
            setIsImportingProducts(false);
        }
    };

    const handleImportProducts = async () => {
        await uploadServiceAccountJson();
        await importProductsFromGoogle();
    };

    const brandColor = useColorModeValue("brand.500", "brand.200");

    return (
        <>
            <Flex direction="column" align="center">
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                    Connect Google Merchant
                </Text>

                <Flex direction="column" align="start" width="300px" mt="15px">
                    <Text fontWeight="bold" mb={2}>
                        Service Account JSON
                    </Text>
                    <Box
                        borderRadius="10px"
                        border="1px solid"
                        borderColor="gray.300"
                        mb={4}
                        pt="10px"
                    >
                        <Input
                            type="file"
                            accept=".json"
                            onChange={handleFileUpload}
                            variant="transparent"
                            _focus={{ borderColor: brandColor }}
                        />
                    </Box>

                    <Text fontWeight="bold" mb={2}>
                        Merchant ID
                    </Text>
                    <Input
                        value={merchantId}
                        onChange={(e) => setMerchantId(e.target.value)}
                        mb={4}
                    />
                    {importStatus === "success" && (
                        <Alert
                            dismissable
                            status="success"
                            borderRadius={0}
                            mt="10px"
                            fontSize={{ sm: "sm", md: "sm", lg: "md" }}
                            w="100%"
                        >
                            <Flex>
                                <AlertIcon />
                                <Flex direction="column">
                                    <AlertTitle mr="12px">
                                        Products imported successfully.
                                    </AlertTitle>
                                </Flex>
                            </Flex>
                        </Alert>
                    )}
                    {importStatus === "error" && (
                        <Alert
                            dismissable
                            status="error"
                            borderRadius={0}
                            mt="10px"
                            fontSize={{ sm: "sm", md: "sm", lg: "md" }}
                            w="100%"
                        >
                            <Flex>
                                <AlertIcon />
                                <Flex direction="column">
                                    <AlertTitle mr="12px">
                                        Error importing products from Google Merchant.
                                    </AlertTitle>
                                </Flex>
                            </Flex>
                        </Alert>
                    )}
                    <Button
                        color="white"
                        bg="black"
                        borderRadius={0}
                        mt="10px"
                        fontSize={{ sm: "sm", md: "sm", lg: "md" }}
                        fontWeight="bold"
                        w="100%"
                        textAlign="center"
                        onClick={handleImportProducts}
                        isLoading={isImportingProducts || isUploading}
                        disabled={!serviceAccountJson || !merchantId}
                    >
                        Import Products
                    </Button>
                </Flex>
                <HStack mt="10px">
                    <Button
                        fontSize={{ sm: "sm", md: "sm", lg: "sm" }}
                        borderRadius={0}
                    >
                        See Instructions
                        <ExternalLinkIcon mx="5px" />
                    </Button>
                </HStack>
            </Flex>
        </>
    );
}
