import { Box, Text, Button, Flex, SimpleGrid, useColorModeValue, VStack } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { AddIcon } from '@chakra-ui/icons';
import OptionsEditor from './OptionsEditor';
import { useRef, useState } from 'react';
import NewAttributeOptionsEditor from './NewAttributeOptionsEditor';

export default function OptionsCard({
    colors,

    variants,
    setVariants,

    deletedVariants,
    setDeletedVariants,

    attributes,
    setAttributes,
}) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const [isAddingAttribute, setIsAddingAttribute] = useState(false);
    const newAttributeRef = useRef(null);

    const addAttribute = () => {
        setIsAddingAttribute(true);
        // const newAttributeName = `Attribute ${Object.keys(attributes).length + 1}`;
        // setAttributes((prevAttributes) => ({
        //     ...prevAttributes,
        //     [newAttributeName]: [],
        // }));
        // console.log('newAttributeName:', {
        //     [newAttributeName]: [],
        // });
        // setTimeout(() => {
        //     newAttributeRef.current?.focus();
        // }, 0);

    };

    return (
        <Card boxShadow="none" borderRadius="10px" >
            <VStack spacing="4" alignItems={"flex-start"}>
                {variants.length > 0 && (
                    <Flex direction="row" justify={"space-between"} w="100%">
                        <Text fontSize="xl" color={textColorPrimary} fontWeight="600">Options</Text>

                    </Flex>
                )}
                {/* <Divider /> */}
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px">
                    {Object.entries(attributes).map(([key, values], index) => (
                        key !== "title" && (
                            <Flex key={key} direction="column" mb="10px" ms="10px">
                                <OptionsEditor
                                    ref={index === Object.keys(attributes).length - 1 ? newAttributeRef : null}
                                    pillBg={colors[index]}
                                    attributeName={key}

                                    variants={variants}
                                    setVariants={setVariants}

                                    deletedVariants={deletedVariants}
                                    setDeletedVariants={setDeletedVariants}

                                    attributes={attributes}
                                    setAttributes={setAttributes}

                                    isAddingAttribute={isAddingAttribute}
                                />

                            </Flex>
                        )
                    ))}

                    {isAddingAttribute && (
                        <Card boxShadow="none" borderRadius="10px" p="10px">
                            <Flex key={`Attribute ${Object.keys(attributes).length + 1}`} direction="column" mb="10px" ms="10px">
                                <NewAttributeOptionsEditor
                                    pillBg={colors[0]}
                                    attributeName={`Attribute ${Object.keys(attributes).length + 1}`}

                                    variants={variants}
                                    setVariants={setVariants}

                                    deletedVariants={deletedVariants}
                                    setDeletedVariants={setDeletedVariants}

                                    attributes={attributes}
                                    setAttributes={setAttributes}
                                    isAddingAttribute={isAddingAttribute}
                                    setIsAddingAttribute={setIsAddingAttribute}
                                />

                            </Flex>
                        </Card>
                    )}
                </SimpleGrid>
                <Box w="100%" h="10px" />
                {!isAddingAttribute && (
                    <Button
                        onClick={addAttribute}
                        colorScheme="brand"
                        variant='outline'
                        alignSelf={"center"}
                        borderRadius={"10px"}
                    >
                        <AddIcon me="5px" />
                        Add Attribute
                    </Button>
                )}
            </VStack>
        </Card>
    );
}