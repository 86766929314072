/*eslint-disable*/
import { DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
    Flex,
    Box,
    Input,
    Tag,
    TagCloseButton,
    TagLabel,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Text,
    IconButton,
} from '@chakra-ui/react';
// Custom components
import { useState, useRef, useContext, forwardRef } from 'react';
import VariantListItem from './VariantListItem';
import { ProductInEditContext } from 'contexts/ProductInEditContext';

const OptionsEditor = forwardRef((props, ref) => {
    const { setHasUnsavedChanges } = useContext(ProductInEditContext);

    const {
        attributeName,
        variants = [],
        setVariants,
        deletedVariants = [],
        setDeletedVariants,
        attributes,
        setAttributes,
        isAddingAttribute,
        ...rest
    } = props;

    console.log('variants:', variants);
    let initialTags = [];

    const placeholderTags = attributes[attributeName];

    if (placeholderTags && Array.isArray(placeholderTags)) {
        initialTags = placeholderTags.map((tag, index) => {
            return {
                name: tag,
                id: index + 1,
            };
        });
    }
    const [tags, setTags] = useState(initialTags);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [tagToDelete, setTagToDelete] = useState({ name: '', id: '' });
    const cancelRef = useRef();

    const handleTagsChange = (newTags) => {
        setTags(newTags);
        setHasUnsavedChanges(true);
    };

    const [inputError, setInputError] = useState('');

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            const newOption = e.target.value.trim();

            // Check if the new option already exists in the attributes (case-insensitive)
            if (attributes[attributeName].some((option) => option.toLowerCase() === newOption.toLowerCase())) {
                setInputError('Option already exists');
                return;
            }

            handleTagsChange([
                ...tags,
                {
                    name: newOption,
                    id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1,
                },
            ]);
            e.target.value = '';
            setInputError('');

            // Add option to attributes
            const newAttributes = { ...attributes };
            newAttributes[attributeName] = [...newAttributes[attributeName], newOption];

            // Create new variants
            const newVariants = [];
            for (const variant of variants) {
                for (const value of newAttributes[attributeName]) {
                    if (value !== variant.attributes[attributeName]) {
                        newVariants.push({
                            ...variant,
                            attributes: {
                                ...variant.attributes,
                                [attributeName]: value,
                            },
                        });
                    }
                }
            }

            // Combine existing variants with new variants
            const allVariants = [...variants, ...newVariants];

            // Remove duplicate variants based on their 'attributes' field
            const uniqueVariants = Object.values(
                allVariants.reduce((acc, variant) => {
                    const key = JSON.stringify(variant.attributes);
                    if (!acc[key]) {
                        acc[key] = variant;
                    }
                    return acc;
                }, {})
            );

            // Each variants should have exactly one value for each attribute.
            // Remove any variants that have missing values for any attribute.
            const filteredVariants = uniqueVariants.filter((variant) => {
                for (const attributeName in newAttributes) {
                    if (!variant.attributes[attributeName]) {
                        return false;
                    }
                }
                return true;
            });
            console.log('filteredVariants:', filteredVariants);
            setVariants(filteredVariants);

            setAttributes(newAttributes);


            console.log('uniqueVariants:', uniqueVariants);
        }
    };

    const handleDeleteClick = (tag) => {
        console.log('handleDeleteClick tag:', tag);
        setTagToDelete(tag);
        setIsAlertOpen(true);
    };

    const confirmDelete = () => {
        handleTagsChange(tags.filter((element) => element.id !== tagToDelete.id));

        // Remove option from attributes
        const newAttributes = { ...attributes };
        newAttributes[attributeName] = newAttributes[attributeName].filter(
            (element) => element !== tagToDelete.name
        );
        console.log('newAttributes:', newAttributes);
        setAttributes(newAttributes);

        // Update variants based on the deleted option
        const newVariants = variants.map((variant) => {
            if (variant.attributes[attributeName] === tagToDelete.name) {
                // If the deleted option is the only option for this attribute,
                // remove the attribute from the variant
                if (newAttributes[attributeName].length === 0) {
                    const { [attributeName]: _, ...updatedAttributes } = variant.attributes;
                    return { ...variant, attributes: updatedAttributes };
                }
                // Otherwise, replace the deleted option with the first available option
                else {
                    return {
                        ...variant,
                        attributes: {
                            ...variant.attributes,
                            [attributeName]: newAttributes[attributeName][0],
                        },
                    };
                }
            }
            return variant;
        });

        // Remove duplicate variants based on their 'attributes' field
        const uniqueVariants = Object.values(
            newVariants.reduce((acc, variant) => {
                const key = JSON.stringify(variant.attributes);
                if (!acc[key]) {
                    acc[key] = variant;
                }
                return acc;
            }, {})
        );

        console.log('uniqueVariants:', uniqueVariants);
        setVariants(uniqueVariants);

        // If the deleted option was the last one for the attribute, remove the attribute from the attributes map
        if (newAttributes[attributeName].length === 0) {
            const { [attributeName]: _, ...updatedAttributes } = newAttributes;
            setAttributes(updatedAttributes);
        }

        setIsAlertOpen(false);
        setTagToDelete({ name: '', id: '' });
    };

    const [isAttributeDeleteAlertOpen, setIsAttributeDeleteAlertOpen] = useState(false);

    const handleAttributeDelete = () => {
        setIsAttributeDeleteAlertOpen(true);
    };

    const confirmAttributeDelete = () => {
        // Remove the attribute from the attributes map
        const { [attributeName]: _, ...updatedAttributes } = attributes;
        setAttributes(updatedAttributes);

        // Update variants by removing the attribute
        const updatedVariants = variants.map((variant) => {
            const { [attributeName]: _, ...updatedVariantAttributes } = variant.attributes;
            return { ...variant, attributes: updatedVariantAttributes };
        });

        // Remove duplicate variants based on their 'attributes' field
        const uniqueVariants = Object.values(
            updatedVariants.reduce((acc, variant) => {
                const key = JSON.stringify(variant.attributes);
                if (!acc[key]) {
                    acc[key] = variant;
                }
                return acc;
            }, {})
        );

        console.log('uniqueVariants after attribute delete:', uniqueVariants);
        setVariants(uniqueVariants);

        setIsAttributeDeleteAlertOpen(false);
        setHasUnsavedChanges(true);
    };

    const cancelAttributeDelete = () => {
        setIsAttributeDeleteAlertOpen(false);
    };

    const generateVariants = (attributes, keys, index = 0, current = {}) => {
        if (index === keys.length) {
            return [current];
        }

        const key = keys[index];
        const variants = [];

        for (const value of attributes[key]) {
            variants.push(...generateVariants(attributes, keys, index + 1, { ...current, [key]: value }));
        }

        return variants;
    };

    const cancelDelete = () => {
        setIsAlertOpen(false);
        setTagToDelete({ name: '', id: '' });
    };

    const [newAttributeName, setNewAttributeName] = useState(attributeName);
    const [attributeNameError, setAttributeNameError] = useState('');
    const [isEditingAttributeName, setIsEditingAttributeName] = useState(false);

    const handleAttributeNameChange = (e) => {
        setNewAttributeName(e.target.value);
    };

    const handleAttributeNameSave = () => {
        if (newAttributeName.trim() === '') {
            setAttributeNameError('Attribute name cannot be empty');
            return;
        }

        if (newAttributeName !== attributeName && attributes.hasOwnProperty(newAttributeName)) {
            setAttributeNameError('Attribute name already exists');
            return;
        }

        const attributesArray = Object.entries(attributes);
        const updatedAttributesArray = attributesArray.map(([key, value]) => {
            if (key === attributeName) {
                return [newAttributeName, value];
            }
            return [key, value];
        });
        const updatedAttributes = Object.fromEntries(updatedAttributesArray);
        setAttributes(updatedAttributes);

        const updatedVariants = variants.map((variant) => {
            const variantAttributesArray = Object.entries(variant.attributes);
            const updatedVariantAttributesArray = variantAttributesArray.map(([key, value]) => {
                if (key === attributeName) {
                    return [newAttributeName, value];
                }
                return [key, value];
            });
            const updatedVariantAttributes = Object.fromEntries(updatedVariantAttributesArray);
            return {
                ...variant,
                attributes: updatedVariantAttributes,
            };
        });
        setVariants(updatedVariants);

        setAttributeNameError('');
        setIsEditingAttributeName(false);
        setHasUnsavedChanges(true);
    }

    let borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
    let bg = props.pillBg || 'brand.400';
    return (
        <>
            <Flex direction="row" alignItems="center">
                <Input
                    ref={ref}
                    type="text"
                    id={attributeName}
                    value={isEditingAttributeName ? newAttributeName : attributeName}
                    onChange={handleAttributeNameChange}
                    fontWeight="bold"
                    fontSize="sm"
                    variant="flushed"
                    placeholder="Enter label"
                    ps="5px"
                    isInvalid={attributeNameError !== ''}
                    errorBorderColor="red.300"
                    readOnly={!isEditingAttributeName}
                    _hover={{ cursor: isAddingAttribute ? 'not-allowed' : 'text' }}
                    onClick={() => setIsEditingAttributeName(true)}
                    disabled={isAddingAttribute}
                />
                {isEditingAttributeName && (
                    <IconButton
                        icon={<CheckIcon />}
                        onClick={handleAttributeNameSave}
                        color="secondaryGray.500"
                        variant="ghost"
                        size="sm"
                        ml="2"
                    />
                )}
                {isEditingAttributeName && (
                    <IconButton
                        icon={<CloseIcon />}
                        onClick={() => setIsEditingAttributeName(false)}
                        color="secondaryGray.500"
                        variant="ghost"
                        size="xs"
                        ml="2"
                    />
                )}
                {!isEditingAttributeName && !isAddingAttribute && (
                    <IconButton
                        icon={<DeleteIcon />}
                        onClick={handleAttributeDelete}
                        color="secondaryGray.500"
                        variant="ghost"
                        ml="auto"
                    />
                )}
            </Flex>
            {attributeNameError && (
                <Text color="red.500" fontSize="sm" mt="4px">
                    {attributeNameError}
                </Text>
            )}

            <Flex
                direction="row"
                p="12px"
                wrap="wrap"
                bg="gray.100"
                border="1px solid"
                borderColor={borderColor}
                borderRadius="10px"
                _focus={{ borderColor: 'teal.300' }}
                minH="40px"
                h="stretch"
                cursor={isAddingAttribute ? 'not-allowed' : 'text'}
                {...rest}
            >
                {tags.map((tag, index) => {
                    return (
                        <Tag
                            fontSize="xs"
                            h="25px"
                            mb="6px"
                            me="6px"
                            borderRadius="10px"
                            variant="solid"
                            bg={isAddingAttribute ? 'secondaryGray.500' : bg}
                            key={index}
                        >
                            <TagLabel w="100%">{tag.name}</TagLabel>
                            <TagCloseButton
                                justifySelf="flex-end"
                                color="white"
                                onClick={() => handleDeleteClick(tag)}
                            />
                        </Tag>
                    );
                })}
                <Input
                    variant="main"
                    bg="transparent"
                    border="none"
                    p="0px"
                    onKeyDown={(e) => keyPress(e)}
                    fontSize="sm"
                    isInvalid={inputError !== ''}
                    errorBorderColor="red.300"
                    disabled={isAddingAttribute}
                    cursor={isAddingAttribute ? 'not-allowed' : 'text'}
                />
                {inputError && (
                    <Text color="red.500" fontSize="sm" mt="4px">
                        {inputError}
                    </Text>
                )}
            </Flex>

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={cancelDelete}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Option
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Box>
                                <Text as="p" mb="20px">Deleting this option will remove the following variants:</Text>
                                {
                                    variants.filter((variant) => variant.attributes[attributeName]?.toLowerCase() === tagToDelete.name?.toLowerCase()).map((variant, index) => {
                                        return (
                                            <VariantListItem variant={variant} key={index} isEditable={false} />
                                        );
                                    })
                                }
                            </Box>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={cancelDelete}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <AlertDialog
                isOpen={isAttributeDeleteAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={cancelAttributeDelete}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Attribute
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>Are you sure you want to delete the attribute "{attributeName}"?</Text>
                            <Text>This action cannot be undone.</Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={cancelAttributeDelete}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={confirmAttributeDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
});

export default OptionsEditor;