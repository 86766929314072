// Create a new file: src/components/AIInsight.js
import React from "react";
import { Button, Flex, HStack, Icon, Text, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { MdAutoAwesome, MdThumbUp, MdThumbDown, MdClose } from "react-icons/md";
import Card from "./card/Card";

const AIInsight = ({ message }) => {
    return (
        <Card
            p="0px"
            bg="green.50"
            pt="0px"
            ps="20px"
            pe={0}
            mb="25px"
        >
            <Flex
                direction="column"
            >
                <Flex
                    direction="row"
                    justify="space-between"

                >
                    <HStack
                        mt="15px"
                        spacing={4} alignItems="start">
                        <MdAutoAwesome color="brand" size="25px" />
                        <Text
                            fontWeight="700"
                            color="brand.500"
                            fontSize="md"
                            pt="5px"
                        >
                            {message}
                        </Text>
                    </HStack>
                    <Button
                        variant="ghost"
                    >
                        <Icon as={MdClose} />
                    </Button>
                </Flex>
                <HStack
                    alignSelf="flex-end"
                    me="10px"
                >
                    <Button
                        variant="ghost"
                        leftIcon={<Icon as={MdThumbUp} color="green.500" />}
                    >Helpful
                    </Button>
                    <Button
                        variant="ghost"
                        leftIcon={<Icon as={MdThumbDown} color="red.500" />}
                    >Not Helpful
                    </Button>

                </HStack>
            </Flex>

        </Card>
    );
};

export default AIInsight;
