// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import SwitchField from 'components/fields/SwitchField';

export default function Newsletter(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';
  const borderColor = useColorModeValue('white', 'whiteAlpha.100');

  // Chakra Color Mode
  return (
    <FormControl>
      <Card
        borderRadius={"5px"}
        border="1px solid"
        borderColor={borderColor}
        boxShadow='sm'
        p="30px" mb="20px" {...rest}>
        <Flex mb="40px" justify="space-between" align="center">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            Newsletter
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: '20px', xl: '0px' }}
        >
          <SwitchField
            mb="25px"
            me="30px"
            id="1"
            label="Weekly newsletter"
            desc="Get notified about articles, discounts and new products."
          />
          <SwitchField
            mb="25px"
            me="30px"
            id="2"
            label="Lifecycle emails"
            desc="Get personalized offers and emails based on your activity."
          />
        </SimpleGrid>
        <SwitchField
          mb="25px"
          me="30px"
          id="3"
          label="Promotional emails"
          desc="Get personalized offers and emails based on your orders & preferences."
        />
        <SwitchField
          me="30px"
          id="4"
          textWidth="60%"
          label="Product updates"
          desc="Checking this will allow us to notify you when we make updates to products you have downloaded/purchased."
        />
      </Card>
    </FormControl>
  );
}
