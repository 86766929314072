/*eslint-disable*/
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, AlertTitle, Button, Flex, HStack, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { errorData } from "util/Logger";
import checkShopifyProductsAccess from "api/shopify/checkShopifyProductsAccess";
import storeShopifyApiKeys from "api/shopify/storeShopifyApiKeys";
import { importFromShopify } from "api/shopify/importFromShopify";

const ImportStatus = {
    NOT_CONNECTED: 'NOT_CONNECTED',
    CONNECTED: 'CONNECTED',
    CONNECTING: 'CONNECTING',
    ERROR: 'ERROR',
    INSUFFICIENT_PERMISSIONS: 'INSUFFICIENT_PERMISSIONS',
};

const ImportStarted = {
    IMPORTING: 'IMPORTING',
    IMPORT_SCHEDULED: 'IMPORT_SCHEDULED',
    IMPORT_FAILED: 'IMPORT_FAILED',
    IMPORT_NOT_STARTED: 'IMPORT_NOT_STARTED',
}

export default function ConnectShopify(props) {
    const { onImportClose } = props;
    const [accessToken, setAccessToken] = useState('');
    const [shopName, setShopName] = useState('');
    const [shopifyConnectionStatus, setShopifyConnectionStatus] = useState(ImportStatus.NOT_CONNECTED);
    const [importStarted, setImportStarted] = useState(ImportStarted.IMPORT_NOT_STARTED);

    const importShopifyProducts = async () => {
        setImportStarted(ImportStarted.IMPORTING);
        try {
            const result = await importFromShopify({
                importProducts: true,
                importOrders: true,
                importCustomers: true,
            });
            if (result.success) {
                setImportStarted(ImportStarted.IMPORT_SCHEDULED);
                onImportClose();
            } else {
                setImportStarted(ImportStarted.IMPORT_FAILED);
            }
        } catch (error) {
            setImportStarted(ImportStarted.IMPORT_FAILED);
            errorData(error);
        }
    }

    const saveShopifyAccessToken = async ({
        accessToken,
        shopName,
        grantedScopes,
    }) => {
        try {
            const result = await storeShopifyApiKeys({ accessToken, shopName, grantedScopes });
            if (result.success) {
                setShopifyConnectionStatus(ImportStatus.CONNECTED);
            } else {
                setShopifyConnectionStatus(ImportStatus.ERROR);
            }
        } catch (error) {
            setShopifyConnectionStatus(ImportStatus.ERROR);
            errorData(error);
        }
    }

    const testShopifyConnection = async () => {
        setShopifyConnectionStatus(ImportStatus.CONNECTING);

        try {
            const result = await checkShopifyProductsAccess({ accessToken, shopName });

            if (result.success) {
                await saveShopifyAccessToken({ accessToken, shopName, grantedScopes: result.grantedScopes });
            } else {
                setShopifyConnectionStatus(ImportStatus.INSUFFICIENT_PERMISSIONS);
            }

        } catch (error) {
            setShopifyConnectionStatus(ImportStatus.ERROR);
            errorData(error);
        }
    };
    return (
        <>
            <Flex direction="column" align="center">
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                    Connect Shopify
                </Text>

                {/* <Box width="100%" mb={4}>
            <Carousel views={images} />
          </Box> */}
                <Flex direction="column" align="start" width="300px" mt='15px'>
                    <Text fontWeight="bold" mb={2}>
                        Access Token
                    </Text>
                    <Input mb={4}
                        value={accessToken}
                        onChange={(e) => setAccessToken(e.target.value)}
                    />
                    <Text fontWeight="bold" mb={2}>
                        Shop Name
                    </Text>
                    <Input mb={4}
                        value={shopName}
                        onChange={(e) => setShopName(e.target.value)}
                    />
                    {shopifyConnectionStatus == ImportStatus.CONNECTED && (
                        <Alert
                            dismissable
                            status='success' borderRadius={0} mt='10px' fontSize={{ sm: "sm", md: "sm", lg: "md" }} w='100%' >
                            <Flex >
                                <AlertIcon />
                                <Flex direction="column">
                                    <AlertTitle mr="12px">Shopify Connected.</AlertTitle>
                                </Flex>
                            </Flex>
                        </Alert>
                    )}
                    {shopifyConnectionStatus == ImportStatus.ERROR && (
                        <Alert
                            dismissable
                            status='error' borderRadius={0} mt='10px' fontSize={{ sm: "sm", md: "sm", lg: "md" }} w='100%' >
                            <Flex >
                                <AlertIcon />
                                <Flex direction="column">
                                    <AlertTitle mr="12px">Error Connecting Shopify.</AlertTitle>
                                </Flex>
                            </Flex>
                        </Alert>
                    )}
                    {shopifyConnectionStatus == ImportStatus.INSUFFICIENT_PERMISSIONS && (
                        <Alert
                            dismissable
                            status='error' borderRadius={0} mt='10px' fontSize={{ sm: "sm", md: "sm", lg: "md" }} w='100%' >
                            <Flex >
                                <AlertIcon />
                                <Flex direction="column">
                                    <AlertTitle mr="12px">Insufficient Permissions.</AlertTitle>
                                </Flex>
                            </Flex>
                        </Alert>
                    )}
                    {shopifyConnectionStatus == ImportStatus.CONNECTED ? (
                        <Button
                            color='white'
                            bg='black'
                            borderRadius={0}
                            mt='10px'
                            fontSize={{ sm: "sm", md: "sm", lg: "md" }}
                            fontWeight="bold"
                            w='100%'
                            textAlign='center'
                            onClick={importShopifyProducts}
                            isLoading={importStarted === ImportStarted.IMPORTING}
                        >Continue</Button>
                    ) : (
                        <Button
                            color='white'
                            bg='black'
                            borderRadius={0}
                            mt='10px'
                            fontSize={{ sm: "sm", md: "sm", lg: "md" }}
                            fontWeight="bold"
                            w='100%'
                            textAlign='center'
                            onClick={testShopifyConnection}
                            isLoading={shopifyConnectionStatus === ImportStatus.CONNECTING}
                        >Test Connection</Button>
                    )}
                </Flex>
                <HStack
                    mt='10px'
                >
                    {/* <Text
                        color='gray.600'
                        fontSize={{ sm: "sm", md: "sm", lg: "sm" }}
                        fontWeight="bold"
                        ms='15px'
                        textAlign='start'
                    >
                        Need Help?
                    </Text>
                    <Flex direction='row' /> */}
                    <Button
                        fontSize={{ sm: "sm", md: "sm", lg: "sm" }}
                        borderRadius={0}
                    >
                        See Instructions
                        <ExternalLinkIcon mx='5px' />
                    </Button>\
                </HStack>
            </Flex >
        </>
    )
}