import React from "react";

// Chakra imports
import {
    Icon,
    Flex,
    Text,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
    useColorModeValue,
    HStack,
} from "@chakra-ui/react";
// Assets
import {
    MdEdit,
    MdCategory,
    MdEditAttributes,
} from "react-icons/md";
import { FaDollarSign, FaTag } from "react-icons/fa";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { ImPriceTag } from "react-icons/im";

export default function BulkEditMenu(props) {
    const { onClick, ...rest } = props;

    const onMenuItemClicked = (action) => {
        onClick(action);
    };

    const textColor = useColorModeValue("secondaryGray.500", "white");
    const textHover = useColorModeValue(
        { color: "secondaryGray.900", bg: "unset" },
        { color: "secondaryGray.500", bg: "unset" }
    );
    const bgList = useColorModeValue("white", "whiteAlpha.100");
    const bgShadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
        "unset"
    );
    const bgButton = useColorModeValue("brand.500", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
    );

    // Ellipsis modals
    const {
        isOpen: isOpen1,
        onOpen: onOpen1,
        onClose: onClose1,
    } = useDisclosure();

    return (
        <Menu isOpen={isOpen1} onClose={onClose1}>
            <MenuButton
                align='center'
                justifyContent='center'
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                lineHeight='100%'
                onClick={onOpen1}
                h="32px"
                mb="6px"
                me="6px"
                borderRadius="16px"
                colorScheme="brand"
                variant="brand"
                size="sm"
                px="10px"
                {...rest}>
                <HStack>
                    <Icon as={MdEdit} color="white" w='20px' h='20px' />
                    <Text
                        textColor="white"
                        fontSize="sm"
                        fontWeight="700"
                    >
                        Edit
                    </Text>
                </HStack>
            </MenuButton>
            <MenuList
                w='200px'
                minW='unset'
                maxW='200px !important'
                border='transparent'
                backdropFilter='blur(63px)'
                bg={bgList}
                boxShadow={bgShadow}
                borderRadius='20px'
                p='15px'>
                <MenuItem
                    transition='0.2s linear'
                    color={textColor}
                    _hover={textHover}
                    p='0px'
                    borderRadius='8px'
                    _active={{
                        bg: "transparent",
                    }}
                    _focus={{
                        bg: "transparent",
                    }}
                    mb='10px'
                    onClick={onMenuItemClicked.bind(this, "categories")}
                >
                    <Flex align='center'>
                        <Icon as={FaTag} h='16px' w='16px' me='8px' />
                        <Text fontSize='sm' fontWeight='400'>
                            Edit Tags
                        </Text>
                    </Flex>
                </MenuItem>
                <MenuItem
                    transition='0.2s linear'
                    p='0px'
                    borderRadius='8px'
                    color={textColor}
                    _hover={textHover}
                    _active={{
                        bg: "transparent",
                    }}
                    _focus={{
                        bg: "transparent",
                    }}
                    mb='10px'
                    onClick={onMenuItemClicked.bind(this, "price")}
                >
                    <Flex align='center'>
                        <Icon as={FaDollarSign} h='16px' w='16px' me='8px' />
                        <Text fontSize='sm' fontWeight='400'>
                            Update Pricing
                        </Text>
                    </Flex>
                </MenuItem>
                <MenuItem
                    transition='0.2s linear'
                    color={textColor}
                    _hover={textHover}
                    p='0px'
                    borderRadius='8px'
                    _active={{
                        bg: "transparent",
                    }}
                    _focus={{
                        bg: "transparent",
                    }}
                    onClick={onMenuItemClicked.bind(this, "attributes")}
                >
                    <Flex align='center'>
                        <Icon as={MdEditAttributes} h='16px' w='16px' me='8px' />
                        <Text fontSize='sm' fontWeight='400'>
                            Edit Attributes
                        </Text>
                    </Flex>
                </MenuItem>
                <MenuItem
                    transition='0.2s linear'
                    color={bgButton}
                    _hover={textHover}
                    p='0px'
                    borderRadius='8px'
                    _active={{
                        bg: "transparent",
                    }}
                    _focus={{
                        bg: "transparent",
                    }}
                    mt="20px"
                    onClick={onMenuItemClicked.bind(this, "bulk")}
                >
                    <Flex align='center'>
                        <Text fontSize='sm' fontWeight='400' me="5px" >
                            Bulk Editor
                        </Text>
                        <Icon as={ExternalLinkIcon} h='16px' w='16px' me='8px' />
                    </Flex>
                </MenuItem>
            </MenuList>
        </Menu>
    );
}
