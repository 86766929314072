// Chakra imports
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import React from 'react';
// Assets
import { MdDownload, MdPrint, MdShare } from 'react-icons/md';
import Content from './Content';
import { errorData } from 'util/Logger';
export default class ComponentToPrint extends React.Component {
  // for react-to-print to work, it must be called from a class based component
  render() {

    const handlePrint = () => {
      try {
        this.props.handlePrint();
      } catch (error) {
        errorData('Error while printing:', error);
      }
    };

    const { bgButton, bgFocus, bgHover, ...rest } = this.props;

    return (
      <Card
        {...rest}
        justifySelf="center"
        alignSelf="center"
        px={{ base: '10px', md: '30px' }}
      >
        <Card
          bgGradient="linear(to-b, #9B73FF, #B274F5)"
          backgroundRepeat="no-repeat"
          bgSize="cover"
          bgPosition="10%"
          flexDirection="row"
          py="50px"
          px="50px"
          borderRadius="10px"
        >
          <Flex direction="column" color="white" h="100%" w="100%" >
            <Text
              lineHeight="100%"
              fontSize={{ sm: 'lg', md: '30px', lg: '36px', xl: '40px' }}
              fontWeight="bold"
            >
              Order {this.props.order}
            </Text>
            <Text fontSize={{ base: 'md', md: 'xl' }} fontWeight="regular">
              {new Date(this.props.createdAt).toLocaleString()}
            </Text>
          </Flex>
          <Flex alignSelf="start" my={{ base: 'auto', md: '0px' }}>
            <Button
              onClick={handlePrint}
              ms="auto"
              me="10px"
              alignItems="center"
              justifyContent="center"
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              p="7px"
              minW="unset"
              h="32px"
              lineHeight="100%"
              borderRadius="6px"
            >
              <Icon as={MdPrint} color="white" w="18px" h="18px" />
            </Button>
            <Button
              alignItems="center"
              me="10px"
              justifyContent="center"
              bg={bgButton}
              _hover={bgHover}
              _focus={bgFocus}
              _active={bgFocus}
              p="7px"
              minW="unset"
              h="32px"
              lineHeight="100%"
              borderRadius="6px"
            >
              <Icon as={MdDownload} color="white" w="18px" h="18px" />
            </Button>
            <Button
              alignItems="center"
              justifyContent="center"
              bg="linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)"
              _hover={{
                bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
              }}
              _focus={{
                bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
              }}
              _active={{
                bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)',
              }}
              p="7px"
              minW="unset"
              h="32px"
              lineHeight="100%"
              borderRadius="6px"
            >
              <Icon as={MdShare} color="white" w="18px" h="18px" />
            </Button>
          </Flex>
        </Card>
        <Content
          id={this.props.id}
          amount={this.props.amount}
          currency={this.props.currency}
          status={this.props.status}
          items={this.props.items}
          customer={this.props.customer}
          channel={this.props.channel} />
      </Card>
    );
  }
}
