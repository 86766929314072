/*eslint-disable*/
import { Icon, Flex, Text, useColorModeValue, Box, Image } from "@chakra-ui/react";
import { MdCheckCircle, MdCircle, MdMoveToInbox, MdSync } from "react-icons/md";
import React from "react";
import { formatDistanceToNow, isValid } from "date-fns";

import shopify from "assets/img/icons/shopify.webp";
import google from "assets/img/icons/google.svg";

export function ItemContent(props) {
  const { isRead, onToggleRead, createdAt, type, data, } = props;
  const textColor = useColorModeValue("navy.700", "white");

  const getIcon = () => {
    switch (type) {
      case 'import': return (
        <Flex
          justify='center'
          align='center'
          mb={'10px'}
          borderRadius='16px'
          minH={{ base: "60px", md: "70px" }}
          h={{ base: "60px", md: "70px" }}
          minW={{ base: "60px", md: "70px" }}
          w={{ base: "60px", md: "70px" }}
          me='14px'
          bg='secondaryGray.400'>
          <MdMoveToInbox size={20} color='brand' />
        </Flex>
      )
      case 'import_success':
      case 'sync_success':
        switch (data.source) {
          case 'shopify': return (
            <Flex
              justify='center'
              align='center'
              mb={'10px'}
              borderRadius='16px'
              minH={{ base: "60px", md: "70px" }}
              h={{ base: "60px", md: "70px" }}
              minW={{ base: "60px", md: "70px" }}
              w={{ base: "60px", md: "70px" }}
              me='14px'
              bg='secondaryGray.400'>
              <Image src={shopify} w="34px" h="34px" />
            </Flex>
          )
          case 'google': return <Image src={google} w="34px" h="34px" />
        }
      case 'sync': return (
        <Flex
          justify='center'
          align='center'
          mb={'10px'}
          borderRadius='16px'
          minH={{ base: "60px", md: "70px" }}
          h={{ base: "60px", md: "70px" }}
          minW={{ base: "60px", md: "70px" }}
          w={{ base: "60px", md: "70px" }}
          me='14px'
          bg='secondaryGray.400'>
          <MdSync size={20} color='brand' />
        </Flex>
      )
      default: return (
        <Flex
          justify='center'
          align='center'
          mb={'10px'}
          borderRadius='16px'
          minH={{ base: "60px", md: "70px" }}
          h={{ base: "60px", md: "70px" }}
          minW={{ base: "60px", md: "70px" }}
          w={{ base: "60px", md: "70px" }}
          me='14px'
          bg='secondaryGray.400'>
          <MdCheckCircle size={20} color='brand' />
        </Flex>
      )
    }
  }
  return (
    <>
      {getIcon()}
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Flex flexDirection="column">
          <Text
            mb='5px'
            fontWeight='bold'
            color={textColor}
            fontSize={{ base: "md", md: "md" }}>
            {props.info}
          </Text>
          <Flex alignItems='center'>
            <Text
              fontSize={{ base: "sm", md: "sm" }}
              lineHeight='100%'
              color={textColor}>
              {`${createdAt && isValid(new Date(createdAt))
                ? formatDistanceToNow(new Date(createdAt), { addSuffix: true })
                : 'Never'
                }`}
            </Text>
          </Flex>
        </Flex>
        <Box w="20px" />
        <Icon

          as={isRead ? MdCheckCircle : MdCircle}
          color={isRead ? "green.500" : "red.500"}
          boxSize={4}
          cursor="pointer"
          onClick={onToggleRead}
        />
      </Flex>
    </>
  );
}
