/*eslint-disable*/

import { Box } from "@chakra-ui/react";
import ImportStatusAlert from "./ImportStatusAlert";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { logData } from "util/Logger";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AlertContainer() {
    const history = useHistory();
    const { currentUser } = useContext(AuthContext);
    const [importStatus, setImportStatus] = useState(null);

    const onSeeImportDetailsClicked = () => {
        history.push('/admin/products/importdetails');
    }

    const handleDismissImportStatusAlert = async () => {
        setImportStatus(null);
        await dismissImportStatusAlert();
    }

    useEffect(() => {
        const fetchData = async () => {
            logData('AlertContainer:currentUser', currentUser);
            if (currentUser.uid) {
                logData('AlertContainer:currentUser', currentUser);
                const importStatus = await getImportStatus();
                if (importStatus)
                    setImportStatus(importStatus.value);

                logData('AlertContainer:importStatus', importStatus);
            }
        };

        fetchData();
    }, [currentUser.uid]);

    return (
        <Box>
            {importStatus && <ImportStatusAlert importStatus={importStatus} onClick={onSeeImportDetailsClicked} onClose={handleDismissImportStatusAlert} />}
        </Box>
    )
}