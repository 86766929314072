import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth'; // import from Firebase auth
import { getAuth } from 'firebase/auth'; // import your Firebase auth instance
import { log } from 'util/Logger';

// Create the context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => localStorage.getItem('isAuthenticated') === 'true'
  );
  const [displayName, setDisplayName] = useState(
    () => localStorage.getItem('displayName')
  );
  const [uid, setUid] = useState(
    () => localStorage.getItem('uid')
  );
  const [token, setToken] = useState(null);

  const init = async () => {
    log('init auth context');
  };

  useEffect(() => {
    init();

    const fetchToken = async (user) => {
      const token = await user.getIdToken();
      setToken(token);
    };

    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        log('user authenticated');
        setIsAuthenticated(true);
        setDisplayName(user.displayName);
        setUid(user.uid);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('displayName', user.displayName);
        localStorage.setItem('uid', user.uid);

        await fetchToken(user);
      } else {
        setIsAuthenticated(false);
        setDisplayName(null);
        setUid(null);
        setToken(null);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('displayName');
        localStorage.removeItem('uid');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{
      currentUser: {
        isAuthenticated,
        displayName,
        uid,
        token,
      }
    }}>
      {children}
    </AuthContext.Provider>
  );
};