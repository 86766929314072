import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export const signIn = async (email, password) => {
    const auth = getAuth();

    try {
        await signInWithEmailAndPassword(auth, email, password);
        // const user = userCredential.user;
        // // Get the session token
        // const token = await user.getIdToken(true);
        // await fetch(`${config.NEXT_PUBLIC_SAVE_SESSION_TOKEN}/${user.uid}`, {
        //     method: 'POST',
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({ sessionToken: token }),
        // });
        // return user;
    } catch (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(errorCode, errorMessage);
        throw error;
    }
};