import { Flex, Image, Tooltip } from "@chakra-ui/react";

export default function DataSource({ src, label, onClick, isAvailable = true }) {
    return (
        <Flex direction="column" align="center">
            <Flex w="100px" h="100px" cursor="pointer">
                <Tooltip label={label}>
                    <Flex
                        w="100%"
                        h="100%"
                        borderRadius="12px"
                        justify="center"
                        transition=".5s all ease"
                        align="center"
                        _hover={{ opacity: "0.8" }}
                        onClick={onClick}
                    >
                        <Image
                            src={src}
                            w="54px"
                            h="54px"
                            objectFit="contain"
                            filter={isAvailable ? "" : "grayscale(100%)"}
                        />
                    </Flex>
                </Tooltip>
            </Flex>
        </Flex>
    );
}