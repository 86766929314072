// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import SessionBadge from './SessionBadge';

export default function Sessions(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';
  const cardBorderColor = useColorModeValue('white', 'whiteAlpha.100');
  // Chakra Color Mode
  return (
    <FormControl>
      <Card
        borderRadius={"5px"}
        border="1px solid"
        borderColor={cardBorderColor}
        boxShadow='sm'
        p="30px" {...rest}>
        <Flex justify="space-between" align="center">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            Sessions
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: '20px', xl: '0px' }}
        >
          <SessionBadge
            py="25px"
            borderBottom="1px solid"
            borderColor={borderColor}
            detail="EU"
            name="Chrome, Bucharest 68.128.072.301"
            status="Enabled"
            color="green"
          />
          <SessionBadge
            py="25px"
            borderBottom="1px solid"
            borderColor={borderColor}
            detail="US"
            name="Chrome MacOS (Your current session)"
            status="Enabled"
            color="green"
          />
          <SessionBadge
            py="25px"
            borderBottom="1px solid"
            borderColor={borderColor}
            detail="EU"
            name="Firefox on Desktop, Barcelona"
            status="Enabled"
            color="green"
          />
          <SessionBadge
            pt="25px"
            detail="US"
            name="Safari on Iphone"
            status="Disable"
            color="red"
          />
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
