// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';

export default function Password(props) {
  const { ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';
  const borderColor = useColorModeValue('white', 'whiteAlpha.100');
  // Chakra Color Mode
  return (
    <Card
      borderRadius={"5px"}
      border="1px solid"
      borderColor={borderColor}
      boxShadow='sm'
      {...rest}>
      <Flex direction="column" mb="30px" ms="10px">
        <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
          Change password
        </Text>
        <Text fontSize="md" color={textColorSecondary}>
          Here you can set your new password
        </Text>
      </Flex>
      <FormControl>
        <Flex flexDirection="column">
          <InputField
            mb="25px"
            id="old"
            label="Old Password"
            placeholder="Your old password"
          />
          <InputField
            mb="25px"
            id="new"
            label="New Password"
            placeholder="Your new password"
          />
          <InputField
            mb="25px"
            id="confirm"
            label="New Password Confirmation"
            placeholder="Confirm new password"
          />
        </Flex>
      </FormControl>
      <Button
        borderRadius="6px"
        minW="183px"
        h="44px"
        ms="auto"
        mt="33px"
        variant="brand"
        color="white"
        fontSize="sm"
        fontWeight="500"
        _hover={{ bg: 'brand.600' }}
        _active={{ bg: 'brand.500' }}
        _focus={{ bg: 'brand.500' }}
      >
        Change Password
      </Button>
    </Card>
  );
}
