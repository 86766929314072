// src/components/editModals/BarChartWidgetModal.js
import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack,
    Checkbox,
    HStack,
    Button,
    Box,
    SimpleGrid,
    Text,
    useColorModeValue,
    Heading,
    Divider,
    Flex,
    IconButton,
} from "@chakra-ui/react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Card from 'components/card/Card';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import DataSourceSection from './DataSourceSection';

export default function BarChartWidgetModal({ widget, onSave, showPreview = false }) {
    const [config, setConfig] = useState(widget.config || {});
    const [previewData, setPreviewData] = useState([]);

    const borderColor = useColorModeValue('white', 'whiteAlpha.100');

    const materialShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';

    useEffect(() => {
        // Generate sample data for preview
        const sampleData = [
            { name: 'Category A', value: 400 },
            { name: 'Category B', value: 300 },
            { name: 'Category C', value: 200 },
            { name: 'Category D', value: 278 },
            { name: 'Category E', value: 189 },
        ];
        setPreviewData(sampleData);
    }, []);

    const handleChange = (e, nestedKey = null) => {
        const { name, value, type, checked } = e.target;
        console.log('test', name, value, type, checked);
        setConfig(prevConfig => ({
            ...prevConfig,
            [nestedKey ? `${nestedKey}` : name]: type === 'checkbox' ? checked : value
        }));

        console.log('config', config);
    };

    const handleDataSourceChange = (index, field, value) => {
        const newDataSources = [...config.data_sources];
        newDataSources[index] = { ...newDataSources[index], [field]: value };
        setConfig({ ...config, data_sources: newDataSources });
    };

    const addDataSource = () => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: [...(prevConfig.data_sources || []), {}]
        }));
    };

    const removeDataSource = (index) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            data_sources: prevConfig.data_sources.filter((_, i) => i !== index)
        }));
    };

    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="100%">
            {showPreview && (
                <Box height="600px" display="flex" alignItems="center" flexDirection="column" >
                    <Text
                        mb={4}
                        fontStyle="italic"
                        fontSize="lg"
                        textAlign="center"
                    >
                        Preview
                    </Text>
                    <Box flex="1" width="100%" display="flex" justifyContent="center" alignItems="center">
                        <ResponsiveContainer width="80%" height="80%">
                            <BarChart
                                data={previewData}
                                layout={config?.orientation === 'horizontal' ? 'vertical' : 'horizontal'}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                {config?.orientation === 'horizontal' ? (
                                    <>
                                        <XAxis type="number" />
                                        <YAxis dataKey="name" type="category" />
                                    </>
                                ) : (
                                    <>
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                    </>
                                )}
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#66748D" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>

                </Box>
            )}
            <Box
                overflowY="auto"
                maxHeight="600px"
                pr={4}
            >
                <VStack spacing={6} align="stretch" width="100%">
                    <DataSourceSection config={config} setConfig={setConfig} onChange={handleDataSourceChange} />


                    <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
                        <Heading size="sm" mb={3}>Chart Settings</Heading>
                        <SimpleGrid columns={2} spacing={4}>
                            <FormControl>
                                <FormLabel fontWeight="medium">Orientation</FormLabel>
                                <Select
                                    name="orientation"
                                    value={config?.orientation || ''}
                                    onChange={(e) => handleChange(e, 'orientation')}
                                    bg="white"
                                >
                                    <option value="vertical">Vertical</option>
                                    <option value="horizontal">Horizontal</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="medium">Tooltip Format</FormLabel>
                                <Select
                                    name="tooltip_format"
                                    value={config?.tooltip_format || ''}
                                    onChange={(e) => handleChange(e, 'tooltip_format')}
                                    bg="white"
                                >
                                    <option value="absolute_value">Absolute Value</option>
                                    <option value="percentage">Percentage</option>
                                </Select>
                            </FormControl>
                        </SimpleGrid>
                    </Box>


                    <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
                        <Heading size="sm" mb={3}>Display Options</Heading>
                        <VStack spacing={3} align="start">
                            <Checkbox
                                name="show_legend"
                                isChecked={config?.show_legend}
                                onChange={(e) => handleChange(e, 'show_legend')}
                            >
                                Show Legend
                            </Checkbox>
                            <Checkbox
                                name="show_data_labels"
                                isChecked={config?.show_data_labels}
                                onChange={(e) => handleChange(e, 'show_data_labels')}
                            >
                                Show Data Labels
                            </Checkbox>
                        </VStack>
                    </Box>

                    <Box bg="gray.50" p={4} borderRadius="md" boxShadow="sm">
                        <Heading size="sm" mb={3}>Data Refresh</Heading>
                        <FormControl>
                            <FormLabel fontWeight="medium">Refresh Rate</FormLabel>
                            <Select
                                name="refresh_rate"
                                value={config.refresh_rate || ''}
                                onChange={handleChange}
                                bg="white"
                            >
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </Select>
                        </FormControl>
                    </Box>
                </VStack>
            </Box>

        </SimpleGrid>
    );
}
