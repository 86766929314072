/*eslint-disable*/
import { Alert, Box, Button, Divider, Flex, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Text } from "@chakra-ui/react";
import { useState } from "react";

import shopify from "assets/img/icons/shopify.webp";
import google from "assets/img/icons/google.svg";
import instagram from "assets/img/icons/instagram.webp";
import meta from "assets/img/icons/meta.png";
import lightspeed from "assets/img/icons/lightspeed.jpg";
import ga4 from "assets/img/icons/ga4.png";
import mailchimp from "assets/img/icons/mailchimp.webp";
import amazon from "assets/img/icons/amazon.webp";

import { log } from "util/Logger";
import DataSource from "./DataSource";
import DropzoneCardCsv from "views/overviewProduct/components/DropzoneCardCsv";
import ConnectShopify from "./ConnectShopify";
import ConnectGoogle from "./ConnectGoogle";
import Card from "components/card/Card";

const ImportStep = {
  Default: Symbol("Default"),
  ConnectingShopify: Symbol("ConnectingShopify"),
  ConnectingGoogle: Symbol("ConnectingGoogle"),
}

const DEFAULT_CONTENT = (props) => (
  <>
    {props.showCsvImport && (
      <DropzoneCardCsv onResult={props.onImportCsvResult} />
    )}
    {props.showCsvImport && (
      <HStack>
        <Divider />
        <Text color='gray.700' fontSize="md" fontWeight="bold">OR</Text>
        <Divider />
      </HStack>
    )}
    <Flex
      direction="column"
      textAlign="start"
      align="start"
      mb="25px"
      mt="15px"
    >
      {/* <Image src={component.icon} w='85px' mr='20px' mb="25px" /> */}
      <Text
        color='gray.700'
        fontSize={{ sm: "xl", md: "xl", lg: "2xl" }}
        fontWeight="bold"
      >
        Connect App
      </Text>
    </Flex>
    <Flex direction='column' alignItems='center'>
      <Box>
        <Box>
          <Alert status='info' mb="25px">
            Some apps are not available in Preview mode.
          </Alert>
          <SimpleGrid columns={{ base: 2, md: 4 }} spacing="5px">
            <DataSource src={shopify}
              label="Shopify"
              onClick={props.connectShopifyClicked}
              onImportClose={props.onImportClose}
            />
            <DataSource src={google} label="Google Merchant"

              onClick={props.connectGoogleClicked} />
            <DataSource src={instagram} label="Instagram Shop" onClick={props.connectInstagramClicked} isAvailable={false} />
            <DataSource src={lightspeed} label="Lightspeed Retail" onClick={() => { }} isAvailable={false} />
            <DataSource src={meta} label="Meta Business Suite" onClick={props.connectMetaClicked} isAvailable={false} />
            <DataSource src={ga4} label="Google Analytics 4" onClick={() => { }} isAvailable={false} />
            <DataSource src={mailchimp} label="MailChimp" onClick={() => { }} isAvailable={false} />
            <DataSource src={amazon} label="Amazon" onClick={() => { }} isAvailable={false} />

          </SimpleGrid>
          <Card
            borderRadius='sm'
            bg='yellow.200'
            mt='20px'
          >
            <Text color='gray.700' fontSize="md" fontWeight="bold">
              We need your help!
            </Text>
            <Text
              mb="10px"
              color='gray.700'

            >
              Please use the form below to suggest 3rd party apps that are not listed. We want to make Sinteli the best tool for your business.
            </Text>
            <Input
              variant="solid"
              placeholder="App Name" />
            <Button
              mt='10px'
              onClick={() => { }}
            >
              Submit
            </Button>
          </Card>
        </Box>
      </Box>
    </Flex>
  </>
);

function getContent(step, props) {
  switch (step) {
    case ImportStep.Default:
      return DEFAULT_CONTENT(props);
    case ImportStep.ConnectingShopify:
      return <ConnectShopify {...props} />
    case ImportStep.ConnectingGoogle:
      return <ConnectGoogle {...props} />
    default:
      break;
  }
  return DEFAULT_CONTENT(props);
}

function ConnectDataSource(props) {
  const {
    onImportClose = () => { },
    isImportOpen,
    showCsvImport = true,
  } = props;

  const [currentStep, setCurrentStep] = useState(ImportStep.Default);
  const [accessToken, setAccessToken] = useState('');
  const [shopName, setShopName] = useState('');

  const connectShopifyClicked = () => {
    log("connect shopify clicked");
    setCurrentStep(ImportStep.ConnectingShopify);
  };

  const connectGoogleClicked = () => {
    log("connect google clicked");
    setCurrentStep(ImportStep.ConnectingGoogle);
  };


  const closeModal = () => {
    onImportClose();
    setCurrentStep(ImportStep.Default);
  };

  return (
    <Modal
      isOpen={isImportOpen}
      onClose={closeModal}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size='lg'
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{
          currentStep === ImportStep.Default && showCsvImport ? "Import Products" : ""
        }</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {getContent(currentStep, {
            ...props,
            showCsvImport,
            connectShopifyClicked,
            connectGoogleClicked,
          })}
        </ModalBody>

        <ModalFooter>
          {currentStep !== ImportStep.Default && (
            <>
              <Button
                variant='ghost'
                mr={3}
                onClick={() => setCurrentStep(ImportStep.Default)}
              >
                Back
              </Button>
            </>
          )}
          <Button variant='ghost' onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConnectDataSource;
