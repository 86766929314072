// Chakra imports
import { Flex, Button, Text, useColorModeValue, Image, HStack, VStack, Badge, Tooltip } from '@chakra-ui/react';



export default function ListItemButton(props) {
  const {
    value,
    secondaryText,
    loading,
    badge,
    badgeColorScheme,
    name, action, actionName, image, ...rest } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const secondaryTextColor = useColorModeValue('secondaryGray.500', 'secondaryGray.400');

  return (
    <Flex
      justifyContent="space-between"
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems="center"
      w="100%"
      {...rest}
    >
      <HStack>
        <Image
          src={image}
          w="34px"
          h="34px"
        // color={checkboxes.api ? "#fff" : iconColor}
        />

        <VStack align="start" spacing={0}>
          <Text
            color={textColor}
            fontSize="md"
            me="6px"
            fontWeight="700"
            mb={{ base: '10px', md: '0px' }}
          >
            {name}
          </Text>
          <Text
            color={secondaryTextColor}
            fontSize="sm"
            me="6px"
            fontWeight="500"
            mb={{ base: '10px', md: '0px' }}
          >
            {secondaryText}
          </Text>
        </VStack>

      </HStack>
      <Flex
        align="center"
        flexDirection={{ base: 'column', md: 'row' }}
        mb={{ base: '20px', md: '0px' }}
      >
        <Text
          ms="auto"
          mb={{ base: '10px', md: '0px' }}
          color="secondaryGray.600"
          fontSize="md"
          me={{ base: '10px', md: '30px' }}
          fontWeight="500"
        >
          {value}
        </Text>
        {badge && (
          <Badge
            colorScheme={badgeColorScheme || "brand"}
            borderRadius="5px"
            px="10px"
            fontSize="xs"
            fontWeight="700"
            me="10px"
          >
            {badge}
          </Badge>
        )}
        <Tooltip label="(Preview Mode Only) Syncing will override local changes and reset the data back to the original." placement="top">
          <Button
            variant="setup"
            px="24px"
            onClick={action}
            fontWeight="500"
            borderRadius='10px'
            fontSize='sm'
            isLoading={loading}
            disabled={true}
          >
            {actionName}
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
