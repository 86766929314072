import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import { logData } from "util/Logger";
import { log } from "util/Logger";
import { errorData } from "util/Logger";

export const getProducts = async ({
    token,
    uid,
    startAfter = null,
    limit = 100,
    columns = null,
    sortBy = 'updated_at',
    sortOrder = 'desc',
    filters = [],
    abortController = null,
}) => {
    try {
        log('fetching products from server...');

        const getProducts = httpsCallable(functions, 'getProducts');
        const result = await getProducts({
            token,
            uid,
            startAfter,
            limit,
            columns,
            sortBy,
            sortOrder,
            filters,
        }, { signal: abortController?.signal });
        logData('getProducts result:', result);
        return result.data;
    } catch (error) {
        errorData('Error:', error);
        throw error;
    }
};
