/*eslint-disable*/

import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Flex, Input, useColorModeValue, Spinner } from "@chakra-ui/react";
import Papa from 'papaparse';
import { logData } from "util/Logger";

function DropzoneCsv(props) {
  const { content, onResult, ...rest } = props;
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  const [isLoading, setIsLoading] = useState(false); // Add this line

  const onDrop = useCallback((acceptedFiles) => {
    setIsLoading(true); // Set loading state to true when file is dropped
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        complete: (result) => {
          logData('Parsed result:', result.data);
          onResult(result.data);
          setIsLoading(false); // Set loading state to false when parsing is complete
        },
        header: true,
      });
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'text/csv' });

  return (
    <Flex
      align='center'
      justify='center'
      bg={bg}
      border='1px dashed'
      borderColor={borderColor}
      borderRadius='16px'
      w='100%'
      h='max-content'
      minH='200px'
      cursor='pointer'
      {...getRootProps({ className: "dropzone" })}
      {...rest}>
      <Input variant='main' {...getInputProps()} />
      {isLoading ? <Spinner /> : <Button variant='no-effects'>{content}</Button>}
    </Flex>
  );
}

export default DropzoneCsv;